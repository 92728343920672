import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_save_form: 0,
    data: [],
    errors: {}
}

let LIST_COTIZATION = "LIST_DEFAULT";
let LIST_COTIZATION_SUCCESS = "LIST_DEFAULT_SUCCESS";
let SAVE_FORM_MEMORY = "SAVE_FORM_MEMORY";
let SAVE_FORM_MEMORY_SUCCESS = "SAVE_FORM_MEMORY_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_COTIZATION:
            return { ...state, fetching_list: 1, }
        case LIST_COTIZATION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case SAVE_FORM_MEMORY:
            return { ...state, fetching_save_form: 1 }
        case SAVE_FORM_MEMORY_SUCCESS:
            return { ...state, fetching_save_form: action.payload }
        default:
            return state;
    }
}

export let getListCotization = () => (dispatch, getState) => {
    dispatch({
        type: LIST_COTIZATION,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))


    let url = `${API_URL}/api/cotization?branch_id=${branch.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: LIST_COTIZATION_SUCCESS,
                    payload: resp.results
                });

            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let saveCotizationMemory = (form, step) => (dispatch, getState) => {
    console.log('form', form);

    dispatch({
        type: SAVE_FORM_MEMORY
    })

    if (form.category !== undefined) {
        localStorage.setItem("category_cotization", JSON.stringify(form.category))
    }
    if (form.plate !== undefined) {
        localStorage.setItem("plate_cotization", JSON.stringify(form.plate))
    }
    if (form.client !== undefined) {
        localStorage.setItem("client _cotization", JSON.stringify(form.client))
    }

    if (form.operators !== undefined) {
        localStorage.setItem("operators_cotization", JSON.stringify(form.operators))
    }

    if (form.services !== undefined) {
        localStorage.setItem("services_cotization", JSON.stringify(form.services))
    }

    dispatch({
        type: SAVE_FORM_MEMORY_SUCCESS,
        payload: step
    })
}
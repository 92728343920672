import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    data: [],
    errors: {}
}

let LIST_BRANCH = "LIST_BRANCH";
let LIST_BRANCH_SUCCESS = "LIST_BRANCH_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_BRANCH:
            return { ...state, fetching: 1, }
        case LIST_BRANCH_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        default:
            return state;
    }
}

export let getLisBranch = () => (dispatch, getState) => {
    dispatch({
        type: LIST_BRANCH,
    });

    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/branch-office-app`;

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            let { status } = resp;
            if (status === 200) {
                return resp.json()
            }
        }).then(resp => {

            dispatch({
                type: LIST_BRANCH_SUCCESS,
                payload: resp.results
            });
        }).catch(err => {
            console.log('error', err);

        })
    } else {
        console.log("no Ingreso");
    }
}
import React, { useEffect, useState } from 'react'
import MenuTemplate from '../../template/menu.template';
import { Col, Row, Table } from 'react-bootstrap';
import ModalExpense from '../Caja/modal.expense';
import { connect } from 'react-redux';
import {
    createOperatingCost, createWorkerCost, getOperatingCost, getOperatingWorker, getPaymentsMethods, onDeleteWorkerCost,
    onDeleteOperationCost, onUpdateOperationCost, onUpdateWorkerCost
} from '../../Redux/costDuck'
import { getListOperators } from '../../Redux/userDuck'
import { getParameterSegment } from '../../Redux/parameterDuck'
import { MdClose, MdEdit } from 'react-icons/md';
import ModalEditExpense from './modal.edit.expense';


const ExpensePage = ({ createOperatingCost, createWorkerCost, getOperatingCost, getOperatingWorker, fetching_operative, data_operative,
    fetching_worker, data_worker, type_worker, getListOperators, fetching_add, fetching_list_box, data_box, fetching_payment,
    getPaymentsMethods, data_payment, getParameterSegment, fetching_operator, data_operators, fetching_delete_cost,
    onDeleteWorkerCost, onDeleteOperationCost, onUpdateOperationCost, onUpdateWorkerCost, fetching_update_cost }) => {
    const [totalPayment, setTotalPayment] = useState(0)

    const [listOperative, setListOperative] = useState([])
    const [modal, setModal] = useState(false)
    const [parameter, setParameter] = useState({ title: '' })
    const [listParameter, setListParameter] = useState([])
    const [listOperator, setListOperators] = useState([])
    const [listWorker, setListWorker] = useState([])
    const [totalWorker, setTotalWorker] = useState(0)
    const [listInsumos, setListInsumos] = useState([])
    const [totalWorkerInsumos, setTotalWorkerInsumo] = useState(0)
    const [listPayment, setListPayment] = useState([])
    const [typeMoveId, setTypeMoveId] = useState(0)
    const [modalEdit, setModalEdit] = useState(false)
    const [formEdit, setFormEdit] = useState({})

    const [total, setTotal] = useState(0)

    useEffect(() => {
        getOperatingCost()
        getOperatingWorker(1)
        getListOperators()
        getParameterSegment("box")
    }, [])

    useEffect(() => {
        if (fetching_operative === 2) {
            setListOperative(data_operative.results)
            setTotal(data_operative.total)
        }

    }, [fetching_operative])

    useEffect(() => {
        if (fetching_list_box === 2) {
            setListParameter(data_box)
        }

    }, [fetching_list_box])

    useEffect(() => {
        if (fetching_delete_cost === 2) {
            window.location.reload()
        }

    }, [fetching_delete_cost])

    useEffect(() => {
        if (fetching_update_cost === 2) {
            window.location.reload()
        }

    }, [fetching_update_cost])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperators(data_operators)
        }

    }, [fetching_operator])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListPayment(data_payment.results)
            setTotalPayment(data_payment.total)
        }

    }, [fetching_payment])


    useEffect(() => {
        if (fetching_worker === 2) {
            if (data_worker.type == "1") {
                setListWorker(data_worker.results)
                setTotalWorker(data_worker.total)
                getOperatingWorker(2)
            } else {
                setListInsumos(data_worker.results);
                setTotalWorkerInsumo(data_worker.total)
            }

            // setListWorker(data_worker.results.filter(d => d.type_move_id === 1))
            // setTotalWorker(data_worker.total)
            // getOperatingWorker(2)

            // setListInsumos(data_worker.results.filter(d => d.type_move_id === 2));
            // setTotalWorkerInsumo(data_worker.total)
        }

    }, [fetching_worker])

    const openModal = (title, type, type_move_id) => {
        setModal(true)
        setTypeMoveId(type_move_id)
        setParameter({
            title,
            type
        })
    }

    const onAddCost = (form) => {

        if (form.type === 1) {
            createOperatingCost(form)
        } else {
            form.type_move_id = typeMoveId
            createWorkerCost(form)
        }
    }

    useEffect(() => {
        if (fetching_add) {
            setModal(false)
            getOperatingCost()
            getOperatingWorker(1)
        }

    }, [fetching_add])

    const onOpenModalExpense = (row, type) => {
        setTypeMoveId(type)
        setModalEdit(true)
        setFormEdit(row)
    }

    const deleteWorkerCost = (row) => {

        if (typeMoveId === 0) {
            onDeleteOperationCost(row)
        } else {
            onDeleteWorkerCost(row)
        }
    }

    const onEditExpense = (row) => {
        if (typeMoveId === 0) {
            onUpdateOperationCost(row)
        } else {
            onUpdateWorkerCost(row)
        }
    }

    return (
        <MenuTemplate>
            <Row>
                <Col lg={8} xs={9}><h3>Gasto Operativos</h3></Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={() => openModal("OPERATIVOS", 1, 0)}>Agregar</button>
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>SECCIÓN</th>
                                <th>DESCRIPCIÓN</th>
                                <th>VALOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listOperative.map((d, k) => <tr key={k}>
                                <td>{d.parameter.description}</td>
                                <td>{d.description}</td>
                                <td align='right'>
                                    {d.value_formated} <MdEdit size={22} className='cursor-pointer' onClick={() => onOpenModalExpense(d, 0)} />
                                </td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>TOTAL</td>
                                <td align='right'>{total}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col lg={8} xs={9}><h3>Anticipos Trabajadores</h3></Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={() => openModal("TRABAJADORES", 2, 1)}>Agregar</button>
                </Col>
            </Row>

            <Row className='my-2'>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>SECCIÓN</th>
                                <th>DESCRIPCIÓN</th>
                                <th>VALOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listWorker.map((d, k) => <tr key={k}>
                                <td>{d.worker.name}</td>
                                <td>{d.description}</td>
                                <td align='right'>{d.value_formated}
                                    <MdEdit size={22} className='cursor-pointer' onClick={() => onOpenModalExpense(d, 1)} />
                                </td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>TOTAL</td>
                                <td align='right'>{totalWorker}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col lg={8} xs={9}><h3>Insumos Trabajadores</h3></Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={() => openModal("TRABAJADORES", 2, 2)}>Agregar</button>
                </Col>
            </Row>

            <Row className='my-2'>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>SECCIÓN</th>
                                <th>DESCRIPCIÓN</th>
                                <th>VALOR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listInsumos.map((d, k) => <tr key={k}>
                                <td>{d.worker.name}</td>
                                <td>{d.description}</td>
                                <td align='right'>{d.value_formated}
                                    <MdEdit size={22} className='cursor-pointer' onClick={() => onOpenModalExpense(d, 2)} />
                                </td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}>TOTAL</td>
                                <td align='right'>{totalWorkerInsumos}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <ModalExpense show={modal} parameter={parameter} listParameter={listParameter} listOperators={listOperator} onHide={() => setModal(false)} onSuccess={onAddCost} />
            <ModalEditExpense show={modalEdit} formEdit={formEdit} onHide={() => setModalEdit(false)} onDeleteWorkerCost={deleteWorkerCost} onEditExpense={onEditExpense} />
        </MenuTemplate>

    )
}

const mapsState = (props) => {
    let { parameter, cost, user } = props

    return {
        data_operative: cost.data,
        fetching_operative: cost.fetching,
        fetching_worker: cost.fetching_worker,
        type_worker: cost.type_worker,
        data_worker: cost.data_worker,
        fetching_add: cost.fetching_add,
        fetching_delete_cost: cost.fetching_delete_cost,
        fetching_update_cost: cost.fetching_update_cost,
        fetching_list_box: parameter.fetching_list,
        data_box: parameter.data,
        fetching_operator: user.fetching_operator,
        data_operators: user.data_operators,
    }
}
export default connect(mapsState, {
    createOperatingCost, createWorkerCost, getOperatingCost, getOperatingWorker, getListOperators,
    getParameterSegment, onDeleteWorkerCost, onDeleteOperationCost, onUpdateOperationCost, onUpdateWorkerCost
})(ExpensePage);
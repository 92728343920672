import React, { useCallback, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';

const videoConstraints = {
    width: 512,
    height: 512,
    facingMode: "environment",
};

const ModalPhoto = ({ show, onSavePhoto, onHide }) => {

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);

        const res = await fetch(imageSrc);
        const blob = await res.blob();
        let file = new File([blob], "photo.png", { type: 'image/jpeg' });
        onSavePhoto(imageSrc, file)
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Foto</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <Row>
                    <Col>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            height={100 + '%'}
                            width={100 + '%'}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            minScreenshotWidth={180}
                            minScreenshotHeight={180}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-info' onClick={capture}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPhoto;
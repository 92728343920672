import React from 'react'
import { MdClose, MdModeEditOutline } from 'react-icons/md';

const ItemProducts = ({ item, onDeleteProduct }) => {
    let { product } = item
    return (
        <tr>
            <td>{product.name}</td>
            <td align='right'>{item.amount}</td>
            <td align='right'>{item.price_formated}</td>
            <td align='right'>{item.total_formated} <MdClose className='cursor-pointer' onClick={() => onDeleteProduct(item)} /></td>
        </tr>
    )
}

export default ItemProducts;
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_search: 0,
    data: [],
    errors: {}
}

let LIST_VEHICLE = "LIST_VEHICLE";
let LIST_VEHICLE_SUCCESS = "LIST_VEHICLE_SUCCESS";
let SEARCH_VEHICLE = "SEARCH_VEHICLE";
let SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_VEHICLE:
            return { ...state, fetching: 1, }
        case LIST_VEHICLE_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case SEARCH_VEHICLE:
            return { ...state, fetching_search: 1 }
        case SEARCH_VEHICLE_SUCCESS:
            return { ...state, fetching_search: 2, data: action.payload }
        default:
            return state;
    }
}

export let getVehicleSearch = (plate) => (dispatch, getState) => {

    dispatch({
        type: SEARCH_VEHICLE,
    });

    let url = `${API_URL}/api/plate/${plate}`

    if (getState().user.loggedIn) {
        fetch(url, {
            method: "GET",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: SEARCH_VEHICLE_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
import { getHeaders } from '../utils';
import { logOutAction } from './userDuck';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_list_product: 0,
    data: [],
    data_category: [],
    errors: {}
}

let LIST_CATEGORY = "LIST_CATEGORY";
let LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
let LIST_CATEGORY_PRODUCT = "LIST_CATEGORY_PRODUCT";
let LIST_CATEGORY_PRODUCT_SUCCESS = "LIST_CATEGORY_PRODUCT_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORY:
            return { ...state, fetching_list: 1, }
        case LIST_CATEGORY_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case LIST_CATEGORY_PRODUCT:
            return { ...state, fetching_list_product: 1 }
        case LIST_CATEGORY_PRODUCT_SUCCESS:
            return { ...state, fetching_list_product: 2, data_category: action.payload }
        default:
            return state;
    }
}

export let getListCategory = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY,
    });
    let url = `${API_URL}/api/categories`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            let { status } = resp;


            if (status === 200) {
                return resp.json()
            }


            logOutAction()(dispatch, getState);

        }).then(resp => {

            dispatch({
                type: LIST_CATEGORY_SUCCESS,
                payload: resp.results
            });
        }).catch(err => {
            console.log('error', err);

        })
    } else {
        console.log("no Ingreso");
    }
}

export let getListCategoryProducts = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY_PRODUCT,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))


    let url = `${API_URL}/api/category-product?branch_id=${branch.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            let { status } = resp;


            if (status === 200) {
                return resp.json()
            }

            logOutAction()(dispatch, getState);

        }).then(resp => {

            dispatch({
                type: LIST_CATEGORY_PRODUCT_SUCCESS,
                payload: resp.results
            });
        }).catch(err => {
            console.log('error', err);

        })
    } else {
        console.log("no Ingreso");
    }
}
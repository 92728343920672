import React, { useEffect, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { MdAttachMoney, MdMoney } from 'react-icons/md';
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import { getMethodPayments } from '../../Redux/paymentDuck'
import { getClientDefault } from '../../Redux/clientDuck'
import { storeCar } from '../../Redux/cartDuck'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ItemTablePayment from '../Payment/item.table.payment';
import ModalPayment from '../Payment/modal.payment';
import ModalPaymentProduct from './modal.payment.product';
import { v4 } from 'uuid';

const CartPaymentPage = ({ fetching_active, row, getMethodPayments, fetching_method, data_method, storeCar, getClientDefault, fetching_default,
    client_default, fetching_create }) => {

    const [turnActive, setTurnActive] = useState({ date_start: '', user: { name: '' } })
    const [listProduct, setListProduct] = useState([])
    const [listMethod, setListMethod] = useState([]);
    const [listPayment, setListPayment] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState({});
    const [customer, setCustomer] = useState({ name: '' });
    const [modal, setModal] = useState(false);

    const history = useHistory()

    useEffect(() => {
        getMethodPayments()
        getClientDefault()
        let products = localStorage.getItem("products");

        if (products !== null) {
            setListProduct(JSON.parse(products));
        }
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            history.push("/carrito")
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_default === 2) {
            setCustomer(client_default)
        }
    }, [fetching_default])

    useEffect(() => {
        if (fetching_method === 2) {
            setListMethod(data_method)
        }
    }, [fetching_method])


    useEffect(() => {
        if (fetching_active === 2) {
            setTurnActive(row)
        }
    }, [fetching_active])

    const onAddProductCart = (item) => {

    }

    const onDeleteProductCart = (item) => {

    }

    const onSelectedMethod = (item) => {
        setSelectedMethod(item)
        setModal(true)
    }

    const onEditPayment = (item) => {

    }

    const onDeletePayment = (item) => {
        setListPayment(listPayment.filter(d => d.uuid !== item.uuid))
    }

    const onFinishPayment = () => {
        let total_product = listProduct.reduce((a, b) => a + parseInt(b.quantity) * parseInt(b.price), 0)
        let total_payment = listPayment.reduce((a, b) => a + parseInt(b.amount), 0)

        if (total_payment !== total_product) {
            alert(`El total de Pago debe ser ${formatNumber(total_product)}`)
            return;
        }

        let form = {
            products: JSON.stringify(listProduct), payments: JSON.stringify(listPayment), cashier_id: turnActive.id, type_order_id: 1,
            status_id: 2, clientId: customer.id
        }
        storeCar(form)

    }

    const onAddPaymentRow = (item) => {
        item.uuid = v4()
        setModal(false)
        setListPayment([
            ...listPayment,
            item
        ])
    }

    return (
        <SimpleTemplate title={"RESUMEN DE VENTA"}>
            <Container fluid>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col className='text-center'><MdAttachMoney /></Col>
                                </Row>
                                <Row>
                                    <Col>Turno Actual</Col>
                                    <Col className='text-end'>{turnActive.user.name}</Col>
                                </Row>
                                <Row>
                                    <Col>Fecha de Apertura</Col>
                                    <Col className='text-end font-weight-bold'>{turnActive.date_start}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center my-1'>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>CLIENTE</Col>
                                    <Col className='text-end'>{customer.name}</Col>
                                </Row>
                                <Row>
                                    <Col>CELULAR</Col>
                                    <Col className='text-end font-weight-bold'>{turnActive.date_start}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                {listProduct.map((d, k) =>
                                    <Row key={k}>
                                        <Col lg={2}>
                                            <img src={d.image} className='img-fluid' />
                                        </Col>
                                        <Col lg={7}>
                                            <Row>
                                                <Col>{d.name}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{d.description}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{formatNumber(d.price)}</Col>
                                            </Row>
                                        </Col>
                                        <Col className='d-flex align-items-center'>
                                            <Row>
                                                <Col>
                                                    <button className='btn-add' onClick={() => onDeleteProductCart(d)}>-</button>
                                                </Col>
                                                <Col>{d.quantity}</Col>
                                                <Col>
                                                    <button className='btn-add' type='button' onClick={() => onAddProductCart(d)}>+</button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center my-2'>
                    <Col lg={6}>
                        <Row>
                            <Col className='font-weight-bold'>TOTAL A PAGAR</Col>
                            <Col className='text-end font-weight-bold'>{formatNumber(listProduct.reduce((a, b) => a + parseInt(b.quantity) * parseInt(b.price), 0))}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Selecciona el Método de Pago</Card.Title>
                                <Row>
                                    {listMethod.map((d, k) => <Col xs={3} key={k} className="mt-1" onClick={() => onSelectedMethod(d)}>
                                        <Card>
                                            <Card.Body className='p-1 size-payment d-flex align-items-center justify-content-center text-center'>
                                                {d.description}
                                            </Card.Body>
                                        </Card>
                                    </Col>)}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Table className='table table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Método de Pago</th>
                                    <th>Propina</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPayment.length === 0 ? <tr><td colSpan={3} align="center">No se Encontraron Datos</td></tr>
                                    : listPayment.map((d, k) => <ItemTablePayment item={d} onDelete={() => onDeletePayment(d)} key={k} />)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2}>TOTAL</th>
                                    <th className='text-right'>{formatNumber(listPayment.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                {listPayment.length > 0 && <div className='container-btn-add'>
                    <a href='#' onClick={onFinishPayment}>
                        FINALIZAR PAGO
                    </a>
                </div>}
                <Row className='mt-5'>
                    <Col></Col>
                </Row>

                <ModalPaymentProduct show={modal} total={listProduct.reduce((a, b) => a + parseInt(b.quantity) * parseInt(b.price), 0)} methodPayment={selectedMethod}
                    onHide={() => setModal(false)} onSuccess={onAddPaymentRow}
                />

            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { turn, payment, client, cart } = props

    return {
        fetching_active: turn.fetching_active,
        row: turn.row,
        fetching_method: payment.fetching_method,
        data_method: payment.data_method,
        fetching_default: client.fetching_default,
        client_default: client.client_default,
        fetching_create: cart.fetching_create,
    }
}

export default connect(mapsState, { getMethodPayments, storeCar, getClientDefault })(CartPaymentPage);
import React, { useEffect, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { getVehicleSearch } from '../../Redux/vehicleDuck'
import { getOrderMemory } from '../../Redux/orderDuck'

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SearchPlatePage = ({ getVehicleSearch, fetching_search, data, getOrderMemory, fetching_get_form }) => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [plate, setPlate] = useState('')
    const history = useHistory()

    useEffect(() => {
        if (fetching_search === 2) {
            setList(data)
            setIsLoading(false)
        }
    }, [fetching_search])

    const updateField = (e) => {
        setIsLoading(true)
        setPlate((e.target.value).toUpperCase())
        if (e.target.value !== '') {
            getVehicleSearch(e.target.value)
        } else {
            setList([])
            setIsLoading(false)
        }
    }

    const onSelectedPlate = (row) => {
        let { client, category } = row

        localStorage.setItem("plate", row.plate)
        localStorage.setItem("client", JSON.stringify(client))
        localStorage.setItem("category", JSON.stringify(category))
        setList([])

        getOrderMemory()
        history.push("/step-one")
    }

    const onCreatePlate = () => {
        localStorage.setItem("plate", plate)
        localStorage.removeItem("client")
        localStorage.removeItem("category")
        setList([])
        getOrderMemory()
        history.push("/step-one")
    }

    return (
        <SimpleTemplate title={"Buscar Placa"}>
            <Container fluid className='mt-2'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={5}>
                        <Row>
                            <Col>
                                <input className='form-control' placeholder='Escribe la Placa' value={plate} onChange={updateField} />
                            </Col>
                        </Row>

                        {isLoading && <Row className='my-4'>
                            <Col className='text-center'>
                                <Spinner animation="border" /></Col>
                        </Row>}
                        {list.length === 0 ? <Row className='mt-4'>
                            <Col className='text-center'>
                                {plate !== '' && <Row>
                                    <Col>
                                        <Row>
                                            <Col>No se encontro la (PLACA <b>{plate}</b>)</Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <button className='btn btn-sm btn-info' onClick={onCreatePlate}>Crear Placa {plate} </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>}
                            </Col>
                        </Row> : list.map((d, k) => <Row key={k} className='mt-1'>
                            <Col>
                                <Card className='cursor-pointer' onClick={() => onSelectedPlate(d)}>
                                    <Card.Body>
                                        <Row>
                                            <Col>Placa</Col>
                                            <Col className='font-weight-bold'>{d.plate}</Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-muted'>Nombre</Col>
                                            <Col className='text-muted'>{d.client.name}</Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-muted'>Celular</Col>
                                            <Col className='text-muted'>{d.client.phone}</Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-muted'>No de Servicios</Col>
                                            <Col className='text-muted'>{d.quantity}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>)}
                    </Col>
                </Row>
            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { vehicle, order } = props
    return {
        fetching_search: vehicle.fetching_search,
        data: vehicle.data,
        fetching_get_form: order.fetching_get_form
    }
}

export default connect(mapsState, { getVehicleSearch, getOrderMemory })(SearchPlatePage);
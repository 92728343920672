import React from 'react'
import { MdModeEditOutline } from 'react-icons/md';
import { formatNumber } from '../../utils';
import { Col, Row } from 'react-bootstrap';

const ItemServices = ({ item, onEditRow, locked }) => {
    let { service } = item

    return (
        <tr>
            <td className='font-size-12'>
                <Row>
                    <Col>{service.name}</Col>
                </Row>
                <Row>
                    <Col className='text-muted font-size-9'>{service.description}</Col>
                </Row>
                {item.comment !== '' && <Row>
                    <Col className='text-muted font-size-9'>Comentario: {item.comment}</Col>
                </Row>}

            </td>
            <td align='right' className='font-size-12'>{item.price_formated}</td>
            <td align='right' className='font-size-12'>{formatNumber(item.discount)}</td>
            <td align='right' className='font-size-12'>
                {item.total}
                {locked !== true && <MdModeEditOutline className="cursor-pointer" onClick={() => onEditRow(item)} />}
            </td>
        </tr>
    )
}

export default ItemServices;
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import { getOpenCashToday, createOrEditCash } from '../../Redux/orderDuck'

const ConfigurationPage = ({ getOpenCashToday, fetching_cash, cash, createOrEditCash, fetching_cash_create }) => {

    const [form, setForm] = useState({ value: '', id: null });

    useEffect(() => {
        getOpenCashToday()
    }, [])

    useEffect(() => {
        if (fetching_cash_create === 2) {
            alert("Asignado")
        }
    }, [fetching_cash_create])

    useEffect(() => {
        if (fetching_cash === 2) {
            if (cash.id !== null) {
                setForm(cash)
            }
        }
    }, [fetching_cash])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveCaja = () => {
        createOrEditCash(form.id, form.value)
    }

    return (
        <MenuTemplate>
            <Row className='d-flex justify-content-center'>
                <Col lg={3}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Caja</Form.Label>
                                <Form.Control type="text" placeholder="Caja" name='value' onChange={updateInput} value={form.value} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <button className='btn btn-sm btn-info' onClick={onSaveCaja}>Guardar</button>
                        </Col>
                    </Row>

                </Col>
            </Row>



        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { order } = props
    return {
        fetching_cash: order.fetching_cash,
        fetching_cash_create: order.fetching_cash_create,
        cash: order.cash
    }
}

export default connect(mapsState, { getOpenCashToday, createOrEditCash })(ConfigurationPage);
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';

const ModalEditExpense = ({ show, formEdit, onHide, onDeleteWorkerCost, onEditExpense }) => {
    const [form, setForm] = useState({ value: '' })


    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }
    }, [formEdit])

    const updateField = (e) => {
        setForm({
            ...form,
            ["value"]: e.target.value
        })
    }

    const onDeleteExpense = () => {
        if (window.confirm("¿Deseas Eliminar el Gasto?")) {
            onDeleteWorkerCost(formEdit)
        }
    }

    const onEditValue = () => {
        formEdit.value = form.value
        onEditExpense(formEdit)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Valor</Form.Label>
                            <Form.Control type="number" placeholder="Descripción" name="value" value={form.value} onChange={updateField} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onDeleteExpense}>Eliminar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-secondary' onClick={onEditValue}>Editar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalEditExpense;
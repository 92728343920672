import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL

const ItemCategory = ({ item, onSelectCategory, selectedCategory }) => {
    let image = API_URL + "/" + item.image

    return (
        <Col xs={4} lg={3} className='cursor-pointer mt-1' onClick={() => onSelectCategory(item)}>
            <Card className={`${selectedCategory.id === item.id ? 'category-selected' : ''}`}>
                <Card.Body className='p-1'>
                    <Row>
                        <Col className='text-center'>
                            <img src={image} className="img-category" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={`${selectedCategory.id === item.id ? 'category-selected-text' : ''} text-center`}>{item.title}</Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col >
    )
}

export default ItemCategory;
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Navbar, Row, Table } from 'react-bootstrap';
import { MdOutlineClose, MdWarning, MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getOrderById, onAddPayment, onAdvancement, onDeletePayment } from '../../Redux/orderDuck'
import { getActiveTurn } from '../../Redux/turnDuck'
import { getMethodPayments } from '../../Redux/paymentDuck'
import ModalPayment from './modal.payment';
import ItemTablePayment from './item.table.payment';
import { formatNumber } from '../../utils';
import { v4 } from 'uuid';

const PaymentPage = ({ getOrderById, getActiveTurn, fetching_active, row, fetching_by_id, row_order, getMethodPayments, fetching_method,
    data_method, onAddPayment, fetching_payment, onAdvancement, fetching_advancement, onDeletePayment, fetching_delete_payment }) => {

    const [turnActive, setTurnActive] = useState(null)
    const [orderPayment, setOrderPayment] = useState({ subtotal_formated: 0, total_formated: 0, discount_formated: 0 })
    const [listPayment, setListPayment] = useState([]);
    const [listMethod, setListMethod] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState({});
    const [modal, setModal] = useState(false);
    const history = useHistory()

    let { id } = useParams()

    useEffect(() => {

        if (fetching_payment === 2) {
            history.push("/")
        }
    }, [fetching_payment])

    useEffect(() => {
        if (fetching_method === 2) {
            setListMethod(data_method)
        }
    }, [fetching_method])

    useEffect(() => {
        if (fetching_advancement === 2) {
            getOrderById(id)
            setModal(false)
        }
    }, [fetching_advancement])

    useEffect(() => {
        if (fetching_delete_payment === 2) {
            getOrderById(id)
        }
    }, [fetching_delete_payment])

    useEffect(() => {
        if (fetching_by_id === 2) {

            setOrderPayment(row_order)
            if (row_order.payments.length > 0) {
                setListPayment(row_order.payments)
            } else {
                setListPayment([])
            }
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_active === 2) {
            setTurnActive(row)
        }
    }, [fetching_active])

    useEffect(() => {
        getActiveTurn()
        getMethodPayments();
    }, [])

    useEffect(() => {
        getOrderById(id)
    }, [id])

    const onGoBack = () => {
        history.goBack();
    }
    const onSelectedMethod = (item) => {
        setSelectedMethod(item)
        setModal(true)
    }

    const onAddPaymentRow = (row) => {
        onAdvancement(id, row.payment.id, row.amount, 0, row.tip)

    }

    const onFinishPayment = () => {
        let cxc = orderPayment.payments.filter(d => d.method_payment_id === 40)

        let _total = listPayment.reduce((a, b) => a + parseFloat(b.amount), 0);

        if (cxc.length > 0) {
            if (parseFloat(orderPayment.total_payment) === parseFloat(_total)) {
                onAddPayment(orderPayment.id, listPayment)
            } else {
                alert(`Monto valido ${orderPayment.total_formated}`)
            }
        } else {
            if (parseFloat(orderPayment.total_pending) === parseFloat(_total)) {
                onAddPayment(orderPayment.id, listPayment)
            } else {
                alert(`Monto valido ${orderPayment.total_formated}`)
            }
        }
    }

    const onDeletePaymentForm = (payment) => {
        if (window.confirm(`Deseas Eliminar el Pago de ${payment.payment.description} por ${formatNumber(payment.amount)}`)) {
            console.log('payment', payment);
            onDeletePayment(payment.id)
        }
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>RESUMEN DE PAGO</b></Col>

                    </Row>
                </Container>
            </Navbar>
            <Container>
                {turnActive !== null ? <Row className='mt-3'>
                    <Col>
                        <Card>

                            <Card.Body>
                                <Row>
                                    <Col>TURNO ACTUAL</Col>
                                    <Col className='text-right'>{turnActive.user.name}</Col>
                                </Row>
                                <Row>
                                    <Col>FECHA DE APERTURA</Col>
                                    <Col className='text-right'>{turnActive.date_start}</Col>
                                </Row>
                                {turnActive.percent > 0 && <Row>
                                    <Col>PORCENTAJE AJUSTADO</Col>
                                    <Col className='text-right'>
                                        <MdWarning size={22} color='red' />
                                        {turnActive.percent}%</Col>
                                </Row>}

                            </Card.Body>
                        </Card>
                    </Col>
                </Row> : <Row>
                    <Col className='text-center my-2'>
                        <b>No haz iniciado Turno</b>
                    </Col>
                </Row>}

                <Row className='my-2'>
                    <Col>SUBTOTAL</Col>
                    <Col className='text-right'><b>{orderPayment.subtotal_formated}</b></Col>
                </Row>
                <Row>
                    <Col>DESCUENTO</Col>
                    <Col className='text-right'><b>{orderPayment.discount_formated}</b></Col>
                </Row>
                <Row className='my-2'>
                    <Col><b>TOTAL A PAGAR</b></Col>
                    <Col className='text-right'><b>{orderPayment.total_formated}</b></Col>
                </Row>
                <Row>
                    <Col><b>SELECCIONA EL MÉTODO DE PAGO</b></Col>
                </Row>
                <Row>
                    {listMethod.map((d, k) => <Col xs={3} key={k} className="mt-1" onClick={() => onSelectedMethod(d)}>
                        <Card>
                            <Card.Body className='p-1 size-payment d-flex align-items-center justify-content-center text-center'>
                                {d.description}
                            </Card.Body>
                        </Card>
                    </Col>)}
                </Row>
                <Row className='my-3'>
                    <Col className='text-center'><b>PAGOS REGISTRADOS</b></Col>
                </Row>
                <Row>
                    <Col>
                        <Table className='table table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Método de Pago</th>
                                    <th>Propina</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listPayment.length === 0 ? <tr><td colSpan={3} align="center">No se Encontraron Datos</td></tr>
                                    : listPayment.map((d, k) => <ItemTablePayment item={d} onDelete={() => onDeletePaymentForm(d)} key={k} />)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2}>TOTAL</th>
                                    <th className='text-right'>{formatNumber(listPayment.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                {listPayment.length > 0 && <div className='container-btn-add'>
                    <a href='#' onClick={onFinishPayment}>
                        FINALIZAR PAGO
                    </a>
                </div>}

                {turnActive === null && <div className='container-btn-add-yellow'>
                    <Link to="/turns">
                        Abrir Turno
                    </Link>
                </div>}

                <ModalPayment show={modal} order={orderPayment} methodPayment={selectedMethod} onHide={() => setModal(false)} onSuccess={onAddPaymentRow} />

            </Container>
        </>

    )
}

const mapsState = (props) => {
    let { order, turn, payment } = props;

    return {
        row: turn.row,
        row_order: order.row,
        fetching_by_id: order.fetching_by_id,
        fetching_active: turn.fetching_active,
        fetching_method: payment.fetching_method,
        data_method: payment.data_method,
        fetching_payment: order.fetching_payment,
        fetching_advancement: order.fetching_advancement,
        fetching_delete_payment: order.fetching_delete_payment,
    }
}

export default connect(mapsState, {
    getOrderById, getActiveTurn, getMethodPayments, onAddPayment, onAdvancement,
    onDeletePayment
})(PaymentPage);
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import { getMe, logOutAction } from '../../Redux/userDuck'
import { Col, Row } from 'react-bootstrap';


const ProfilePage = ({ getMe, fetching_me, row, logOutAction }) => {

    const [data, setData] = useState({})

    useEffect(() => {
        if (fetching_me === 2) {
            setData(row)
        }
    }, [fetching_me])

    useEffect(() => {
        getMe()
    }, [])
    return (
        <MenuTemplate>
            <Row className='mt-5'>
                <Col className='text-center'><h2>Información del Usuario</h2></Col>
            </Row>
            <Row className='my-2'>
                <Col className='text-center'>{data.name}</Col>
            </Row>
            <Row className='my-2'>
                <Col className='text-center'>{data.email}</Col>
            </Row>
            <Row className='my-2'>
                <Col className='text-center'>
                    <button className='btn btn-sm btn-warning' onClick={logOutAction}>Cerrar Sesion</button>
                </Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        fetching_me: user.fetching_me,
        row: user.row
    }
}

export default connect(mapsState, { getMe, logOutAction })(ProfilePage);
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Form, Navbar, Row } from 'react-bootstrap';
import { MdOutlineCameraAlt, MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import ItemPhotos from './item.photos';
import ModalPhoto from './modal.photo';
import { updateFieldOrder, onCreateOrder } from '../../../Redux/orderDuck'
import { toogleLoader } from '../../../Redux/alertDuck'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';


const StepThreePage = ({ onCreateOrder, toogleLoader, fetching_create }) => {

    const [form, setForm] = useState({ notification: true, observations: '' })
    const [modal, setModal] = useState(false)
    const [listPhotos, setListPhotos] = useState([])

    const history = useHistory();

    useEffect(() => {
        if (fetching_create === 2) {
            history.push("/");
        }
    }, [fetching_create])

    const onOpenCamera = () => {
        setModal(true)
    }
    const onSavePhoto = (img, file) => {
        setListPhotos([
            ...listPhotos,
            { id: v4(), img, file }
        ])
        setModal(false)
    }

    const onCreateOrderTmp = (status_id) => {
        let _form = form

        _form.status_id = status_id;
        _form.photos = listPhotos
        _form.registration_date = moment().format("YYYY-MM-DD")

        toogleLoader()

        onCreateOrder(_form)
    }

    const updateField = (e) => {

        if (e.target.type === 'checkbox') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked
            })
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.name
            })
        }
    }

    const onGoBack = () => {
        history.goBack()
    }

    const onDeletPhoto = (row) => {
        let _list = listPhotos.filter(d => d.id !== row.id)
        setListPhotos(_list);
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={9}><b>ÚLTIMO PASO</b></Col>
                        <Col></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row className='mt-4 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                name='notification'
                                checked={form.notification}
                                onChange={updateField}
                                type={"checkbox"}
                                label={`Envia Notificación`} />

                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-4 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Form.Group className="mb-3">
                            <Form.Label>Comentarios</Form.Label>
                            <Form.Control type="text" placeholder="Agrega comentarios" name='observations' onChange={updateField} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-4 d-flex justify-content-center'>
                    <Col lg={5}>
                        <h3>Sube Fotos</h3>
                    </Col>
                    {listPhotos.length > 0 && listPhotos.length !== 3 && <Col lg={5}><button className='btn btn-info btn-sm' onClick={onOpenCamera}>Subir mas Fotos</button></Col>}
                </Row>
                {listPhotos.length === 0 ? <Row className='mt-4 d-flex justify-content-center'>
                    <Col lg={5}>
                        <Card className='cursor-pointer' onClick={onOpenCamera}>
                            <Card.Body>
                                <Row>
                                    <Col className='text-center'><MdOutlineCameraAlt size={100} /></Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row> :
                    <Row>
                        {listPhotos.map((p, k) => <ItemPhotos key={k} item={p} onDelete={onDeletPhoto} />)}
                    </Row>}

                <Row className='mt-5'>
                    <Col className='text-center'>
                        <button className='btn btn-success' onClick={() => onCreateOrderTmp(1)}>Crear Orden</button>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className='text-center'>
                        <button className='btn btn-secondary' onClick={() => onCreateOrderTmp(4)}>Crear en Espera</button>
                    </Col>
                </Row>
                <ModalPhoto show={modal} onSavePhoto={onSavePhoto} onHide={() => setModal(false)} />
            </Container>
        </>
    )
}


const mapsState = (props) => {
    let { order } = props
    return {
        fetching_create: order.fetching_create,
    }
}
export default connect(mapsState, { updateFieldOrder, onCreateOrder, toogleLoader })(StepThreePage);
import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import {
    getOperatingCost, createOperatingCost, getOperatingWorker, createWorkerCost, getPaymentsMethods,
    getServiceByWorker, getPaymentToWorker, getOperatingService, getOperatingServiceLite, getSalesProduct,
    getServiceByAdditional, getPaymentsMethodsProducts
} from '../../Redux/costDuck'
import { getListOperators } from '../../Redux/userDuck'
import { getParameterSegment } from '../../Redux/parameterDuck'

import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CajaPage = ({ getPaymentsMethods, fetching_payment, data_payment, getServiceByWorker, getPaymentToWorker, fetching_payment_worker,
    data_payment_worker, getOperatingService, fetching_review, data_review, getSalesProduct, fetching_sales_product, data_sales_product,
    getOperatingServiceLite, fetching_by_worker, data_by_worker, getServiceByAdditional, fetching_additional, data_additional,
    getPaymentsMethodsProducts, fetching_method_by_product, data_method_by_product }) => {


    const [review, setReview] = useState({ input: {}, output: {} })

    const [totalPayment, setTotalPayment] = useState(0)
    const [totalByWorker, setTotalByWorker] = useState(0)
    const [totalServiceByWorker, setTotalServiceByWorker] = useState(0)
    const [totalDiscountService, setTotalDiscountService] = useState(0)

    const [subtotalPayWorker, setSubtotalPayWorker] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [totalPaymentWorker, setTotalPaymentWorker] = useState(0)
    const [totalTip, setTotalTip] = useState(0)

    const [listPayment, setListPayment] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [listServiceByWorker, setListServiceByWorker] = useState([])
    const [listPaymentByWorker, setListPaymentByWorker] = useState([])
    const [listAdditional, setListAdditional] = useState([])
    const [totalAdditional, setTotalAdditional] = useState(0)
    const [listMethodByProduct, setListMethodByProduct] = useState([])
    const [totalMethodByProduct, setTotalMethodByProduct] = useState(0)

    const history = useHistory()

    useEffect(() => {
        getPaymentsMethods()
        getServiceByWorker()
        getPaymentToWorker();
        getOperatingServiceLite();
        getSalesProduct();
        getServiceByAdditional()
        getPaymentsMethodsProducts()
    }, [])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListPayment(data_payment.results)
            setTotalPayment(data_payment.total)
        }

    }, [fetching_payment])

    useEffect(() => {
        if (fetching_additional === 2) {
            setListAdditional(data_additional.results)
            setTotalAdditional(data_additional.total)
        }

    }, [fetching_additional])
    useEffect(() => {
        if (fetching_method_by_product === 2) {
            setListMethodByProduct(data_method_by_product.results)
            setTotalMethodByProduct(data_method_by_product.total)
        }

    }, [fetching_method_by_product])

    useEffect(() => {
        if (fetching_by_worker === 2) {
            setTotalByWorker(data_by_worker.quantity)
            setTotalServiceByWorker(data_by_worker.total)
            setListServiceByWorker(data_by_worker.results)
        }

    }, [fetching_by_worker])


    useEffect(() => {
        if (fetching_payment_worker === 2) {
            setSubtotalPayWorker(data_payment_worker.subtotal)
            setTotalDiscountService(data_payment_worker.discount)
            setTotalTip(data_payment_worker.tip)
            setListPaymentByWorker(data_payment_worker.results)
            setTotalPaymentWorker(data_payment_worker.total)
        }

    }, [fetching_payment_worker])

    useEffect(() => {
        if (fetching_sales_product === 2) {
            setListProducts(data_sales_product)
        }

    }, [fetching_sales_product])

    useEffect(() => {
        if (fetching_review === 2) {
            setReview(data_review)
        }
    }, [fetching_review])


    const goToDetail = (item) => {
        history.push(`/caja-worker/${item.operator_id}`)
    }

    return (
        <MenuTemplate>
            <Row className='d-flex justify-content-center mt-3'>
                <Col lg={12} xs={12}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={5}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col><h4>Utilidad</h4></Col>
                                        <Col className='text-right text-success'><h4>{formatNumber(review.utility)}</h4></Col>
                                    </Row>
                                    <Row className='border-top'>
                                        <Col><h4>Ingresos</h4></Col>
                                        <Col><h4>{formatNumber(review.ingresos)}</h4></Col>
                                        <Col><h4>Egresos</h4></Col>
                                        <Col className='text-danger text-right'><h4>{formatNumber(review.expense)}</h4></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>SERVICIOS</Card.Title>
                                    <Row>
                                        <Col lg={8}><h5>Métodos de Pago</h5></Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th className='font-size-12'>MÉTODO</th>
                                                        <th className='font-size-12'>CANT</th>
                                                        <th className='font-size-12'>SUBTOTAL</th>
                                                        <th className='font-size-12'>PROP</th>
                                                        <th className='font-size-12'>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listPayment.map((d, k) => <tr key={k}>
                                                        <td>{d.metodo}</td>
                                                        <td align='right'>{d.quantity}</td>
                                                        <td align='right'>{d.subtotal}</td>
                                                        <td align='right'>{d.tip}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={4}><b>TOTAL</b></td>
                                                        <td align='right'>{totalPayment}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={12}><h5>Servicios por Trabajador</h5></Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Trabajador</th>
                                                        <th>Unidad</th>
                                                        <th>Subtotal</th>
                                                        <th>Desc</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listServiceByWorker.map((d, k) => <tr key={k}>
                                                        <td>{d.worker}</td>
                                                        <td align='right'>{d.quantity}</td>
                                                        <td align='right'>{d.subtotal_formated}</td>
                                                        <td align='right'>{d.discount_formated}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td><b>TOTAL</b></td>
                                                        <td align='right'>{totalByWorker}</td>
                                                        <td align='right'>{totalServiceByWorker}</td>
                                                        <td align='right'>{totalDiscountService}</td>
                                                        <td align='right'>{totalServiceByWorker}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col><h5>Pagos a Trabajadores</h5></Col>
                                    </Row>

                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th className='font-size-12 text-center'>Trabajador</th>
                                                        <th className='font-size-12 text-center'>Subtotal</th>
                                                        <th className='font-size-12 text-center'>Prop</th>
                                                        <th className='font-size-12 text-center'>Desc</th>
                                                        <th className='font-size-12 text-center'>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listPaymentByWorker.map((d, k) => <tr key={k} onClick={() => goToDetail(d)}>
                                                        <td>{d.worker}</td>
                                                        <td align='right'>{d.subtotal_formated}</td>
                                                        <td align='right'>{d.tip_formated}</td>
                                                        <td align='right'>{d.discount_formated}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td><b>TOTAL</b></td>
                                                        <td align='right'>{subtotalPayWorker}</td>
                                                        <td align='right'>{totalTip}</td>
                                                        <td align='right'>{totalDiscountService}</td>
                                                        <td align='right'>{totalPaymentWorker}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col><h5>Ventas de Productos Adicionales</h5></Col>
                                    </Row>

                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th className='font-size-12 text-center'>Producto</th>
                                                        <th className='font-size-12 text-center'>Cantidad</th>
                                                        <th className='font-size-12 text-center'>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listAdditional.map((d, k) => <tr key={k} onClick={() => goToDetail(d)}>
                                                        <td>{d.product}</td>
                                                        <td align='right'>{d.quantity}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th><b>TOTAL</b></th>
                                                        <th className='text-right'>{formatNumber(listAdditional.reduce((a, b) => a + parseInt(b.quantity), 0))}</th>
                                                        <th className='text-right'>{totalAdditional}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>



                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>TIENDA</Card.Title>
                                    <Row>
                                        <Col lg={8}><h5>Ventas de Productos</h5></Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Precio</th>
                                                        <th>Unidad</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listProducts.map((d, k) => <tr key={k}>
                                                        <td>{d.service}</td>
                                                        <td align='right'>{formatNumber(d.price)}</td>
                                                        <td align='right'>{d.quantity}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={2}><b>TOTAL</b></td>
                                                        <td align='right'>{listProducts.reduce((a, b) => a + parseInt(b.quantity), 0)}</td>
                                                        <td align='right'>{formatNumber(listProducts.reduce((a, b) => a + parseFloat(b.total), 0))}</td>

                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={8}><h5>Métodos de Pago</h5></Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th className='font-size-12'>MÉTODO</th>
                                                        <th className='font-size-12'>CANT</th>
                                                        <th className='font-size-12'>SUBTOTAL</th>
                                                        <th className='font-size-12'>PROP</th>
                                                        <th className='font-size-12'>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {listMethodByProduct.map((d, k) => <tr key={k}>
                                                        <td>{d.metodo}</td>
                                                        <td align='right'>{d.quantity}</td>
                                                        <td align='right'>{d.subtotal}</td>
                                                        <td align='right'>{d.tip}</td>
                                                        <td align='right'>{d.total_formated}</td>
                                                    </tr>)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={4}><b>TOTAL</b></td>
                                                        <td align='right'>{totalMethodByProduct}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title>PARQUEADERO</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    <Row className='mt-4'>

                        <Col>

                        </Col>


                        <Col xs={12} md={4}>
                            <Row>
                                <Col><h3>Registro Contable del Día</h3></Col>
                            </Row>
                            <Row className='my-2'>
                                <Col>
                                    <Table className='table-sm table-bordered'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Descripción</th>
                                                <th>Debito</th>
                                                <th>Credito</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Efectivo</td>
                                                <td align='right'>{formatNumber(review.cash)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Banco</td>
                                                <td align='right'>{formatNumber(review.bank)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Ventas a Credito</td>
                                                <td align='right'>{formatNumber(review.input.credit)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Cuentas por Cobrar</td>
                                                <td align='right'>{formatNumber(review.input.cxc)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Compras a Credito</td>
                                                <td></td>
                                                <td align='right'>{0}</td>
                                            </tr>
                                            <tr>
                                                <td>Ventas Servicios</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.service)}</td>
                                            </tr>
                                            <tr>
                                                <td>Ventas Adicionales</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.product)}</td>
                                            </tr>
                                            <tr>
                                                <td>Ventas en Tienda</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.store)}</td>
                                            </tr>
                                            <tr>
                                                <td>Servicios de Parqueadero</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.parking)}</td>
                                            </tr>
                                            <tr>
                                                <td>Otros Ingresos</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.other_revenues)}</td>
                                            </tr>
                                            <tr>
                                                <td>Propinas</td>
                                                <td></td>
                                                <td align='right'>{formatNumber(review.input.tip)}</td>
                                            </tr>
                                            <tr>
                                                <td>Gastos Operativos</td>
                                                <td align='right'>{formatNumber(review.output.operative)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Anticipos a Trabajadores</td>
                                                <td align='right'>{formatNumber(review.output.anticipos)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Pagos a Trabajadores</td>
                                                <td align='right'>
                                                    <Row>
                                                        <Col>{formatNumber(review.output.payroll)}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='text-danger'>({formatNumber(review.output.payroll_pending)})</Col>
                                                    </Row>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Descuentos por Servicios</td>
                                                <td align='right'>{formatNumber(review.output.discount)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td><b>Igualdades</b></td>
                                                <td align='right'><b>{formatNumber(review.ingresos)}</b></td>
                                                <td align='right'><b>{formatNumber(review.ingresos)}</b></td>
                                            </tr>
                                        </tbody>

                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row className='mt-4'>
                                <Col className='text-center'><h3>Caja Inicial</h3></Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>{formatNumber(review.open_cash)}</Col>
                            </Row>
                            <Row>
                                <Col className='text-center'><h3>Efectivo + Insumos</h3></Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>{formatNumber(review.cash + review.input.insumos)}</Col>
                            </Row>
                            <Row>
                                <Col className='text-center'><h3>Total Neto</h3></Col>
                            </Row>
                            <Row>
                                <Col className='text-center display-5'>{formatNumber(review.neto)}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-5 pt-4'>
                <Col></Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { parameter, cost, user } = props

    return {
        fetching_list_box: parameter.fetching_list,
        data_box: parameter.data,
        fetching_add: cost.fetching_add,
        data_operative: cost.data,
        fetching_operative: cost.fetching,
        fetching_worker: cost.fetching_worker,
        type_worker: cost.type_worker,
        data_worker: cost.data_worker,
        fetching_payment: cost.fetching_payment,
        data_payment: cost.data_payment,
        fetching_by_worker: cost.fetching_by_worker,
        data_by_worker: cost.data_by_worker,
        fetching_payment_worker: cost.fetching_payment_worker,
        data_payment_worker: cost.data_payment_worker,
        fetching_review: cost.fetching_review,
        data_review: cost.data_review,
        fetching_sales_product: cost.fetching_sales_product,
        fetching_additional: cost.fetching_additional,
        data_additional: cost.data_additional,
        data_sales_product: cost.data_sales_product,
        fetching_method_by_product: cost.fetching_method_by_product,
        data_method_by_product: cost.data_method_by_product,
        fetching_operator: user.fetching_operator,
        data_operators: user.data_operators,
    }
}


export default connect(mapsState, {
    getOperatingCost, getParameterSegment, createOperatingCost, getOperatingWorker, getListOperators, createWorkerCost,
    getPaymentsMethods, getServiceByWorker, getPaymentToWorker, getOperatingService, getSalesProduct, getOperatingServiceLite,
    getServiceByAdditional, getPaymentsMethodsProducts
})(CajaPage);
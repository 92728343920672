import React from 'react'
import { MdClose, MdEdit } from 'react-icons/md';
import { formatNumber } from '../../../utils';
import { Col, Row } from 'react-bootstrap';

const ItemServiceSelected = ({ item, onEditItem }) => {
    //TODO:PENDING discount
    return (
        <tr>
            <td className='font-size-10'>{item.service}
                {item.comment !== '' && <Row>
                    <Col className="text-muted">Comentario: {item.comment}</Col>
                </Row>}

            </td>
            <td className='font-size-11' align='right'>{formatNumber(item.price)}</td>

            <td className='font-size-11' align='right'>{formatNumber(item.discount || 0)}</td>
            <td className='font-size-11' align='right'>{formatNumber(item.price - (item.discount || 0))}</td>
            <td>
                <MdEdit onClick={() => onEditItem(item)} />
            </td>
        </tr>
    )
}

export default ItemServiceSelected;
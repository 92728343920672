import React, { useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ModalPayroll = ({ show, onHide, listMethod, data, onSuccess }) => {

    const [selected, setSelected] = useState({})

    const onSelectedMethod = (item) => {
        setSelected(item)
    }


    const onAddPay = () => {
        if (Object.keys(selected).length !== 0) {
            onSuccess(selected)
        } else {
            alert("Selecciona el Métodod de Pago")
            return;
        }
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>LIQUIDACIÓN {formatNumber(data.total_worker)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col></Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Selecciona el Método de Pago</Card.Title>
                                <Row>
                                    {listMethod.filter(d => d.id === 4 || d.id === 5 || d.id === 51 || d.id === 6)
                                        .map((d, k) => <Col xs={3} key={k} className="mt-1" onClick={() => onSelectedMethod(d)}>
                                            <Card bg={`${selected.id === d.id ? 'info' : ''}`}>
                                                <Card.Body className='p-1 size-payment d-flex align-items-center justify-content-center text-center'>
                                                    {d.description}
                                                </Card.Body>
                                            </Card>
                                        </Col>)}
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-success' onClick={onAddPay}>PAGAR</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    )
}

export default ModalPayroll;
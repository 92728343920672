import React from 'react'
import { MdClose } from 'react-icons/md';
import { formatNumber } from '../../utils';

const ItemTablePayment = ({ item, onDelete }) => {
    let { payment } = item
    return (
        <tr>
            <td>{payment.description}</td>
            <td align='right'>{formatNumber(item.tip)}</td>
            <td align='right'>{formatNumber(item.amount)} <MdClose className='cursor-pointer' onClick={onDelete} /></td>
        </tr>
    )
}

export default ItemTablePayment;
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Navbar, Row } from 'react-bootstrap';
import ItemCategory from './item.category';
import { updateFieldOrder, saveOrderMemory, getOrderMemory, onDeletePlate, onDeleteClient } from '../../../Redux/orderDuck'
import { connect } from 'react-redux';
import ModalClient from './modal.client';
import { getListServices } from '../../../Redux/servicesDuck'
import { getListCategory } from '../../../Redux/categoryDuck'
import { restartStatus } from '../../../Redux/clientDuck'
import { createWorker } from "tesseract.js";
import { MdAdd, MdClose, MdEast, MdPlusOne, MdWest } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import imgcar from '../../../Images/car.png'
import imgavatar from '../../../Images/avatar.png'

const API_URL = process.env.REACT_APP_API_URL

const StepOnePage = ({ errors, getListServices, getListCategory, fetching_list_category, data_category, saveOrderMemory, getOrderMemory, fetching_get_form,
    fetching_save_form, form_order, onDeletePlate, fetching_delete_plate, onDeleteClient, fetching_delete_client, restartStatus }) => {
    const [plate, setPlate] = useState('');
    const [client, setClient] = useState('');
    const [form, setForm] = useState({});
    const [listSearch, setListSearch] = useState([]);
    const [listSearchClient, setListSearchClient] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [plateTypped, setPlateTypped] = useState('')
    const [clientTypped, setClientTypped] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [showInputClient, setShowInputClient] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({})
    const history = useHistory()

    useEffect(() => {
        getListCategory()
        getOrderMemory()
        restartStatus()
    }, [])

    useEffect(() => {
        if (fetching_get_form === 2) {

            if (form_order.plate !== null && form_order.plate !== undefined) {
                setPlate(form_order.plate.replaceAll('"', '').replaceAll("\\", "").replaceAll(" ", ""))
            }

            if (form_order.client !== null && form_order.client !== undefined) {
                setClient(form_order.client)
            }
            if (form_order.category !== null && form_order.category !== undefined) {
                setSelectedCategory(form_order.category)
            }
        }

    }, [fetching_get_form])

    useEffect(() => {
        if (fetching_delete_plate === 2) {
            setPlate('')
            setSelectedCategory([])
        }
    }, [fetching_delete_plate])

    useEffect(() => {
        if (fetching_delete_client === 2) {
            setClient({ name: '' })
        }
    }, [fetching_delete_client])

    useEffect(() => {
        if (fetching_save_form === 2) {
            history.push("/step-two");
        }
    }, [fetching_save_form])

    useEffect(() => {
        if (fetching_list_category === 2) {
            setListCategory(data_category)
        }

    }, [fetching_list_category])


    const addNewPlate = () => {
        setShowInput(false)
        setPlate({ label: plateTypped, id: 0 })
        setPlateTypped('')

    }
    const addNewClient = () => {
        setShowInputClient(true)
    }

    const onSuccessCreateClient = (client) => {
        setShowInputClient(false)
        client.label = `${client.name} (${client.phone})`;
        client.value = client.id;
        setClient(client);
    }

    const onGoBack = () => {
        history.push("/");
    }

    const onNextPage = () => {
        if (plate === '') {
            alert("Placa Obligatoria")
            return;
        }
        if (client === '') {
            alert("Cliente Obligatorio")
            return;
        }

        if (selectedCategory === '') {
            alert("Categoria Obligatoria")
            return;
        }

        let _form = { plate, client, category: selectedCategory }

        saveOrderMemory(_form, 2)
    }

    const onSelectedCategory = (item) => {
        localStorage.setItem("category", JSON.stringify(item));
        setSelectedCategory(item)
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={9}><b>PASO 1</b></Col>
                        <Col></Col>
                    </Row>
                </Container>
            </Navbar>

            <Container>

                <Row className='mt-2 d-flex justify-content-center'>
                    <Col lg={6}>
                        <Row className='cursor-pointer border-bottom'>
                            <Col lg={1} xs={2}>
                                <img src={imgcar} className='img-fluid' />
                            </Col>
                            <Col onClick={() => history.push("/search-plate")}>
                                {plate === '' ? <span className='text-muted'>Placa</span> : plate}
                            </Col>
                            <Col lg={1} xs={2}>
                                {plate !== '' ? <MdClose size={30} onClick={onDeletePlate} />
                                    :
                                    <MdAdd size={30} color='green' onClick={() => history.push("/search-plate")} />}
                            </Col>
                        </Row>

                    </Col>
                    {(listSearch.length === 0 && plateTypped.length > 0) && <Col lg={3} md={3} xs={3} className="d-flex align-items-center">
                        <button className='btn btn-info btn-sm' onClick={addNewPlate}>Agregar</button>
                    </Col>}
                </Row>
                <Row className='d-flex justify-content-center mt-3'>
                    <Col lg={6}>
                        <Row className='cursor-pointer border-bottom'>
                            <Col lg={1} xs={2}>
                                <img src={imgavatar} className='img-fluid' />
                            </Col>
                            <Col onClick={() => history.push("/search-client")}>
                                {client === null || client === '' ? <span className='text-muted'>Buscar o Registrar Cliente</span> : <>{client.name} ({client.phone})</>}
                            </Col>
                            <Col lg={1}>
                                {client !== '' ? <MdClose size={30} onClick={onDeleteClient} />
                                    :
                                    <MdAdd size={30} color='green' onClick={() => history.push("/search-client")} />}
                            </Col>
                        </Row>
                        {/* {showInputClient ?
                            <input className='form-control' value={clientTypped} onChange={updateFieldPlate} />
                            : <Form.Group className="mb-3">
                                <Form.Label>Buscar o Registrar Cliente</Form.Label>
                                <AsyncSelect
                                    isClearable
                                    value={client}
                                    placeholder="Busca cliente"
                                    loadOptions={loadOptionsClient}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            setClient(e)
                                            updateFieldOrder({ field: "client_id", value: e })
                                        }

                                    }}
                                />
                                <Form.Text className="text-danger">
                                    {errors.client_id}
                                </Form.Text>
                            </Form.Group>} */}
                    </Col>
                    {(listSearchClient.length === 0 && clientTypped.length > 0) && <Col lg={3} md={3} xs={4} className="d-flex align-items-center">
                        <button className='btn btn-info btn-sm' onClick={addNewClient}>Crear Cliente</button>
                    </Col>}
                </Row>
                <Row className='my-2'>
                    <Col className='text-center'><b>Selecciona el tipo de Vehículo</b></Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Row>
                            {listCategory.map((cat, k) =>
                                <ItemCategory key={k} item={cat} selectedCategory={selectedCategory}
                                    onSelectCategory={onSelectedCategory} />)}
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col className='text-danger'>{errors.category_id}</Col>
                </Row>
                <ModalClient show={showInputClient} onSuccess={onSuccessCreateClient} clientTypped={clientTypped} onHide={() => setShowInputClient(false)} />
            </Container>
            <Navbar bg="dark" expand="lg" fixed="bottom">
                <Container fluid>
                    <Row className='w-100 d-flex justify-content-center'>
                        <Col className='text-center text-white' lg={2} md={5}>
                            <a onClick={onNextPage}>SIGUIENTE <MdEast size={25} /></a>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </>
    )
}

const mapsState = (props) => {
    let { order, category } = props
    return {
        form: order.form,
        errors: order.errors,
        fetching_list_category: category.fetching_list,
        data_category: category.data,
        fetching_get_form: order.fetching_get_form,
        fetching_save_form: order.fetching_save_form,
        fetching_delete_plate: order.fetching_delete_plate,
        fetching_delete_client: order.fetching_delete_client,
        form_order: order.form,

    }
}

export default connect(mapsState, {
    updateFieldOrder, getListServices, getListCategory, saveOrderMemory, getOrderMemory, onDeletePlate,
    onDeleteClient, restartStatus
})(StepOnePage);
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SimpleTemplate from '../../template/simple.template';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getReviewTurn } from '../../Redux/turnDuck'
import { formatNumber } from '../../utils';

const DetailTurnPage = ({ getReviewTurn, fetching_review, data_review }) => {
    let { id } = useParams()
    const [list, setList] = useState([])
    const [listExpense, setListExpense] = useState([])
    const [listAntipos, setListAnticipos] = useState([])

    useEffect(() => {
        if (fetching_review === 2) {
            console.log('data_review', data_review);
            setList(data_review.orders)
            setListExpense(data_review.expense)
            setListAnticipos(data_review.payment_worker.filter(d => d.type_move_id === 1))
        }
    }, [fetching_review])

    useEffect(() => {
        getReviewTurn(id)
    }, [id])

    console.log('listAntipos', listAntipos);


    return (
        <SimpleTemplate title={`Detalles del Turno ${id}`}>
            <Container fluid>
                <Row>
                    <Col lg={4}>
                        <Row>
                            <Col>Ordenes de Servicio</Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className='table table-sm table-bordered'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Orden</th>
                                            <th>Placa</th>
                                            <th>Propina</th>
                                            <th>Total</th>
                                            <th>Total Pago A trabajadores</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map((a, b) => <tr key={b}>
                                            <td>{b + 1}</td>
                                            <td>{a.id}</td>
                                            <td>{a.plate}</td>
                                            <td align='right'>{a.tip}</td>
                                            <td align='right'>{a.total}</td>
                                            <td align='right'>{a.total_payment_worker}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.tip), 0))}</th>
                                            <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                            <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total_payment_worker), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                    </Col>
                    <Col lg={4}>
                        <Row>
                            <Col>Gastos Operativos</Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className='table table-sm table-bordered'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Tipo</th>
                                            <th>Descripción</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listExpense.map((a, k) => <tr key={k}>
                                            <td>{k + 1}</td>
                                            <td>{a.expense}</td>
                                            <td>{a.descripcion}</td>
                                            <td align='right'>{a.value}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(listExpense.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>Anticipos</Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className='table table-sm table-bordered'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Trabajador</th>
                                            <th>Descripción</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listAntipos.map((a, b) => <tr key={b}>
                                            <td>{b + 1}</td>
                                            <td>{a.user}</td>
                                            <td>{a.descripcion}</td>
                                            <td align='right'>{a.value}</td>

                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(listExpense.reduce((a, b) => a + parseFloat(b.value), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>

        </SimpleTemplate>
    )
}
const mapsState = (props) => {
    let { turn } = props
    return {
        fetching_review: turn.fetching_review,
        data_review: turn.data_review,
    }
}


export default connect(mapsState, { getReviewTurn })(DetailTurnPage);
import React, { createContext, useContext, useEffect } from "react";

import { useSocket } from '../hooks/useSocket'
// import { AuthContext } from "../context/AuthContext";
// import { ChatContext } from "./chat/ChatContext";
// import { types } from "../types/types";
// import { scrollToBottomAnimated } from "../helpers/scrollToBottom";


const API_URL = process.env.REACT_APP_API_URL

export const SocketContext = createContext()


export const SocketProvider = ({ children }) => {

    const { socket, online, conectarSocket, desconectarSocket } = useSocket(API_URL)

    // const {auth} = useContext(AuthContext)

    // const {dispatch} = useContext(ChatContext)


    // useEffect(()=>{

    //     if(auth.logged){
    //         conectarSocket()
    //     }

    // },[auth,conectarSocket])


    useEffect(() => {

        conectarSocket()
    }, [conectarSocket])

    // useEffect(()=>{

    //     if(!auth.logged){
    //         desconectarSocket()
    //     }

    // },[auth,desconectarSocket])

    // useEffect(()=>{

    //     socket?.on("lista-usuarios",(usuarios)=>{
    //         dispatch({
    //             type:types.usuariosCargados,
    //             payload:usuarios
    //         })

    //     })

    // },[socket,dispatch])

    // useEffect(()=>{

    //     socket?.on("mensaje-personal",(mensaje)=>{

    //         dispatch({
    //             type:types.nuevoMensaje,
    //             payload:mensaje
    //         })

    //         scrollToBottomAnimated("mensajes")

    //     })

    // },[socket,dispatch])

    return (
        <SocketContext.Provider value={{ socket, online }}>
            {children}
        </SocketContext.Provider>
    )
}
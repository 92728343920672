import moment from 'moment';
import { getHeaders } from '../utils';
import { logOutAction } from './userDuck';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_add: 0,
    fetching_worker: 0,
    fetching_payment: 0,
    fetching_by_worker: 0,
    fetching_payment_worker: 0,
    fetching_review: 0,
    fetching_sales_product: 0,
    fetching_additional: 0,
    fetching_method_by_product: 0,
    fetching_delete_cost: 0,
    fetching_update_cost: 0,
    data: [],
    data_worker: [],
    data_review: [],
    data_payment: [],
    data_by_worker: [],
    data_payment_worker: [],
    data_sales_product: [],
    data_additional: [],
    data_method_by_product: [],
    errors: {},
    type: 1
}

let LIST_COST = "LIST_COST";
let LIST_COST_SUCCESS = "LIST_COST_SUCCESS";
let ADD_COST = "ADD_COST";
let ADD_COST_SUCCESS = "ADD_COST_SUCCESS";
let LIST_COST_WORKER = "LIST_COST_WORKER";
let LIST_COST_WORKER_SUCCESS = "LIST_COST_WORKER_SUCCESS";
let LIST_PAYMENT = "LIST_PAYMENT";
let LIST_PAYMENT_SUCCESS = "LIST_PAYMENT_SUCCESS";
let LIST_SERVICE_BY_WORKER = "LIST_SERVICE_BY_WORKER";
let LIST_SERVICE_BY_WORKER_SUCCESS = "LIST_SERVICE_BY_WORKER_SUCCESS";
let PAYMENT_WORKER = "PAYMENT_WORKER";
let PAYMENT_WORKER_SUCCESS = "PAYMENT_WORKER_SUCCESS";
let LIST_SERVICE_REVIEW = "LIST_SERVICE_REVIEW";
let LIST_SERVICE_REVIEW_SUCCESS = "LIST_SERVICE_REVIEW_SUCCESS";
let SALES_PRODUCT = "SALES_PRODUCT";
let SALES_PRODUCT_SUCCESS = "SALES_PRODUCT_SUCCESS";
let LIST_SERVICE_ADDTIONAL = "LIST_SERVICE_ADDTIONAL";
let LIST_SERVICE_ADDTIONAL_SUCCESS = "LIST_SERVICE_ADDTIONAL_SUCCESS";
let LIST_PAYMENT_PRODUCTS = "LIST_PAYMENT_PRODUCTS";
let LIST_PAYMENT_PRODUCTS_SUCCESS = "LIST_PAYMENT_PRODUCTS_SUCCESS";
let DELETE_COST = "DELETE_COST";
let DELETE_COST_SUCCESS = "DELETE_COST_SUCCESS";
let UPDATE_COST = "UPDATE_COST";
let UPDATE_COST_SUCCESS = "UPDATE_COST_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_COST:
            return { ...state, fetching: 1, }
        case LIST_COST_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case ADD_COST:
            return { ...state, fetching_add: 1 }
        case ADD_COST_SUCCESS:
            return { ...state, fetching_add: 2 }
        case LIST_COST_WORKER:
            return { ...state, fetching_worker: 1 }
        case LIST_COST_WORKER_SUCCESS:
            return { ...state, fetching_worker: 2, data_worker: action.payload, type_worker: action.type_worker }
        case LIST_PAYMENT:
            return { ...state, fetching_payment: 1 }
        case LIST_PAYMENT_SUCCESS:
            return { ...state, fetching_payment: 2, data_payment: action.payload }
        case LIST_SERVICE_BY_WORKER:
            return { ...state, fetching_by_worker: 1 }
        case LIST_SERVICE_BY_WORKER_SUCCESS:
            return { ...state, fetching_by_worker: 2, data_by_worker: action.payload }
        case PAYMENT_WORKER:
            return { ...state, fetching_payment_worker: 1 }
        case PAYMENT_WORKER_SUCCESS:
            return { ...state, fetching_payment_worker: 2, data_payment_worker: action.payload }
        case LIST_SERVICE_REVIEW:
            return { ...state, fetching_review: 1 }
        case LIST_SERVICE_REVIEW_SUCCESS:
            return { ...state, fetching_review: 2, data_review: action.payload }
        case SALES_PRODUCT:
            return { ...state, fetching_sales_product: 1 }
        case SALES_PRODUCT_SUCCESS:
            return { ...state, fetching_sales_product: 2, data_sales_product: action.payload }
        case LIST_SERVICE_ADDTIONAL:
            return { ...state, fetching_additional: 1 }
        case LIST_SERVICE_ADDTIONAL_SUCCESS:
            return { ...state, fetching_additional: 2, data_additional: action.payload }
        case LIST_PAYMENT_PRODUCTS:
            return { ...state, fetching_method_by_product: 1 }
        case LIST_PAYMENT_PRODUCTS_SUCCESS:
            return { ...state, fetching_method_by_product: 2, data_method_by_product: action.payload }
        case DELETE_COST:
            return { ...state, fetching_delete_cost: 1 }
        case DELETE_COST_SUCCESS:
            return { ...state, fetching_delete_cost: 2 }
        case UPDATE_COST:
            return { ...state, fetching_update_cost: 1 }
        case UPDATE_COST_SUCCESS:
            return { ...state, fetching_update_cost: 2 }
        default:
            return state;
    }
}

export let getOperatingCost = () => (dispatch, getState) => {
    dispatch({
        type: LIST_COST,
    });

    let { currentDate } = getState().order;

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/operation?branch=${branch.id}&source=movil&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_COST_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getOperatingWorker = (type) => (dispatch, getState) => {
    dispatch({
        type: LIST_COST_WORKER,
    });

    let { currentDate } = getState().order;

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/worker?branch=${branch.id}&type=${type}&source=movil&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }


    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_COST_WORKER_SUCCESS,
                payload: resp,
                type_worker: type
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getPaymentsMethods = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYMENT,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/payments?branch_id=${branch.id}&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_PAYMENT_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getPaymentsMethodsProducts = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYMENT_PRODUCTS,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/payments-product?branch_id=${branch.id}&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_PAYMENT_PRODUCTS_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getOperatingService = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICE_REVIEW,
    });

    let { currentDate } = getState().order;


    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/services?branch_id=${branch.id}&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SERVICE_REVIEW_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getOperatingServiceLite = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICE_REVIEW,
    });

    let { currentDate } = getState().order;


    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/services-lite?branch_id=${branch.id}&cashier_id=${turn.id}`;

    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SERVICE_REVIEW_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getServiceByWorker = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICE_BY_WORKER,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/services-by-worker?branch_id=${branch.id}&cashier_id=${turn.id}`;


    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SERVICE_BY_WORKER_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getServiceByAdditional = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICE_ADDTIONAL,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/services-by-additional?branch_id=${branch.id}&cashier_id=${turn.id}`;


    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SERVICE_ADDTIONAL_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getPaymentToWorker = () => (dispatch, getState) => {
    dispatch({
        type: PAYMENT_WORKER,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/payment-by-worker?branch_id=${branch.id}&cashier_id=${turn.id}`;


    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            let { status } = resp;
            console.log('status', status);

            if (status === 200) {
                return resp.json()
            }
            if (status === 401) {
                logOutAction()(dispatch, getState)
            }
        }).then(resp => {

            dispatch({
                type: PAYMENT_WORKER_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getSalesProduct = () => (dispatch, getState) => {
    dispatch({
        type: SALES_PRODUCT,
    });

    let { currentDate } = getState().order;
    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/report/product?branch_id=${branch.id}&cashier_id=${turn.id}`;


    // if (currentDate !== moment().format("YYYY-MM-DD")) {
    //     url += `&date=${currentDate}`
    // }

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: SALES_PRODUCT_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let createOperatingCost = (form) => (dispatch, getState) => {
    dispatch({
        type: ADD_COST,
    });

    let url = `${API_URL}/api/cost/operation`;

    if (getState().user.loggedIn) {

        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ADD_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteOperationCost = (form) => (dispatch, getState) => {
    dispatch({
        type: DELETE_COST,
    });

    let url = `${API_URL}/api/cost/operation/${form.id}`;

    if (getState().user.loggedIn) {
        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id
        fetch(url, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: DELETE_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateOperationCost = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_COST,
    });

    let url = `${API_URL}/api/cost/operation/${form.id}`;

    if (getState().user.loggedIn) {
        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id

        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: UPDATE_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let createWorkerCost = (form) => (dispatch, getState) => {
    dispatch({
        type: ADD_COST,
    });

    let url = `${API_URL}/api/cost/worker`;

    if (getState().user.loggedIn) {
        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ADD_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteWorkerCost = (form) => (dispatch, getState) => {
    dispatch({
        type: DELETE_COST,
    });

    let url = `${API_URL}/api/cost/worker/${form.id}`;

    if (getState().user.loggedIn) {
        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id
        fetch(url, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: DELETE_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateWorkerCost = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_COST,
    });

    let url = `${API_URL}/api/cost/worker/${form.id}`;

    if (getState().user.loggedIn) {
        let turn = JSON.parse(localStorage.getItem("turn"));
        form.cashier_id = turn.id
        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_COST_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import userReducer, { restoreSessionAction } from './userDuck'
import orderReducer, { onChangeDate } from './orderDuck'
import categoryReducer from './categoryDuck'
import servicesReducer from './servicesDuck'
import productReducer from './productsDuck'
import clientReducer from './clientDuck'
import parameterReducer from './parameterDuck'
import reportReducer from './reportDuck'
import costReducer from './costDuck'
import alertReducer from './alertDuck'
import branchReducer from './branchDuck'
import turnReducer from './turnDuck'
import paymentReducer from './paymentDuck'
import cotizationReducer from './cotizationDuck'
import cartReducer from './cartDuck'
import payrollReducer from './payrollDuck'
import vehicleReducer from './vehicleDuck'
import moment from 'moment'

let rootReducer = combineReducers({
    user: userReducer,
    order: orderReducer,
    category: categoryReducer,
    services: servicesReducer,
    product: productReducer,
    client: clientReducer,
    parameter: parameterReducer,
    report: reportReducer,
    cost: costReducer,
    alert: alertReducer,
    branch: branchReducer,
    turn: turnReducer,
    payment: paymentReducer,
    cotization: cotizationReducer,
    cart: cartReducer,
    payroll: payrollReducer,
    vehicle: vehicleReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    let store = createStore(rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )

    restoreSessionAction()(store.dispatch)

    if (store.getState().user.loggedIn === true) {


        let filter = localStorage.filter;
        if (filter === undefined) {
            localStorage.setItem("filter", JSON.stringify({ date: moment().format("YYYY-MM-DD") }))
        } else {
            filter = JSON.parse(filter)
            onChangeDate(filter.date)(store.dispatch)
        }
    }

    return store
}
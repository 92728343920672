import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    loader: false,
    calendar: false,
    drawer: false,
}

let TOOGLE_LOADER = "TOOGLE_LOADER";
let TOOGLE_LOADER_SUCCESS = "TOOGLE_LOADER_SUCCESS";
let TOOGLE_CALENDAR = "TOOGLE_CALENDAR";
let TOOGLE_CALENDAR_SUCCESS = "TOOGLE_CALENDAR_SUCCESS";
let TOOGLE_DRAWER = "TOOGLE_DRAWER";
let TOOGLE_DRAWER_SUCCESS = "TOOGLE_DRAWER_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TOOGLE_LOADER:
            return { ...state, }
        case TOOGLE_LOADER_SUCCESS:
            return { ...state, loader: action.payload, }
        case TOOGLE_CALENDAR:
            return { ...state, }
        case TOOGLE_CALENDAR_SUCCESS:
            return { ...state, calendar: action.payload, }
        case TOOGLE_DRAWER:
            return { ...state, drawer: action.payload, }
        case TOOGLE_DRAWER_SUCCESS:
            return { ...state, drawer: action.payload, }
        default:
            return state;
    }
}

export let toogleLoader = () => (dispatch, getState) => {
    let { loader } = getState().alert;

    dispatch({
        type: TOOGLE_LOADER,
    });


    dispatch({
        type: TOOGLE_LOADER,
        payload: !loader
    });
}

export let toogleCalendar = () => (dispatch, getState) => {
    let { calendar } = getState().alert;

    dispatch({
        type: TOOGLE_CALENDAR,
    });


    dispatch({
        type: TOOGLE_CALENDAR_SUCCESS,
        payload: !calendar
    });
}

export let toogleDrawer = (status) => (dispatch, getState) => {
    let { drawer } = getState().alert;

    dispatch({
        type: TOOGLE_DRAWER,
        payload: status
    });

}
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Navbar, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { MdWest, MdOutlineClose, MdPhoneEnabled } from "react-icons/md";
import { FaPrint } from "react-icons/fa";

import {
    getOrderById, onFinishOrder, onAddAdditionProducts, onRestartOrder, onEditServiceDetail, onDeleteServiceDetail,
    onAddAdditionService, onUpdatePlate, onCancelOrder, onDeleteAdditionService
} from '../../../Redux/orderDuck'
import { onUpdateClient } from '../../../Redux/clientDuck'
import { connect } from 'react-redux';
import ItemServices from '../item.services';
import { formatNumber } from '../../../utils';
import ItemProducts from '../item.products';
import { MdModeEditOutline } from 'react-icons/md';
import ModalEditParameter from './modal.edit.parameter';
import { getListOperators, onUpdateOperator, onAddOperator, onDeleteOperator, onDeleteDetail } from '../../../Redux/userDuck'
import { getListServices } from '../../../Redux/servicesDuck'
import { getListProducts } from '../../../Redux/productsDuck'
import ItemPhotos from '../CreateOrder/item.photos';
import ModalProducts from '../CreateOrder/modal.products';
import ModalEditService from './modal.edit.service';
import ModalServices from '../modal.services';
import ModalOperators from './modal.operators';
import ModalConfirm from '../../Components/modal.confirm';
import ModalDeleteOrder from './modal.delete.order';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const EditCCOrderPage = ({ getOrderById, fetching_by_id, row, getListOperators, fetching_operator, data_operators, onFinishOrder, fetching_finish,
    onUpdateClient, fetching_update, row_client, onUpdateOperator, fetching_update_operator, row_operators, getListProducts,
    fetching_list_product, data_product, onAddAdditionProducts, fetching_additional, onRestartOrder, onEditServiceDetail, fetching_detail,
    onDeleteServiceDetail, getListServices, fetching_list_services, data_services, onAddAdditionService, onAddOperator, onDeleteOperator,
    onDeleteDetail, onUpdatePlate, fetching_plate, fetching_cancel, onCancelOrder, role_id, onDeleteAdditionService, fetching_delete }) => {
    let { id } = useParams();
    const [data, setData] = useState({ name: '', client: { name: '' }, category: { name: '' }, operators: [], photos: [] })
    const [listServices, setListServices] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [listProductsCart, setListProductsCart] = useState([])
    const [subTotalServices, setSubTotalServices] = useState(0)
    const [totalServices, setTotalServices] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [amountProducts, setAmountProducts] = useState(0)
    const [listOperators, setListOperators] = useState([])
    const [modal, setModal] = useState(false)
    const [modalProduct, setModalProduct] = useState(false)
    const [modalService, setModalService] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [operator, setOperator] = useState({})
    const [parameter, setParameter] = useState({ title: '', type: 0, label: '', typeInput: 'text', value: '' })
    const [rowService, setRowService] = useState({})
    const [modalAddService, setModalAddService] = useState(false)
    const [modalOperator, setModalOperator] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [operatorSelected, setOperatorSelected] = useState({ user: { name: '' } })
    const [listServicesAltern, setListServicesAltern] = useState([])
    const [form, setForm] = useState({ notification: true })
    const [order, setOrder] = useState({})
    const history = useHistory()

    useEffect(() => {
        getOrderById(id)
        getListProducts()
    }, [id])

    useEffect(() => {
        if (fetching_list_services === 2) {
            setModalAddService(true)
            setListServicesAltern(data_services)
        }
    }, [fetching_list_services])

    useEffect(() => {
        if (fetching_cancel === 2) {
            setModalDelete(false)
            history.goBack()
        }
    }, [fetching_cancel])

    useEffect(() => {
        if (fetching_delete === 2) {
            window.location.reload()
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_detail === 2) {
            setModalService(false)
            window.location.reload();
        }
    }, [fetching_detail])

    useEffect(() => {
        if (fetching_list_product === 2) {
            setListProductsCart(data_product)
        }
    }, [fetching_list_product])

    useEffect(() => {
        if (fetching_plate === 2) {
            window.location.reload();

        }
    }, [fetching_plate])
    useEffect(() => {
        if (fetching_additional === 2) {
            window.location.reload();

        }
    }, [fetching_additional])

    useEffect(() => {
        if (fetching_finish === 2) {
            history.goBack();
        }
    }, [fetching_finish])

    useEffect(() => {
        if (fetching_update === 2) {
            data.client.phone = row_client.phone
            setModal(false)
        }
    }, [fetching_update])

    useEffect(() => {
        if (fetching_update_operator === 2) {
            window.location.reload();
            setModal(false)
        }
    }, [fetching_update_operator])

    useEffect(() => {
        if (fetching_operator) {
            setListOperators(data_operators)
        }
    }, [fetching_operator])

    useEffect(() => {
        getListOperators()
    }, [])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setOperator(row.operator)
            setListServices(row.detail)

            if (row.detail_product.length > 0) {
                setListProducts(row.detail_product)
                let _total_products = row.detail_product.reduce((a, b) => a = a + parseFloat(b.price) * parseFloat(b.amount), 0)
                let _amount_products = row.detail_product.reduce((a, b) => a = a + parseFloat(b.amount), 0)

                setTotalProducts(_total_products);
                setAmountProducts(_amount_products)
            }

            let _subtotal = row.detail.reduce((a, b) => a = a + parseFloat(b.price), 0)
            let _discount = row.detail.reduce((a, b) => a = a + parseFloat(b.discount), 0)
            let _total = row.detail.reduce((a, b) => a = a + parseFloat(b.price) - parseFloat(b.discount), 0)

            setSubTotalServices(_subtotal);
            setTotalDiscount(_discount)
            setTotalServices(_total);

        }
    }, [fetching_by_id])

    const onGoBack = () => {
        history.goBack();
    }

    const onEditParameter = (parameter, value) => {
        if (parameter.type === 1) {
            onUpdatePlate(data.id, value)
        } else {
            let form = { id: data.client.id, phone: value, name: data.client.name }
            onUpdateClient(form)
        }
    }



    const onAddProductSelected = (items) => {
        setModalProduct(false)

        onAddAdditionProducts(data.id, items)
    }

    const onEditRow = (item) => {
        setModalService(true)
        setRowService(item)
    }

    const onClickEdit = (form) => {
        onEditServiceDetail(form)

    }

    const onDeleteService = (form) => {
        onDeleteDetail(form.id)

    }

    const onAddServiceModal = () => {
        getListServices(data.category, data.client)
    }

    const onAddServiceDetail = (service) => {
        let param = { "order_id": data.id, "price_id": service.id, "service_id": service.service_id };
        onAddAdditionService(param)
    }

    const onAddOperatorForm = (e) => {
        setModalOperator(false);

        let params = {
            "order_id": data.id,
            "operator_id": e.id
        }

        onAddOperator(params);
    }

    const onEditRowOperator = (ope) => {
        setModalConfirm(true)
        setOperatorSelected(ope);

    }

    const onConfirm = (status) => {

        if (status) {
            let param = {
                "order_id": data.id,
                "id": operatorSelected.id
            }

            onDeleteOperator(param)
        }
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onOpenModal = (title, type, label, value, typeInput) => {
        setModal(true)
        setParameter({ title, type, label, value, typeInput })
    }

    const onShowDeleteOrder = (row) => {
        setModalDelete(true)
        setOrder(row)
    }

    const onDeleteOrderConfirm = (order, reason) => {
        onCancelOrder(order, reason)
    }

    const onViewPdf = (order) => {
        window.open(`https://ws.carwashcol.com/api/orders-view-pdf/${order.id}`, '_blank');
    }

    const onDeleteProduct = (detail) => {
        if (window.confirm(`¿Deseas eliminar ${detail.product.name}?`)) {
            onDeleteAdditionService(detail.id)
        }
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100 d-flex justify-contente-center'>
                        <Col className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Detalle Order #{data.day}</b></Col>
                        <Col xs={2} className="justify-content-end d-flex">
                            <FaPrint className='cursor-pointer mr-3' onClick={() => onViewPdf(data)} />
                            {(role_id === 1 || role_id === 2) &&
                                <MdOutlineClose className='cursor-pointer' onClick={() => onShowDeleteOrder(data)} />}
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            <Container fluid>
                <Row className='d-flex justify-content-center'>
                    <Col lg={5}>
                        <Row>
                            <Col>
                                <Table className='table-sm table-bordered table-sm' >
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th scope='col'>TITULO</th>
                                            <th scope='col'>DESCRIPCIÓN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Fac</b></td>
                                            <td>{data.id}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Fecha</b></td>
                                            <td>{data.updated_at_text}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Cliente</b></td>
                                            <td>{data.client.name}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Placa</b></td>
                                            <td>{data.plate}
                                                {data.status_id !== 3 &&
                                                    <MdModeEditOutline className='cursor-pointer' onClick={() => onOpenModal("Editar Placa", 1, "Placa", data.plate, "text")} />}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Celular</b></td>
                                            <td>{data.client.phone}
                                                {data.status_id !== 3 &&
                                                    <MdModeEditOutline className='cursor-pointer' onClick={() => onOpenModal("Editar Celular", 2, "Celular", data.client.phone, "number")} />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><b>Tipo Vehículo</b></td>
                                            <td>{data.category.title}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Observaciones</b></td>
                                            <td>{data.observations}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                        {data.status_id === 3 && <>
                            <Row>
                                <Col className='text-center'><b>MÉTODOS DE PAGO</b></Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <Table className='table-sm table-bordered table-sm'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th scope='col' className='font-size-12'>MÉTODO DE PAGO</th>
                                                <th scope='col' className='font-size-12'>SUBTOTAL</th>
                                                <th scope='col' className='font-size-12'>PROPINA</th>
                                                <th scope='col' className='font-size-12'>SUBTOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.payments.map((serv, k) => <tr key={k}>
                                                <td>{serv.payment.description}</td>
                                                <td align='right'>{serv.amount_formated}</td>
                                                <td align='right'>{serv.tip}</td>
                                                <td align='right'>{serv.total}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={3}>PENDIENTE DE PAGO</th>
                                                <th className='text-right'>{formatNumber(data.payments.filter(d => d.method_payment_id === 40).reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={3}>TOTAL</th>
                                                <th className='text-right'>{formatNumber(data.payments.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center'>
                                    <Link className='btn btn-sm btn-success' to={`/payment/${data.id}`}>Cambiar Pago</Link>
                                </Col>
                            </Row>
                        </>}
                        <Row className='mt-2'>
                            <Col className='text-center'><h4>Operadores</h4></Col>
                            <Col className='text-center'>
                                {data.status_id !== 3 &&
                                    <button className='btn btn-info text-white btn-sm' onClick={() => setModalOperator(true)}>Agregar Operadores</button>}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Table className='table-sm table-bordered table-sm'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th scope='col'>SERVICIO</th>
                                            <th scope='col'>EDIT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.operators.map((serv, k) => <tr key={k}>
                                            <td>{serv.user.name}</td>
                                            <td>
                                                {data.status_id !== 3 &&
                                                    <MdModeEditOutline className='cursor-pointer' onClick={() => onEditRowOperator(serv)} />}
                                            </td>
                                        </tr>)}
                                    </tbody>

                                </Table>
                            </Col>
                        </Row>

                        <Row className='mt-2'>
                            <Col className='text-center'><h4>Servicios</h4></Col>
                            <Col className='text-center'>
                                {data.status_id !== 3 &&
                                    <button className='btn btn-info text-white btn-sm' onClick={onAddServiceModal}>Agregar más Servicios</button>}

                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Table className='table-sm table-bordered table-sm'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th scope='col'>SERVICIO</th>
                                            <th scope='col'>PRECIO</th>
                                            <th scope='col'>DESC</th>
                                            <th scope='col'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listServices.map((serv, k) => <ItemServices key={k} item={serv} onEditRow={onEditRow} locked={data.status_id === 3 ? true : false} />)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className='font-size-12'><b>TOTAL A PAGAR</b></td>
                                            <td align='right' className='font-size-12'><b>{formatNumber(subTotalServices)}</b></td>
                                            <td align='right' className='font-size-12'><b>{formatNumber(totalDiscount)}</b></td>
                                            <td align='right' className='font-size-12'><b>{formatNumber(totalServices)}</b></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>

                        <Row className='mt-2'>
                            <Col className='text-center'><h4>Productos</h4></Col>
                            <Col className='text-center'>
                                {data.status_id !== 3 &&
                                    <Link className='btn btn-info text-white btn-sm' to={`/cart-products?order_id=${data.id}`}>Agregar más Productos</Link>}

                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <Table className='table-sm table-bordered table-sm'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th scope='col'>PRODUCTO</th>
                                            <th scope='col'>CANTIDAD</th>
                                            <th scope='col'>PRECIO</th>
                                            <th scope='col'>TOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listProducts.map((serv, k) => <ItemProducts key={k} item={serv} onDeleteProduct={onDeleteProduct} />)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td ><b>TOTAL A PAGAR</b></td>
                                            <td align='right'><b>{amountProducts}</b></td>
                                            <td align='right'></td>
                                            <td align='right'><b>{formatNumber(totalProducts)}</b></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9}><b>CANTIDAD DE SERVICIOS</b></Col>
                            <Col><b>{data.count_plate}</b></Col>
                        </Row>
                        {data.photos.length > 0 && <>
                            <Row className='mt-3'>
                                <Col><b>FOTOS AGREGADAS</b></Col>
                            </Row>
                            <Row>
                                {data.photos.map((p, k) => {
                                    let param = { img: p.full_path }
                                    return <ItemPhotos item={param} key={k} />
                                })}
                            </Row>
                        </>}

                        <Row className='mt-4'>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        name='notification'
                                        checked={form.notification}
                                        onChange={updateField}
                                        type={"checkbox"}
                                        label={`Envia Notificación`} />

                                </Form.Group>
                            </Col>
                        </Row>

                        {data.status_id == 1 && <Row className='mt-3'>
                            <Col className='text-center'>
                                <button className='btn btn-danger text-white btn-sm' onClick={() => onFinishOrder(id, form.notification)}>Avisar al Cliente</button>

                            </Col>
                        </Row>}
                        {data.status_id == 4 && <Row className='mt-3'>
                            <Col className='text-center'>
                                <button className='btn btn-warning text-white btn-sm' onClick={() => onRestartOrder(id, form.notification)}>Avisar al Cliente</button>

                            </Col>
                        </Row>}
                        {data.status_id == 2 && <Row className='mt-3'>
                            <Col className='text-center'>
                                <a href={`tel:+${data.client.phone}`}>
                                    <button className='btn-custom-call' >
                                        <MdPhoneEnabled color='white' />
                                    </button>
                                </a>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
            </Container >

            <ModalEditParameter
                show={modal}
                parameter={parameter}
                onClick={onEditParameter}
                onHide={() => setModal(false)}
                listOperators={listOperators}
            />

            <ModalProducts
                show={modalProduct}
                listProducts={listProductsCart}
                onAddProductSelected={onAddProductSelected}
                onHide={() => setModalProduct(false)} />

            <ModalEditService
                show={modalService}
                formEdit={rowService}
                onHide={() => setModalService(false)}
                onClickEdit={onClickEdit}
                onDeleteService={onDeleteService} />

            <ModalServices
                show={modalAddService}
                listServices={listServicesAltern}
                onHide={() => setModalAddService(false)}
                onSelectedService={onAddServiceDetail}
            />

            <ModalOperators
                show={modalOperator}
                listOperators={listOperators}
                onClick={onAddOperatorForm}
                onHide={() => setModalOperator(false)}
            />
            <ModalConfirm show={modalConfirm} title="¿Borrar El operador Asignado?" content={operatorSelected.user.name} button="danger"
                onClick={onConfirm}
                onHide={() => setModalConfirm(false)} />

            <ModalDeleteOrder show={modalDelete} onHide={() => setModalDelete(false)} order={order} onSubmit={onDeleteOrderConfirm} />
        </>
    )
}

const mapsState = (props) => {
    let { order, user, client, product, services } = props

    return {
        fetching_by_id: order.fetching_by_id,
        row: order.row,
        fetching_finish: order.fetching_finish,
        fetching_additional: order.fetching_additional,
        fetching_detail: order.fetching_detail,
        fetching_plate: order.fetching_plate,
        fetching_cancel: order.fetching_cancel,
        fetching_delete: order.fetching_delete,
        fetching_operator: user.fetching_operator,
        data_operators: user.data_operators,
        row_operators: user.row,
        role_id: user.user.role_id,
        fetching_update_operator: user.fetching_update,
        fetching_update: client.fetching_update,
        row_client: client.row,
        fetching_list_product: product.fetching_list,
        data_product: product.data,
        fetching_list_services: services.fetching_list,
        data_services: services.data,
    }
}

export default connect(mapsState, {
    getOrderById, getListOperators, onFinishOrder, onUpdateClient, onUpdateOperator,
    getListProducts, onAddAdditionProducts, onRestartOrder, onEditServiceDetail, onDeleteServiceDetail,
    getListServices, onAddAdditionService, onAddOperator, onDeleteOperator, onDeleteDetail, onUpdatePlate,
    onCancelOrder, onDeleteAdditionService
})(EditCCOrderPage);
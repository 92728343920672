import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../../utils';

const ItemService = ({ item, onSelectedService }) => {
    let { service } = item

    return (
        <Row className='my-1'>
            <Col>
                <Card className='cursor-pointer' onClick={() => onSelectedService(item)}>
                    <Card.Body>
                        <Row>
                            <Col className=''><b>NOMBRE</b></Col>
                        </Row>
                        <Row>
                            <Col className=''>{item.service}</Col>
                        </Row>
                        <Row>
                            <Col className=''><b>DESCRIPCIÓN</b></Col>
                        </Row>
                        <Row>
                            <Col className=''>{item.description}</Col>
                        </Row>
                        <Row>
                            <Col className=''><b>PRECIO</b></Col>
                            <Col className='text-right'><b>{formatNumber(item.price)}</b></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ItemService;
import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';

import ItemOrder from './item.order';
import { MdAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import ListActivePage from './list.active';
import FinishOrder from './FinishOrder'
import { getOrders, getOrdersCC } from '../../Redux/orderDuck'
import { restartStatusService } from '../../Redux/servicesDuck'

import ListPayed from './list.payed';
import ListCCPage from './list.cc';
import ModalDialogTurn from './modal.dialog.turn';

const HomePage = ({ getOrders, fetching_list, data, getOrdersCC, restartStatusService, fetching_active, row }) => {

    const [list, setList] = useState([])
    const [key, setKey] = useState('')
    const [show, setShow] = useState(false)

    useEffect(() => {
        let menu = localStorage.getItem("menu")
        setKey(menu)

        if (menu === 'nuevas') {
            getOrders(1)
        }
        if (menu === 'finalizadas') {
            getOrders(2)
        }

        if (menu === 'pagadas') {
            getOrders(3)
        }
        if (menu === 'cobrar') {
            getOrdersCC(3, 40)
        }
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_active === 2) {
            if (row === null) {
                setShow(false)
            }
            if (row !== null) {
                setShow(true)
            }
        }
    }, [fetching_active])

    const onSelect = (e, otro) => {
        if (e === 'nuevas') {
            setKey("nuevas")
            localStorage.setItem("menu", "nuevas")
            getOrders(1)
        }
        if (e === 'finalizadas') {
            setKey("finalizadas")
            localStorage.setItem("menu", "finalizadas")
            getOrders(2)
        }

        if (e === 'pagadas') {
            setKey("pagadas")
            localStorage.setItem("menu", "pagadas")
            getOrders(3)
        }
        if (e === 'cobrar') {
            setKey("cobrar")
            localStorage.setItem("menu", "cobrar")
            getOrdersCC(3, 40)
        }
    }

    return (
        <MenuTemplate>
            <Row>
                <Col className='px-0'>
                    <Tabs defaultActiveKey="nuevas" id="uncontrolled-tab-example"
                        activeKey={key}
                        onSelect={onSelect}>
                        <Tab eventKey="nuevas" title="Nueva">
                            <ListActivePage list={list} />
                        </Tab>
                        <Tab eventKey="finalizadas" title="Finalizadas">
                            <FinishOrder list={list} />
                        </Tab>
                        <Tab eventKey="pagadas" title="Pagadas">
                            <ListPayed list={list} />
                        </Tab>
                        <Tab eventKey="cobrar" title="Cobrar">
                            <ListCCPage list={list} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>

            <Row className='my-5'>
                <Col></Col>
            </Row>
            {show && <div className='container-btn-add'>
                <Link to={"/step-one"}>
                    REGISTRAR SERVICIOS
                </Link>
            </div>}


            {/* <ModalDialogTurn show={!show} /> */}

        </MenuTemplate>

    )
}

const mapsState = (props) => {
    let { order, turn } = props
    return {
        fetching_list: order.fetching_list,
        data: order.data,
        fetching_active: turn.fetching_active,
        row: turn.row
    }
}

export default connect(mapsState, { getOrders, getOrdersCC, restartStatusService })(HomePage);
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalConfirm = ({ show, title, content, onHide, onClick, button }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>{title}</Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>{content}</Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className={`btn btn-sm btn-${button}`} onClick={() => onClick(true)}>Confirmar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirm;
import React, { Fragment } from 'react'
// import 'index.css'

const InputField = ({ type, name, value, placeholder, onChange, error, min, max }) => {
    type = type || 'text'
    return (
        <Fragment>
            <input
                type={type}
                className="form-control"
                name={name}
                value={value}
                placeholder={placeholder}
                min={min}
                max={max}
                onChange={onChange} />
            {error !== undefined ? (<label className="error-label">{error}</label>) : null}
        </Fragment>
    )
}

export default InputField
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Navbar, Row } from 'react-bootstrap';
import { MdClose, MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getClientById, onDeleteVehicleClient } from '../../Redux/clientDuck'

const VehicleClientPage = ({ getClientById, fetching_by_id, row, onDeleteVehicleClient, fetching_delete }) => {

    const history = useHistory()
    let { id } = useParams();
    const [client, setClient] = useState({ name: '', recurrent: [] })

    useEffect(() => {
        if (fetching_delete === 2) {
            getClientById(id)
        }
    }, [fetching_delete])
    useEffect(() => {
        if (fetching_by_id === 2) {
            setClient(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        getClientById(id)
    }, [id])


    const onGoBack = () => {
        history.goBack()
    }
    const onDeleteVehicle = (item) => {
        if (window.confirm("¿Esta seguro de desvincular")) {
            onDeleteVehicleClient(item.id)
        }
    }


    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Vehículos de {client.name}</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                {client.recurrent.map((d, k) => <Row key={k} className="mt-1">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>{d.plate}</Col>
                                    <Col className='text-right'><MdClose onClick={() => onDeleteVehicle(d)} /></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>)}
            </Container>
        </>
    )
}

const mapsState = (props) => {
    let { client } = props
    return {
        fetching_by_id: client.fetching_by_id,
        fetching_delete: client.fetching_delete,
        row: client.row,
    }
}

export default connect(mapsState, { getClientById, onDeleteVehicleClient })(VehicleClientPage);
import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import InputField from '../../Components/InputField'

const FormClient = ({ formEdit, onSuccess, errors }) => {
    const [form, setForm] = useState({ name: '', phone: '', address: "", email: '' })

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            let phone = formEdit.phone
            let ind = phone.substring(0, 2)
            if (ind === '57') {
                formEdit.phone = phone.substring(2, phone.length)
            }

            setForm(formEdit)
        }
    }, [formEdit])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSuccessPre = () => {
        let _form = form;
        _form.phone = `57${form.phone}`

        if (_form.phone.length !== 12) {
            alert("El numero de telefono no puede ser mayor ni menos que 10 digitos")
            return;
        }

        onSuccess(_form)
    }

    return (
        <>
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Nombre</label>
                        <InputField
                            placeholder="Nombre Cliente"
                            value={form.name || ''}
                            name="name"
                            onChange={updateInput}
                            error={errors.name}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Celular</label>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">57</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                onChange={updateInput}
                                value={form.phone}
                                type='number'
                                placeholder="Número de Telefono"
                                name="phone"
                            />
                        </InputGroup>
                        {errors.phone !== undefined ? (<label className="error-label">{errors.phone}</label>) : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Email</label>
                        <InputField
                            placeholder="Email"
                            value={form.email || ''}
                            name="email"
                            onChange={updateInput}
                            error={errors.email}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Dirección</label>
                        <InputField
                            placeholder="Dirección"
                            value={form.address || ''}
                            name="address"
                            onChange={updateInput}
                            error={errors.address}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <button className='btn btn-sm btn-success' onClick={onSuccessPre}>Guardar</button>
                </Col>
            </Row>
        </>
    )
}

export default FormClient;
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onCreateClient } from '../../../Redux/clientDuck'
import { toogleLoader } from '../../../Redux/alertDuck'

const initState = { business: '', phone: '' }
const initStateError = { business: '', phone: '' }

const ModalClient = ({ show, onHide, onCreateClient, fetching, onSuccess, row, errors_client, clientTypped, toogleLoader }) => {
    const [form, setForm] = useState(initState)
    const [errors, setErrors] = useState(initStateError)

    useEffect(() => {
        setForm({
            ...form,
            ["business"]: clientTypped
        })
    }, [clientTypped])

    useEffect(() => {
        if (fetching === 2) {
            setForm(initState)
            setErrors(initState)
            toogleLoader()
            onSuccess(row)
        }
        if (fetching === 3) {
            setErrors(errors_client)
        }
    }, [fetching])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveClient = () => {
        let errors = {}
        if (form.business === '') {
            errors.business = "Campo Requerido"
        }
        if (form.phone === '') {
            errors.phone = "Campo Requerido"
        }

        if (Object.keys(errors).length === 0) {
            onCreateClient(form);
            toogleLoader()
        } else {
            setErrors(errors)
        }

    }



    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Crear Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Nombres y Apellidos</Form.Label>
                            <Form.Control type="text" placeholder="Nombres y Apellidos" name='business' onChange={updateInput} value={form.business} />
                            <Form.Text className="text-danger">
                                {errors !== undefined && errors.business}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Celular</Form.Label>
                            <Form.Control type="number" placeholder="Celular" name='phone' onChange={updateInput} value={form.phone} />
                            <Form.Text className="text-danger">
                                {errors !== undefined && errors.phone}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" name='email' onChange={updateInput} value={form.email} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onSaveClient}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { client } = props
    return {
        fetching: client.fetching,
        row: client.row,
        errors_client: client.errors
    }
}

export default connect(mapsState, { onCreateClient, toogleLoader })(ModalClient);
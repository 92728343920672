import axios from 'axios'
import { removeSession, errorLoginServer, getHeaders } from '../utils'
import { restartOrder } from './orderDuck'
import { closeSessionTurn } from './turnDuck'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_operator: 0,
    fetching_me: 0,
    fetching_update: 0,
    loggedIn: false,
    data: [],
    row: {},
    data_operators: [],
    errors: {},
}


const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const LOGOUT = "LOGOUT"
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
const LOGIN_ERROR = "LOGIN_ERROR"
const RESTORE_SESSION = "RESTORE_SESSION"
const RESTORE_SESSION_SUCCESS = "RESTORE_SESSION_SUCCESS"
const RESTORE_SESSION_ERROR = "RESTORE_SESSION_ERROR"
const LIST_OPERATOR = "LIST_OPERATOR"
const LIST_OPERATOR_SUCCESS = "LIST_OPERATOR_SUCCESS"
const ME = "ME"
const ME_SUCCESS = "ME_SUCCESS"
const UPDATE_OPERATOR = "UPDATE_OPERATOR"
const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS"

export default function reducer(state = initialState, action) {
    let clients;
    switch (action.type) {
        case LOGIN:
            return { ...state, fetching: 1 };
        case LOGIN_SUCCESS:
            return { ...state, fetching: 2, ...action.payload, loggedIn: true };
        case LOGIN_ERROR:
            return { ...state, fetching: 3, errors: action.payload, loggedIn: false };
        case RESTORE_SESSION:
            return { ...state, fetching: 1 };
        case RESTORE_SESSION_SUCCESS:
            return { ...state, fetching: 2, ...action.payload, loggedIn: true };
        case RESTORE_SESSION_ERROR:
            return { ...state, fetching: 0, loggedIn: false };
        case LIST_OPERATOR:
            return { ...state, fetching_operator: 1 };
        case LIST_OPERATOR_SUCCESS:
            return { ...state, fetching_operator: 2, data_operators: action.payload };
        case ME:
            return { ...state, fetching_me: 1 };
        case ME_SUCCESS:
            return { ...state, fetching_me: 2, row: action.payload };
        case LOGOUT:
            return { ...state, fetching: true };
        case LOGOUT_SUCCESS:
            return { ...state, fetching: false, loggedIn: false };
        case UPDATE_OPERATOR:
            return { ...state, fetching_update: 1 };
        case UPDATE_OPERATOR_SUCCESS:
            return { ...state, fetching_update: 2, row: action.payload };


        default:
            return state;
    }
}


export let onLogin = (form) => (dispatch, state) => {
    dispatch({
        type: LOGIN,
        fetching: true
    })

    let url = `${API_URL}/api/auth/login`
    axios.post(url, form)
        .then(response => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            })
            saveStorage(response.data)
        }).catch(error => {
            let err = {}
            let { status } = error.response

            if (status === 422) {
                err = errorLoginServer(error)
                dispatch({
                    type: LOGIN_ERROR,
                    payload: err
                });
            } else {
                err.message = "Usuario o clave no valido"
                dispatch({
                    type: LOGIN_ERROR,
                    payload: err
                });
            }

        })
}

export let logOutAction = () => (dispatch, getState) => {
    dispatch({
        type: LOGOUT,
    })
    closeSessionTurn()(dispatch, getState)
    removeSession()
    //restartOrder()(dispatch, getState)

    dispatch({
        type: LOGOUT_SUCCESS,
    })
}


export let restoreSessionAction = () => dispatch => {
    dispatch({
        type: RESTORE_SESSION
    })

    let storage = localStorage.getItem("storage")
    storage = JSON.parse(storage)

    if (storage) {
        dispatch({
            type: RESTORE_SESSION_SUCCESS,
            payload: storage
        })
    } else {
        dispatch({
            type: RESTORE_SESSION_ERROR,
            payload: storage
        })
    }
}

export let getListOperators = () => (dispatch, getState) => {
    dispatch({
        type: LIST_OPERATOR,
    });
    let url = `${API_URL}/api/user-admin-operators`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_OPERATOR_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }

}

export let getMe = () => (dispatch, getState) => {
    dispatch({
        type: ME,
    });
    let url = `${API_URL}/api/me`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ME_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }

}


export let onUpdateOperator = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPERATOR,
    });

    let url = `${API_URL}/api/orders-operator/${form.order_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_OPERATOR_SUCCESS,
                payload: form
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onAddOperator = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPERATOR,
    });

    let url = `${API_URL}/api/orders-operator/${form.order_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_OPERATOR_SUCCESS,
                payload: form
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteOperator = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPERATOR,
    });

    let url = `${API_URL}/api/orders-operator/${form.order_id}/${form.id}`;

    // String path =
    //     '${Environment.apiUrl}/api/orders-operator/${operator.orderId}/${operator.id}';

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_OPERATOR_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_OPERATOR,
    });

    let url = `${API_URL}/api/orders-detail/${id}`;


    if (getState().user.loggedIn) {

        fetch(url, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_OPERATOR_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}


function saveStorage(storage) {
    storage.loggedIn = true;
    localStorage.storage = JSON.stringify(storage)
    localStorage.access_token = storage.access_token
    localStorage.branch = JSON.stringify(storage.user.branch_office)
}


import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import MenuTemplate from '../../template/menu.template';
import { getListClients } from '../../Redux/clientDuck'
import { Link } from 'react-router-dom';

const ClientsPage = ({ getListClients, fetching_list, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getListClients()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])


    const onSeachClient = (e) => {
        getListClients(e.target.value)
    }

    return (
        <MenuTemplate>
            <Row>
                <Col className='text-center'>Listado de Clientes</Col>
            </Row>
            <Row className='my-3 d-flex justify-content-center'>
                <Col lg={4}>
                    <Row>
                        <Col>
                            <input className='form-control' placeholder='Buscar' onChange={onSeachClient} />
                        </Col>
                    </Row>
                    {list.map((d, k) => <Row key={k} className="my-1">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>Nombre</Col>
                                        <Col className='text-right'>{d.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Celular</Col>
                                        <Col className='text-right'>{d.phone}</Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col className='text-center'><Link to={`/clients-vehicle/${d.id}`} className='btn btn-warning btn-sm'>Vehiculos Asociados</Link></Col>
                                        <Col className='text-center'><Link to={`/clients/${d.id}`} className='btn btn-info btn-sm'>Editar Cliente</Link></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>)}
                </Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {

    let { client } = props

    return {
        fetching_list: client.fetching_list,
        data: client.data,
    }
}

export default connect(mapsState, { getListClients })(ClientsPage);
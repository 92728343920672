import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import ItemMenu from './item.menu';
import { MdDonutSmall, MdGrading, MdCarRepair, MdOutlineMonetizationOn, MdKeyboardArrowDown, MdOutlineBuildCircle, MdLocalParking, MdShoppingCart, MdCalculate, } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { toogleCalendar, toogleDrawer } from '../Redux/alertDuck'
import PaymentBar from './PaymentBar';
import menu from '../Images/menu.svg'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const MenuTemplate = ({ children, user, currentDate, toogleCalendar, toogleDrawer, turn_active }) => {

    const location = useLocation();
    const history = useHistory();
    const [titleDate, setTitleDate] = useState("")
    const [isPayment, setIsPayment] = useState(true)
    const [selected, setSelected] = useState("")

    useEffect(() => {
        if (location.pathname === '/') {
            setSelected(0);
        }
        if (location.pathname === '/egresos') {
            setSelected(1);
        }
        if (location.pathname === '/caja') {
            setSelected(2);
        }
        if (location.pathname === '/carrito') {
            setSelected(3);
        }
        if (location.pathname === '/parqueaderos') {
            setSelected(4);
        }


    }, [location])

    useEffect(() => {

        if (currentDate !== moment().format("YYYY-MM-DD")) {
            setTitleDate(currentDate)
        } else {
            setTitleDate("Hoy")
        }

    }, [currentDate])

    return (<>
        <PaymentBar user={user} isPayment={isPayment} />

        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Row className='w-100 d-flex justify-content-center'>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Col className='text-left ml-2'>
                                <img src={menu} className="img-menu" onClick={() => toogleDrawer(true)} />
                            </Col>
                            {/* <Col className='text-center cursor-pointer px-0' xs={4} onClick={toogleCalendar}>{titleDate} <MdKeyboardArrowDown /></Col> */}
                            <Col className='text-right font-weight-bold px-0 font-size-14'>
                                {user.business.business}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Navbar>
        <Container fluid className='bg-mail'>
            {children}
        </Container>
        <Navbar fixed="bottom" className='my-0 py-0'>

            <div className='container-menu'>
                {turn_active === null ? <>
                    <Row className='d-flex justify-content-center w-100 pt-3'>
                        <Col className='text-center text-white'>
                            No tiene Turno Activo <Link to={`/turns`} className='text-primary'>Registrar Turnos Aqui</Link>
                        </Col>
                    </Row>
                </> : <>
                    <ItemMenu title={"Nuevas"} to={"/"} icon={<MdCarRepair size={20} color={selected === 0 ? '0080ff' : '575757'} />} selected={selected === 0} />
                    <ItemMenu title={"Egresos"} to={"/egresos"} icon={<MdCalculate size={20} color={selected === 1 ? '0080ff' : '575757'} />} selected={selected === 1} />
                    <ItemMenu title={"Caja"} to={"/caja"} icon={<MdOutlineMonetizationOn size={20} color={selected === 2 ? '0080ff' : '575757'} />} selected={selected === 2} />
                    <ItemMenu title={"Tienda"} to={"/carrito"} icon={<MdShoppingCart size={20} color={selected === 3 ? '0080ff' : '575757'} />} selected={selected === 3} />
                    <ItemMenu title={"Parking"} to={"/parqueaderos"} icon={<MdLocalParking size={20} color={selected === 4 ? '0080ff' : '575757'} />} selected={selected === 4} />

                    {/* <ItemMenuClick title={"Caja"} onClick={toogleDrawer} icon={<MdOutlineBuildCircle size={20} color={selected === 3 ? '2c649d' : '575757'} />} selected={selected === 4} /> */}
                </>}

            </div>
        </Navbar>
    </>
    )
}

const mapsState = (props) => {
    let { user, order, turn } = props

    return {
        user: user.user,
        currentDate: order.currentDate,
        turn_active: turn.row
    }
}

export default connect(mapsState, { toogleCalendar, toogleDrawer })(MenuTemplate);
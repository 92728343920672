import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_method: 0,
    data: [],
    data_method: [],
    errors: {}
}

let LIST_PAYMENT = "LIST_PAYMENT";
let LIST_PAYMENT_SUCCESS = "LIST_PAYMENT_SUCCESS";
let LIST_METHOD_PAYMENT = "LIST_METHOD_PAYMENT";
let LIST_METHOD_PAYMENT_SUCCESS = "LIST_METHOD_PAYMENT_SUCCESS";
let RESTART_PAYMENT = "RESTART_PAYMENT";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PAYMENT:
            return { ...state, fetching_list: 1, }
        case LIST_PAYMENT_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case LIST_METHOD_PAYMENT:
            return { ...state, fetching_method: 1 }
        case LIST_METHOD_PAYMENT_SUCCESS:
            return { ...state, fetching_method: 2, data_method: action.payload }
        case RESTART_PAYMENT:
            return { ...state, fetching_list: 0, fetching_method: 0 }

        default:
            return state;
    }
}

export let restartPayment = () => (dispatch, getState) => {

    dispatch({
        type: RESTART_PAYMENT,
    });
}

export let getMethodPayments = () => (dispatch, getState) => {
    dispatch({
        type: LIST_METHOD_PAYMENT,
    });

    let url = `${API_URL}/api/parameter/payment/segment?source=movil`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_METHOD_PAYMENT_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import generateStore from './Redux/store'
import { SocketProvider } from './context/SocketContext';
import 'bootstrap/dist/css/bootstrap.min.css';
// import * as serviceWorker from './serviceWorker';


let store = generateStore()

let WithRouter = () => <BrowserRouter>
  <App />
</BrowserRouter>

let WithStore = () => (<Provider store={store} >
  <SocketProvider>
    <WithRouter />
  </SocketProvider>
</Provider>)

// serviceWorker.register();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WithStore />
);

// ReactDOM.render(<WithStore />, document.getElementById('root'));





import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import MenuTemplate from '../../template/menu.template';
import { getReportService } from '../../Redux/reportDuck'
import { connect } from 'react-redux';
import { Chart as ChartJs, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import { Line, Doughnut } from 'react-chartjs-2'

ChartJs.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend, Filler
)


const options = {
    reponsive: true,

}


const IndicatorsPage = ({ getReportService, fetching_service, data }) => {
    const [listService, setListService] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [total, setTotal] = useState(0)
    const [graph, setGraph] = useState({})


    useEffect(() => {
        if (fetching_service === 2) {
            setListService(data.results)
            setTotal(data.total)
            setQuantity(data.quantity)

            let labels = data.results.map(d => {
                return d.service
            })
            let dataSet = data.results.map(d => {
                return d.total
            })


            setGraph(
                {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Top 5 Servicios',
                            data: dataSet,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            )
        }
    }, [fetching_service])

    useEffect(() => {
        getReportService()
    }, [])


    return (
        <MenuTemplate>
            <Row className='d-flex justify-content-center mt-3'>
                <Col lg={8} xs={12}>
                    <Row>
                        <Col className='text-center'><h2>TOP 5 SERVICIOS</h2></Col>
                    </Row>
                    <Row>
                        <Col className='text-center'>más Solicitados</Col>
                    </Row>
                    <Row className='d-flex justify-content-center my-5'>
                        <Col lg={6}>
                            {Object.keys(graph).length > 0 && <Doughnut data={graph} />}

                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'><h3>Servicios del Día</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>DESCRIPCIÓN</th>
                                        <th>UNIDAD</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>{listService.map((ser, k) => <tr key={k}>
                                    <td>{ser.service}</td>
                                    <td>{ser.quantity}</td>
                                    <td>{ser.total_formated}</td>
                                </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><b>TOTAL</b></td>
                                        <td>{quantity}</td>
                                        <td><b>{total}</b></td>
                                    </tr>
                                </tfoot>

                            </Table>
                        </Col>
                    </Row>
                    <Row className='mt-5 pt-4'>
                        <Col></Col>
                    </Row>
                </Col>
            </Row>
        </MenuTemplate>
    )
}

const mapsState = (props) => {
    let { report } = props
    return {
        fetching_service: report.fetching_service,
        data: report.data
    }
}

export default connect(mapsState, { getReportService })(IndicatorsPage);
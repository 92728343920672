import React, { useEffect, useState } from 'react'
import { Col, Container, Form, ListGroup, Navbar, Row, Table } from 'react-bootstrap';
import { MdClose, MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getListOperators } from '../../Redux/userDuck'
import { formatNumber } from '../../utils';
import ItemServiceSelected from '../Home/CreateOrder/item.service.selected';
import ModalServices from '../Home/modal.services';

const StepTwoCotization = ({ errors, getListOperators, fetching_operator, data_services, data_operators }) => {

    const history = useHistory()
    const [form, setForm] = useState({ operator_id: 0 })
    const [modal, setModal] = useState(false)
    const [formEdit, setFormEdit] = useState({})
    const [modalEditService, setModalEditService] = useState(false)

    const [showInput, setShowInput] = useState(false)
    const [client, setClient] = useState('');
    const [listServices, setListServices] = useState([])
    const [listServicesSelected, setListServicesSelected] = useState([])

    const [listOperatorSelected, setListOperatorSelected] = useState([])
    const [listOperators, setListOperators] = useState([])

    useEffect(() => {
        getListOperators()
        // getOrderMemory()
    }, [])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperators(data_operators)
        }
    }, [fetching_operator])

    const onGoBack = () => {
        history.goBack()
    }

    const onAddOperator = (e) => {
        let selected = listOperators.filter(d => parseInt(d.id) === parseInt(e.target.value))[0]

        setListOperatorSelected([
            ...listOperatorSelected,
            selected
        ])
    }
    const onEditItemService = (item) => {
        setModalEditService(true)
        setFormEdit(item)
    }

    const onDeleteOperator = (item) => {
        let _list = listOperatorSelected.filter(d => d.id !== item.id)

        setListOperatorSelected(_list)

    }


    const onSelectedService = (item) => {


        setListServicesSelected([
            ...listServicesSelected,
            item
        ]);
        setModal(false)
    }


    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Paso 2</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Operador</Form.Label>
                            <select className='form-control' name='operator_id' value={form.operator_id} onChange={onAddOperator}>
                                <option value={0}>Seleccione</option>
                                {listOperators.map((ope, k) => <option key={ope.id} value={ope.id}>{ope.name}</option>)}
                            </select>
                            <Form.Text className="text-danger">
                                {errors.operator_id}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {listOperatorSelected.map((d, k) => <ListGroup.Item key={k}>
                                <Row>
                                    <Col>{d.name}</Col>
                                    <Col xs={3} className="d-flex justify-content-end"><MdClose onClick={() => onDeleteOperator(d)} /></Col>
                                </Row>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col className='text-center'>
                        <button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Agregar Servicio</button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table className='table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Servicio</th>
                                    <th>Subtotal</th>
                                    <th>Descuento</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listServicesSelected.map((serv, k) => <ItemServiceSelected key={k} item={serv} onEditItem={onEditItemService} />)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className='font-size-11'><b>TOTAL</b></td>
                                    <td className='font-size-11'>
                                        ${formatNumber(listServicesSelected.reduce((a, b) => a = a + parseFloat(b.price), 0))}
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <ModalServices show={modal} listServices={data_services} onHide={() => setModal(false)} onSelectedService={onSelectedService} />


            </Container>
        </>
    )
}


const mapsState = (props) => {
    let { cotization, services, user } = props

    return {
        errors: cotization.errors,
        fetching_operator: user.fetching_operator,
        data_operators: user.data_operators,
        data_services: services.data,

    }
}
export default connect(mapsState, { getListOperators })(StepTwoCotization);
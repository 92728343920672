import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL


const ItemOrderPayed = ({ item, bg = "light" }) => {
    let image = API_URL + "/" + item.image

    return (
        <Col lg={4} md={4} className="my-1 px-0 mx-1">
            <Card bg={bg}>
                <Card.Body className='px-3 py-2'>
                    <Row>
                        <Col className='text-left' xs={3}>
                            <div className='div-user'>
                                {item.administrator}
                            </div>
                        </Col>
                        <Col className={`text-right mx-0 `}>
                            {item.created_at}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col><b>Order #{item.day}</b> <span className='font-size-12 text-muted'>({item.id})</span></Col>
                            </Row>
                            <Row>
                                <Col><b>Placa</b> {item.plate}</Col>
                            </Row>
                            <Row>
                                <Col><b>Tipo</b> {item.category}</Col>
                            </Row>
                            <Row>
                                <Col><b>Cliente</b> {item.client}</Col>
                            </Row>
                            <Row>
                                <Col><b>Operador</b> {item.operators}</Col>
                            </Row>
                            <Row>
                                <Col><b>Turno</b> {item.turn}</Col>
                            </Row>
                            <Row>
                                <Col><b>Turno</b> {item.payments}</Col>
                            </Row>
                            <Row>
                                <Col><b>TOTAL</b> {formatNumber(item.total)}</Col>
                            </Row>
                            {item.observations !== '' && <Row>
                                <Col><b>Observaciones</b> {item.observations}</Col>
                            </Row>}


                        </Col>
                        <Col lg={3} xs={3} className='d-flex justify-content-center flex-column align-items-center'>
                            <Row className='d-flex justify-content-center'>
                                <Col xs={6}>
                                    <img src={image} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col xs={6}>
                                    <Link to={`/order/${item.id}`} className="btn btn-success text-white">Ver</Link>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ItemOrderPayed;
import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';

const initState = { parameter_id: 0, user_id: 0, description: '', value: '' };

const ModalExpense = ({ show, parameter, listParameter, onSuccess, onHide, listOperators }) => {

    const [form, setForm] = useState(initState)

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSave = () => {
        let _form = form
        _form.type = parameter.type;

        if (form.value !== '') {
            onSuccess(_form)
            setForm(initState)
        } else {
            alert("El valor no puede estar vacio")
        }

    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <h5 className='text-center'>AGREGAR COSTOS {parameter.title}</h5>
            </Modal.Header>
            <Modal.Body>
                {parameter.type === 1 ? <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Sección</Form.Label>
                            <select className='form-control' name='parameter_id' value={form.parameter_id} onChange={updateField} >
                                <option value={0}>Seleccione</option>
                                {listParameter.map((ope, k) => <option key={k} value={ope.id}>{ope.description}</option>)}
                            </select>
                        </Form.Group>
                    </Col>
                </Row> :
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Trabajadores</Form.Label>
                                <select className='form-control' name='user_id' value={form.user_id} onChange={updateField} >
                                    <option value={0}>Seleccione</option>
                                    {listOperators.map((ope, k) => <option key={k} value={ope.id}>{ope.name}</option>)}
                                </select>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control type="text" placeholder="Descripción" name="description" value={form.description} onChange={updateField} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Costo</Form.Label>
                            <Form.Control type="number" placeholder="value" name="value" value={form.value} onChange={updateField} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onSave}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalExpense;
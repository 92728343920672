import React, { useEffect, useState } from 'react'
import { Card, Col, Container, ListGroup, Navbar, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { MdWest } from 'react-icons/md';

import { onAddAdditionProducts } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import './index.css'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FormCart from './form.cart';
import SimpleTemplate from '../../template/simple.template';

const CartTakePage = ({ title }) => {

    const history = useHistory()
    const [listProducts, setListProducts] = useState([])

    useEffect(() => {
        let products = localStorage.getItem("products")
        if (products !== null) {
            setListProducts(JSON.parse(products))
        }
    }, [])

    const onAddProductOrder = (products) => {
        localStorage.setItem("products", JSON.stringify(products))
        history.push("/cart-payment")
    }

    return (

        <SimpleTemplate title={"Agregar Productos"}>
            <Container fluid>
                <Row>
                    <Col>
                        <FormCart onSuccess={onAddProductOrder} title="Pagar" list_products={listProducts} />
                    </Col>
                </Row>
            </Container>
        </SimpleTemplate>
    )
}

const mapsState = (props) => {
    let { category, order } = props

    return {
        fetching_list_product: category.fetching_list_product,
        data_category: category.data_category,
    }
}

export default connect(mapsState, { onAddAdditionProducts })(CartTakePage);
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
const initState = { price: 0, discount: 0, comment: '' }
const ModalEditService = ({ show, onHide, onClickEdit, formEdit, onDeleteService }) => {
    const [form, setForm] = useState(initState)
    const [cost, setCost] = useState(0)

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
            setCost(formEdit.price)
        }

    }, [formEdit])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onModifyPrice = () => {
        let _form = form;

        if (parseFloat(cost) !== parseFloat(form.price)) {
            _form.old_price = form.price
        }

        _form.price = cost
        onClickEdit(form)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Row>
                    <Col>¿Deseas Editar o Eliminar el Servicio?</Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Cambios de Precio</label>
                            <input type="number" className='form-control' name="price" value={cost} onChange={(e) => setCost(e.target.value)} />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Descuento</label>
                            <input type="number" className='form-control' name='discount' placeholder="0" value={form.discount} onChange={updateInput} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Comentarios</label>
                            <textarea className='form-control' name='comment' placeholder='Agrega un comentario' value={form.comment} onChange={updateInput}></textarea>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger mx-3' onClick={() => onDeleteService(form)}>Eliminar</button>
                        <button className='btn btn-sm btn-info' onClick={onModifyPrice}>Editar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalEditService;
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL


const ItemOrder = ({ item }) => {
    let image = API_URL + "/" + item.image

    return (
        <Col lg={4} md={5} className="my-1">
            <Card>
                <Card.Body className='px-3 py-2'>
                    <Row>
                        <Col className='text-left' xs={3}>
                            <div className='div-user'>
                                {item.administrator}
                            </div>
                        </Col>
                        <Col className='font-size-11'>{item.created_at}</Col>
                        <Col className={`text-right mx-0 `}>
                            <span className={`${item.status_id === 4 && 'text-warning'}`}>{item.status}</span>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <Row>
                                <Col><b>Order #{item.day}</b> <span className='font-size-12 text-muted'>({item.id})</span></Col>
                            </Row>
                            <Row>
                                <Col><b>Placa</b> {item.plate}</Col>
                            </Row>
                            <Row>
                                <Col><b>Cliente</b> {item.client}</Col>
                            </Row>
                            <Row>
                                <Col><b>Operador</b> {item.operators}</Col>
                            </Row>
                            {item.observations !== '' && <Row>
                                <Col><b>Observaciones</b> {item.observations}</Col>
                            </Row>}
                            <Row className='border-top'>
                                <Col><b>TOTAL A PAGAR</b> {formatNumber(item.total)}</Col>
                            </Row>

                        </Col>
                        <Col lg={3} xs={3}>
                            <Row className='d-flex justify-content-end'>
                                <Col xs={8}>
                                    <img src={image} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col >
                                    {item.status_id === 4 ? <Link to={`/order/${item.id}`} className="btn btn-warning text-white">Iniciar</Link> :
                                        <Link to={`/order/${item.id}`} className="btn btn-info text-white">Entregar</Link>}
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ItemOrder;
import React, { useEffect, useState } from 'react'
import { Col, Container, FormControl, InputGroup, Navbar, Row } from 'react-bootstrap';
import { MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputField from '../../Components/InputField';

import { getActiveTurn, onCreateTurn, onFinishTurn } from '../../Redux/turnDuck'
import { formatNumber } from '../../utils';
import ItemMoney from './item.money';

const CreateTurnPage = ({ getActiveTurn, onCreateTurn, fetching_create, fetching_active, row, onFinishTurn, fetching_finish }) => {

    const [form, setForm] = useState({ open_cash: 0, percent: 0 })
    const [errors, setErrors] = useState({})
    const [active, setActive] = useState({ user: { name: '' } })
    const [total, setTotal] = useState(0)
    const [money, setMoney] = useState([
        { id: 1, "denomination": 100000, quantity: 0 },
        { id: 2, "denomination": 50000, quantity: 0 },
        { id: 3, "denomination": 20000, quantity: 0 },
        { id: 4, "denomination": 10000, quantity: 0 },
        { id: 5, "denomination": 5000, quantity: 0 },
        { id: 6, "denomination": 2000, quantity: 0 },
        { id: 7, "denomination": 1000, quantity: 0 },
        { id: 8, "denomination": 500, quantity: 0 },
        { id: 9, "denomination": 200, quantity: 0 },
        { id: 10, "denomination": 100, quantity: 0 },
        { id: 11, "denomination": 50, quantity: 0 },
    ])

    const history = useHistory()

    useEffect(() => {
        if (fetching_finish === 2) {
            history.push("/turns")
        }

    }, [fetching_finish])

    useEffect(() => {
        if (fetching_create === 2) {
            history.push("/")
        }

    }, [fetching_create])

    useEffect(() => {

        if (fetching_active === 2) {
            setActive(row)
        }

    }, [fetching_active])

    useEffect(() => {
        getActiveTurn()
    }, [])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onGoBack = () => {
        history.goBack();
    }

    const onCreate = () => {
        let _form = form

        _form.open_cash = total;

        onCreateTurn(_form)
    }

    const onCloseTurn = () => {
        onFinishTurn(active.id);
    }

    const onUpdateRow = (row, value) => {
        const index = money.findIndex(item => item.id === row.id);

        const newItems = [...money];
        newItems[index] = { ...newItems[index], quantity: value };
        let _total = newItems.reduce((a, b) => a + b.quantity * b.denomination, 0)
        setTotal(_total)
        setMoney(newItems);
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Crear Turno</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                {active === null ? <>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={5}>
                            <div className="form-group">
                                <label>Cambiar porcentaje Liquidación</label>
                                <input type="range" min="0" max="100" value={form.percent}
                                    className="slider" id="myRange" onChange={(e) => setForm({ ...form, percent: parseInt(e.target.value) })} />
                                <span className='ml-4'>{form.percent}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col className='text-center'>
                            <h3>Ingresar el Dinero</h3>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={5}>
                            <table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Denominación</th>
                                        <th>Cantidad</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {money.map((a, k) => <ItemMoney key={k} item={a} onUpdateRow={onUpdateRow} />)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL</th>
                                        <th className='text-right'>{formatNumber(total)}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </Col>
                    </Row>
                </> :
                    <>
                        <Row className='d-flex justify-content-center'>
                            <Col lg={5}>
                                <Row className='my-4'>
                                    <Col>Fecha Apertura</Col>
                                    <Col className='text-right'>{active.date_start}</Col>
                                </Row>
                                <Row className='my-4'>
                                    <Col>Fecha Cierre</Col>
                                    <Col className='text-right'>{active.date_end}</Col>
                                </Row>
                                <Row className='my-4'>
                                    <Col>Monto Apertura</Col>
                                    <Col className='text-right'>{active.open_cash_formated}</Col>
                                </Row>
                                <Row className='my-4'>
                                    <Col>Usuario</Col>
                                    <Col className='text-right'>{active.user.name}</Col>
                                </Row>
                            </Col>
                        </Row>

                    </>}

                <Row>
                    <Col className='d-flex justify-content-center'>
                        {active === null ? <button className='btn btn-sm btn-success' onClick={onCreate}>Ingresar</button> :
                            <button className='btn btn-sm btn-warning' onClick={onCloseTurn}>Cerrar Turno</button>}

                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapsState = (props) => {
    let { turn } = props

    return {
        fetching_create: turn.fetching_create,
        fetching_active: turn.fetching_active,
        fetching_finish: turn.fetching_finish,
        row: turn.row
    }
}

export default connect(mapsState, { getActiveTurn, onCreateTurn, onFinishTurn })(CreateTurnPage);
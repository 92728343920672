import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_update: 0,
    fetching_by_id: 0,
    fetching_vehicle: 0,
    fetching_delete: 0,
    fetching_default: 0,
    data: [],
    row: {},
    errors: {},
    client_default: {}
}

let CREATE_CLIENT = "CREATE_CLIENT";
let CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
let CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";
let UPDATE_CLIENT = "UPDATE_CLIENT";
let UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
let LIST_CLIENT = "LIST_CLIENT";
let LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS";
let CLIENT_BY_ID = "CLIENT_BY_ID";
let CLIENT_BY_ID_SUCCESS = "CLIENT_BY_ID_SUCCESS";
let DELETE_VEHICLE = "DELETE_VEHICLE";
let DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
let CLIENT_DEFAULT = "CLIENT_DEFAULT";
let CLIENT_DEFAULT_SUCCESS = "CLIENT_DEFAULT_SUCCESS";
let RESTART_CLIENT = "RESTART_CLIENT";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CLIENT:
            return { ...state, fetching_list: 1, }
        case LIST_CLIENT_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_update: 0 }
        case CREATE_CLIENT:
            return { ...state, fetching: 1, }
        case CREATE_CLIENT_SUCCESS:
            return { ...state, fetching: 2, row: action.payload }
        case CREATE_CLIENT_ERROR:
            return { ...state, fetching: 3, errors: action.payload }
        case UPDATE_CLIENT:
            return { ...state, fetching_update: 1 }
        case UPDATE_CLIENT_SUCCESS:
            return { ...state, fetching_update: 2, row: action.payload }
        case CLIENT_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case CLIENT_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case DELETE_VEHICLE:
            return { ...state, fetching_delete: 1 }
        case DELETE_VEHICLE_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case CLIENT_DEFAULT:
            return { ...state, fetching_default: 1 }
        case CLIENT_DEFAULT_SUCCESS:
            return { ...state, fetching_default: 2, client_default: action.payload }
        case RESTART_CLIENT:
            return { ...state, fetching: 0 }
        default:
            return state;
    }
}

export let restartStatus = () => (dispatch, getState) => {
    dispatch({
        type: RESTART_CLIENT,
    });

}

export let getListClients = (search) => (dispatch, getState) => {
    dispatch({
        type: LIST_CLIENT,
    });

    let url = `${API_URL}/api/clients`;
    console.log('search', search);

    if (search !== '' && search !== undefined) {
        if (!isNaN(search)) {
            search = `57${search}`
        }

        url += `?search=${search}`
    }

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                dispatch({
                    type: CREATE_CLIENT_ERROR,
                    payload: resp.errors
                });
            } else {
                dispatch({
                    type: LIST_CLIENT_SUCCESS,
                    payload: resp.results
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getClientDefault = () => (dispatch, getState) => {
    dispatch({
        type: CLIENT_DEFAULT,
    });

    let url = `${API_URL}/api/clients-default`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: CLIENT_DEFAULT_SUCCESS,
                    payload: resp
                });

            }
        })
    } else {
        console.log("no Ingreso");
    }
}


export let getClientById = (id) => (dispatch, getState) => {
    dispatch({
        type: CLIENT_BY_ID,
    });

    let url = `${API_URL}/api/clients/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                dispatch({
                    type: CREATE_CLIENT_ERROR,
                    payload: resp.errors
                });
            } else {
                dispatch({
                    type: CLIENT_BY_ID_SUCCESS,
                    payload: resp
                });

            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateClient = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CLIENT,
    });

    let url = `${API_URL}/api/clients`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                dispatch({
                    type: CREATE_CLIENT_ERROR,
                    payload: resp.errors
                });
            } else {
                localStorage.setItem("client", JSON.stringify(resp))

                dispatch({
                    type: CREATE_CLIENT_SUCCESS,
                    payload: resp
                });

            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onUpdateClient = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_CLIENT,
    });

    let url = `${API_URL}/api/clients/${form.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: UPDATE_CLIENT_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }

}

export let onDeleteVehicleClient = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_VEHICLE,
    });

    let url = `${API_URL}/api/clients-vehicles/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: DELETE_VEHICLE_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }

}
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalOperators = ({ show, listOperators, onClick, onHide }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Operadores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    {listOperators.map((d, k) =>
                        <li key={k} className='list-group-item' onClick={() => onClick(d)}>{d.name}</li>
                    )}
                </ul>


            </Modal.Body>
        </Modal >
    )
}

export default ModalOperators;
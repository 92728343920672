import React, { useEffect, useState } from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import InputField from '../../Components/InputField';
import { getClientById, onUpdateClient } from '../../Redux/clientDuck'
import FormClient from './form.client';

const EditClientPage = ({ getClientById, fetching_by_id, row, onUpdateClient, fetching_update }) => {

    let { id } = useParams()
    const history = useHistory()
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(() => {
        getClientById(id)
    }, [id])

    useEffect(() => {
        if (fetching_update === 2) {
            history.goBack()
        }
    }, [fetching_update])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    const onGoBack = () => {
        history.goBack()
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Editar Cliente</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col lg={5}>
                        <FormClient formEdit={form} onSuccess={onUpdateClient} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const mapsState = (props) => {
    let { client } = props
    return {
        fetching_by_id: client.fetching_by_id,
        row: client.row,
        fetching_update: client.fetching_update
    }
}

export default connect(mapsState, { getClientById, onUpdateClient })(EditClientPage);
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_service: 0,
    data: [],
    errors: {}
}

let REPORT_SERVICE = "REPORT_SERVICE";
let REPORT_SERVICE_SUCCESS = "REPORT_SERVICE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REPORT_SERVICE:
            return { ...state, fetching_service: 1, }
        case REPORT_SERVICE_SUCCESS:
            return { ...state, fetching_service: 2, data: action.payload }
        default:
            return state;
    }
}

export let getReportService = () => (dispatch, getState) => {
    dispatch({
        type: REPORT_SERVICE,
    });

    let { currentDate } = getState().order;


    let url = `${API_URL}/api/report/service?date=${currentDate}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: REPORT_SERVICE_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
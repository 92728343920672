import React, { useEffect, useState } from 'react'
import { Card, Col, Container, ListGroup, Navbar, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { MdWest } from 'react-icons/md';

import { onAddAdditionProducts } from '../../Redux/orderDuck'
import { connect } from 'react-redux';
import './index.css'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import FormCart from './form.cart';
import SimpleTemplate from '../../template/simple.template';

const CartProductsPage = ({ onAddAdditionProducts, fetching_additional }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');
    const history = useHistory();


    useEffect(() => {
        if (fetching_additional === 2) {
            history.goBack()
        }
    }, [fetching_additional])


    const onAddProductOrder = (products) => {
        onAddAdditionProducts(orderId, products)
    }

    return (
        <SimpleTemplate title={"Agregar Productos"}>
            <Container fluid>
                <Row>
                    <Col>
                        <FormCart onSuccess={onAddProductOrder} title="Agregar" list_products={[]} />
                    </Col>
                </Row>
            </Container>
        </SimpleTemplate>
    )
}

const mapsState = (props) => {
    let { category, order } = props

    return {
        fetching_list_product: category.fetching_list_product,
        data_category: category.data_category,
        fetching_additional: order.fetching_additional
    }
}

export default connect(mapsState, { onAddAdditionProducts })(CartProductsPage);
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    data: [],
    errors: {}
}

let LIST_SERVICES = "LIST_SERVICES";
let LIST_SERVICES_SUCCESS = "LIST_SERVICES_SUCCESS";
let RESTART_STATUS_SERVICES = "RESTART_STATUS_SERVICES";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SERVICES:
            return { ...state, fetching_list: 1, }
        case LIST_SERVICES_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case RESTART_STATUS_SERVICES:
            return { ...state, fetching_list: 0, }
        default:
            return state;
    }
}

export let restartStatusService = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICES,
    })
}

export let getListServices = (category, client_extern) => (dispatch, getState) => {
    dispatch({
        type: LIST_SERVICES,
    });

    let client_id = 0;

    if (client_extern === undefined) {
        let client = JSON.parse(localStorage.getItem("client"))
        client_id = client.id
    } else {
        client_id = client_extern.id
    }

    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/services/${category.id}/category-lite?client_id=${client_id}&branch_id=${branch.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SERVICES_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import MenuTemplate from '../../template/menu.template';

import take_img from '../../Images/take.png'
import history_img from '../../Images/history.png'
import inventory_img from '../../Images/inventory.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { restartCart } from '../../Redux/cartDuck'
import { connect } from 'react-redux';

const CartPage = ({ restartCart }) => {
    const history = useHistory()

    useEffect(() => {
        restartCart()
    }, [])

    const goTo = (link) => {
        history.push(link)
    }
    return (
        <MenuTemplate>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={4}>
                    <Row>
                        <Col className='text-center'>
                            <Card className='cursor-pointer' onClick={() => goTo("/cart-take")}>
                                <Card.Img src={take_img} />
                                <Card.Body>
                                    <Card.Title>Para Llevar</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='text-center'>
                            <Card className='cursor-pointer' onClick={() => goTo("/cart-history")}>
                                <Card.Img src={history_img} />
                                <Card.Body>
                                    <Card.Title>Historial</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='text-center'>
                            <Card className='cursor-pointer' onClick={() => goTo("/cart-inventory")}>
                                <Card.Img src={inventory_img} />
                                <Card.Body>
                                    <Card.Title>Inventario</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>

            </Row>
            {/* <Row className='d-flex justify-content-center mt-4'>
                <Col lg={2}>
                    <Row>
                        <Col className='text-center'>
                            <Card className='cursor-pointer' onClick={() => goTo("/cart-inventory")}>
                                <Card.Img src={inventory_img} />
                                <Card.Body>
                                    <Card.Title>Inventario</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Col>

            </Row> */}

        </MenuTemplate>
    )
}

const mapsState = (props) => {
    return {

    }
}

export default connect(mapsState, { restartCart })(CartPage);
import React from 'react'
import { Card, Col } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';
import './index.css'

const ItemPhotos = ({ item, onDelete }) => {
    return (
        <Col lg={3} xs={4}>
            <Card>
                <Card.Body className='p-0'>
                    <div className='container-image'>
                        <img src={item.img} className="img-fluid" />
                        <div className='btn-close-photo' onClick={() => onDelete(item)}>
                            <MdClose />
                        </div>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    )
}

export default ItemPhotos;
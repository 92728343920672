import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../utils'
const API_URL = process.env.REACT_APP_API_URL


const ItemOrderFinish = ({ item, onClick }) => {
    let image = API_URL + "/" + item.image

    return (
        <Col lg={4} className="my-1 px-0">
            <Card className={`${item.status_id === 3 && 'card-finish'}`}>
                <Card.Body className='px-2 py-1'>
                    <Row>
                        <Col className='text-left' xs={4}>
                            <div className='div-user'>
                                {item.administrator}
                            </div>
                        </Col>

                        <Col className="text-center font-size-11">{item.status}</Col>
                        <Col className='text-center font-size-11 px-1'>{item.created_at}</Col>

                    </Row>
                    <Row>

                        <Col>
                            <Row>
                                <Col><b>Order #{item.day}</b></Col>
                            </Row>
                            <Row>
                                <Col>Placa: {item.plate}</Col>
                            </Row>
                            <Row>
                                <Col>Cliente: {item.client}</Col>
                            </Row>
                            <Row>
                                <Col>Operador: {item.operators}</Col>
                            </Row>
                            <Row>
                                <Col>Método de Pago: {item.payments}</Col>
                            </Row>
                            <Row>
                                <Col>TOTAL A PAGAR: <b>{formatNumber(item.total)}</b></Col>
                            </Row>

                        </Col>
                        <Col lg={3} xs={3}>
                            <Row className="d-flex justify-content-end">
                                <Col xs={8}>
                                    <img src={image} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Link to={`/order/${item.id}`} className="btn btn-success text-white form-control">Ver</Link>
                                </Col>
                            </Row>

                            {item.status_id === 2 &&
                                <Row className="mt-2">
                                    <Col>
                                        <Link to={`/payment/${item.id}`} className="btn btn-danger text-white form-control">Pagar</Link>
                                    </Col>
                                </Row>
                            }


                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ItemOrderFinish;
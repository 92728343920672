import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import { toogleCalendar } from '../Redux/alertDuck'
import { onChangeDate } from '../Redux/orderDuck'


const LoaderPage = ({ calendar, toogleCalendar, onChangeDate }) => {

    const onSelectedDate = (e) => {
        let date = moment(e).format("YYYY-MM-DD")
        onChangeDate(date);
        window.location.reload()

    }

    const onClickOut = () => {
        toogleCalendar()
    }

    return (
        <Container fluid className={`bg-loader ${!calendar && 'd-none'}`} onClick={onClickOut}>
            <Row>
                <Col>
                    <Calendar onChange={onSelectedDate} />
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { alert } = props

    return {
        calendar: alert.calendar
    }
}

export default connect(mapsState, { toogleCalendar, onChangeDate })(LoaderPage);
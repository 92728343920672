import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    data: [],
    errors: {}
}

let LIST_PARAMETER = "LIST_PARAMETER";
let LIST_PARAMETER_SUCCESS = "LIST_PARAMETER_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PARAMETER:
            return { ...state, fetching_list: 1, }
        case LIST_PARAMETER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        default:
            return state;
    }
}

export let getParameterSegment = (segment) => (dispatch, getState) => {
    dispatch({
        type: LIST_PARAMETER,
    });

    let url = `${API_URL}/api/parameter/${segment}/segment`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_PARAMETER_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import MenuTemplate from '../../../template/menu.template';
import { getOrders, onAddPayment } from '../../../Redux/orderDuck'
import { getParameterSegment } from '../../../Redux/parameterDuck'
import { connect } from 'react-redux';
import ItemOrderFinish from './item.order.finish';
import ModalPayment from './modal.payment';

const FinishOrderPage = ({ getOrders, fetching_list, data, getParameterSegment, fetching_list_parameter, data_parameter, onAddPayment,
    fetching_payment, list }) => {

    const [listParameter, setListParameter] = useState([])
    const [order, setOrder] = useState({})
    const [modal, setModal] = useState(false)

    useEffect(() => {
        getParameterSegment("payment")
    }, [])

    useEffect(() => {
        if (fetching_payment === 2) {
            setModal(false)
            // getOrders(2)
        }
    }, [fetching_payment])

    useEffect(() => {
        if (fetching_list_parameter === 2) {
            setListParameter(data_parameter)
        }

    }, [fetching_list_parameter])


    const onOpenModalPayment = (item) => {
        setOrder(item)
        setModal(true)
    }

    const onAddPaymentModal = (value) => {
        onAddPayment(order.id, value)
    }

    return (
        <Container fluid>
            <Row className='mt-3'>
                {(list.length > 0) ? list.map((ord, k) => <ItemOrderFinish item={ord} key={k} onClick={onOpenModalPayment} />)
                    : <Col className='text-center'><h3>No Tienes Ordenes Finalizadas</h3></Col>}
            </Row>
            <Row className='my-5'>
                <Col></Col>
            </Row>
            <ModalPayment show={modal} listMethods={listParameter} onSuccess={onAddPaymentModal} />
        </Container>
    )
}

const mapsState = (props) => {
    let { order, parameter } = props
    return {
        fetching_list_parameter: parameter.fetching_list,
        data_parameter: parameter.data,
        fetching_payment: order.fetching_payment,
    }
}

export default connect(mapsState, { getOrders, getParameterSegment, onAddPayment })(FinishOrderPage);
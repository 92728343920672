import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ItemMenu = ({ title, icon, to, selected }) => {

    return (

        <div className={`container-menu-item ${selected && 'border-top-custom'}`}>
            <Link to={to}>
                <div>
                    {icon}
                </div>
                <div className={`font-size-12 ${selected ? 'text-blue font-weight-bold' : 'text-grey'}`}>{title}</div>
            </Link>
        </div>
    )
}

export default ItemMenu;
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { connect } from 'react-redux';
import { getListCategoryProducts } from '../../Redux/categoryDuck'

const FormCart = ({ getListCategoryProducts, fetching_list_product, data_category, onSuccess, title, list_products }) => {
    const [listCategory, setListCategory] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [listSelectedProducts, setListSelectedProducts] = useState([])
    const [categorySelected, setCategorySelected] = useState({})

    useEffect(() => {
        if (list_products.length > 0) {
            setListSelectedProducts(list_products)
        }

    }, [list_products])

    useEffect(() => {
        getListCategoryProducts()
    }, [])

    useEffect(() => {
        if (fetching_list_product === 2) {
            if (data_category.length > 0) {
                setListCategory(data_category)
                setListProducts(data_category[0].products)
                setCategorySelected(data_category[0])
            }
        }
    }, [fetching_list_product])

    const onSelectCategory = (category) => {
        setCategorySelected(category)
        setListProducts(category.products)
    }

    const validateItems = (product_id) => {
        let total = 0
        if (listSelectedProducts.length > 0) {
            total = listSelectedProducts.filter(d => d.id === product_id).reduce((a, b) => a + parseInt(b.quantity), 0)
            return total;
        }

        return total
    }
    const getTotalProduct = () => {
        let total = listSelectedProducts.reduce((a, b) => a + parseFloat(b.price) * parseFloat(b.quantity), 0)
        return total
    }

    const onDeleteProductCart = (product) => {
        let selected = listSelectedProducts.filter(d => d.id === product.id)

        if (selected.length > 0) {
            let quantity = selected[0].quantity - 1

            if (quantity > 0) {
                setListSelectedProducts(prevItems => {
                    // Encuentra el índice del objeto a actualizar
                    const index = prevItems.findIndex(item => product.id === item.id);
                    if (index !== -1) {
                        const newItems = [...prevItems];
                        newItems[index] = { ...newItems[index], quantity: quantity };
                        return newItems;
                    }
                    return prevItems; // Si el objeto no se encuentra, devuelve el array sin cambios
                });
            } else {
                setListSelectedProducts(prevItems => prevItems.filter(item => item.id !== product.id));
            }
        }
    }

    const onAddProductCart = (product) => {

        let selected = listSelectedProducts.filter(d => d.id === product.id)

        if (selected.length === 0) {
            product.quantity = 1;
            setListSelectedProducts([
                ...listSelectedProducts,
                product
            ])
        } else {
            let quantity = selected[0].quantity + 1

            setListSelectedProducts(prevItems => {
                // Encuentra el índice del objeto a actualizar
                const index = prevItems.findIndex(item => product.id === item.id);
                if (index !== -1) {
                    const newItems = [...prevItems];
                    newItems[index] = { ...newItems[index], quantity: quantity };
                    return newItems;
                }
                return prevItems; // Si el objeto no se encuentra, devuelve el array sin cambios
            });
        }
    }

    const onAddProducts = () => {
        onSuccess(listSelectedProducts)
    }

    return (
        <Container fluid>
            {listCategory.length > 0 ? <>
                <Row>
                    <Col lg={3} xs={5} className='px-0'>
                        <ListGroup defaultActiveKey="#link1">
                            {listCategory.map((d, k) => <ListGroup.Item className={`${d.id === categorySelected.id ? 'active' : ''}`} key={k} action onClick={() => onSelectCategory(d)}>
                                {d.name} ({d.products.length})
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                    <Col className='px-0'>
                        <Row className='pb-5'>
                            {listProducts.map((d, k) => <Col className='pt-2' lg={4} key={k}>
                                <Card>
                                    <Card.Img variant="top" src={d.image} />

                                    <Card.Body>
                                        <Card.Title>{d.name}</Card.Title>
                                        <Card.Text>{d.description}</Card.Text>
                                    </Card.Body>
                                    <Row className='d-flex justify-content-end my-2 pr-2 pl-3' >
                                        <Col className='d-flex align-items-center font-weight-bold' xs={4}>{d.price_formated}</Col>
                                        <Col lg={2} xs={3}>
                                            <button className='btn-add' onClick={() => onDeleteProductCart(d)}>-</button>
                                        </Col>
                                        <Col lg={1} xs={2} className='d-flex align-items-center'>{validateItems(d.id)}</Col>
                                        <Col lg={2} xs={3}>
                                            <button className='btn-add' type='button' onClick={() => onAddProductCart(d)}>+</button>
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>)}
                        </Row>
                    </Col>
                </Row>
                <Row className='fixed-bottom'>
                    <Col className='d-flex align-items-center' xs={8}>TOTAL</Col>
                    <Col lg={2} xs={4}>
                        <Row>
                            <Col>
                                <button className='btn btn-success' onClick={onAddProducts}>{title}
                                    <span className='font-weight-bold'> ${formatNumber(getTotalProduct())}</span>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </> : <Row><Col className='text-center'><h3>No tienes Productos</h3></Col></Row>}

        </Container>
    )
}

const mapsState = (props) => {
    let { category, order } = props

    return {
        fetching_list_product: category.fetching_list_product,
        data_category: category.data_category,
        fetching_additional: order.fetching_additional
    }
}

export default connect(mapsState, { getListCategoryProducts })(FormCart);

const formatNumber = (number) => {
    return new Intl.NumberFormat('es-ES').format(number)
    // return parseFloat(number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}


const removeSession = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("storage")
    localStorage.removeItem("address")
    localStorage.removeItem("date")
    localStorage.removeItem("branch")
    localStorage.removeItem("filter")
    localStorage.removeItem("turn")
}

function errorLoginServer(error) {
    let err = {}

    if (error.response.status === 422) {
        err = { email: '', phone: '', name: '' }

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            if ('email' === key) {
                err.email = value[0]

                if (value[0] === 'The email has already been taken.') {
                    err.message = "Email ya Existe"
                }

            }
            if ('phone' === key) {
                err.phone = value[0]
                if (value[0] === 'The phone has already been taken.') {
                    err.message = "Número de Teléfono ya Existe"
                }


            }
            if ('name' === key) {
                err.name = value[0]
            }
            if ('password' === key) {
                err.password = value[0]
                if (value[0] === 'The password must be at least 4 characters.') {
                    err.message = "La contraseña debe tener por lo menos 4 Caracteres"
                }
            }
        }
    } else {
        err.message = "Datos Incorrectos"
    }

    return err;
}

export const validateInput = (form, rules) => {
    let err = {}

    for (const [key, value] of Object.entries(form)) {
        rules.map(val => {
            if (key === val.field) {
                if (value === '' || value === 0) {
                    err[key] = val.msg
                }
            }

        });
    }

    return Object.keys(err).length === 0 ? null : err
}

const getHeaders = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.access_token,
        "Content-Type": "application/json",
        // "Content-Type": 'multipart/form-data',
        "Accept": "application/json",
    }
}

const getHeadersImage = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.access_token,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",

    }
}


export {
    formatNumber, capitalize, removeSession, errorLoginServer, getHeaders, getHeadersImage
}
import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

const PaymentBar = ({ user, isPayment }) => {
    let { plan_business } = user

    const [data, setData] = useState({})
    const [payment, setPayment] = useState({})
    const [modal, setModal] = useState(false)
    const [days, setDays] = useState(0)
    const [message, setMessage] = useState(0)

    useEffect(() => {

        if (plan_business !== null) {

            if (plan_business !== undefined) {
                let { payment } = plan_business

                setData(plan_business)

                if (payment === null) {
                    setModal(true)
                    setMessage("No se ha reportado tu Pago.")
                } else {
                    setDays(payment.days)
                    setPayment(payment)
                    if (payment.days > 3) {

                        let word = payment.days === 1 ? 'día' : 'días'

                        setModal(true)
                        setMessage(`Hace (${payment.days} ${word}) No se ha reportado el Pago correspondiente.`)
                    }
                }
            }
        } else {
            setModal(true)
            setMessage(`No se ha reportado el Pago correspondiente.`)

        }
    }, [plan_business])

    return (
        <>{isPayment === false && <Container fluid className='fixed-top'>
            <Row className='bg-danger'>
                <Col className='text-white my-2 text-center'><h5>Tu Pago esta vencido hace {payment.days} {days === 1 ? 'día' : 'días'}, por favor Cancelar para poder Seguir usando la Plataforma</h5></Col>
            </Row>
        </Container>
        }
            <Modal show={modal} size={"lg"} centered>
                <Modal.Body>
                    <Container className='my-4'>
                        <Row>
                            <Col className='text-center text-red'><h2>Lo sentimos, lo Días permitidos se han agotado</h2></Col>
                        </Row>
                        <Row>
                            <Col className='text-center text-red'><h3>{message}</h3></Col>
                        </Row>
                    </Container>
                </Modal.Body></Modal>
        </>

    )
}

export default PaymentBar;
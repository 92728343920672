import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Navbar, Row } from 'react-bootstrap';
import { MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getListCotization } from '../../Redux/cotizationDuck'
import { formatNumber } from '../../utils';

const CotizationPage = ({ getListCotization, fetching_list, data }) => {

    const history = useHistory()
    const [list, setList] = useState([])

    useEffect(() => {
        getListCotization()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])


    const onGoBack = () => {
        history.goBack()
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Cotizaciones</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                {list.map((d, k) => <Row key={k} className="my-1">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>#</Col>
                                    <Col className='text-right'>{d.id}</Col>
                                </Row>
                                <Row>
                                    <Col>Fecha</Col>
                                    <Col className='text-right'>{d.created_at}</Col>
                                </Row>
                                <Row>
                                    <Col>Cliente</Col>
                                    <Col xs={7} className='text-right'>{d.client}</Col>
                                </Row>
                                <Row>
                                    <Col>Tipo de Vehículo</Col>
                                    <Col className='text-right'>{d.category}</Col>
                                </Row>
                                <Row>
                                    <Col>Placa</Col>
                                    <Col className='text-right'>{d.plate}</Col>
                                </Row>
                                <Row>
                                    <Col>TOTAL</Col>
                                    <Col className='text-right'>{formatNumber(d.total)}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>)}
            </Container>
            <div className='container-btn-add'>
                <Link to={"/stepone-cotization"}>
                    Crear Cotización
                </Link>
            </div>
        </>
    )
}

const mapsState = (props) => {
    let { cotization } = props
    return {
        fetching_list: cotization.fetching_list,
        data: cotization.data,
    }
}

export default connect(mapsState, { getListCotization })(CotizationPage);
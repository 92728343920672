import moment from 'moment';
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_active: 0,
    fetching_list: 0,
    fetching_finish: 0,
    fetching_create: 0,
    fetching_reopen: 0,
    fetching_review: 0,
    data: [],
    data_review: [],
    row: {},
    errors: {},
}

let LIST_TURN = "LIST_TURN";
let LIST_TURN_SUCCESS = "LIST_TURN_SUCCESS";

let TURN_ACTIVE = "TURN_ACTIVE";
let TURN_ACTIVE_SUCCESS = "TURN_ACTIVE_SUCCESS";
let TURN_FINISH = "TURN_FINISH";
let TURN_FINISH_SUCCESS = "TURN_FINISH_SUCCESS";
let TURN_REOPEN = "TURN_REOPEN";
let TURN_REOPEN_SUCCESS = "TURN_REOPEN_SUCCESS";
let CREATE_TURN = "CREATE_TURN";
let CREATE_TURN_SUCCESS = "CREATE_TURN_SUCCESS";
let CLOSE_SESSION_TURN = "CLOSE_SESSION_TURN";
let TURN_REVIEW = "TURN_REVIEW";
let TURN_REVIEW_SUCCESS = "TURN_REVIEW_SUCCESS";



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_TURN:
            return { ...state, fetching_list: 1, }
        case LIST_TURN_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_finish: 0, fetching_create: 0, fetching_active: 0 }
        case TURN_ACTIVE:
            return { ...state, fetching_active: 1 }
        case TURN_ACTIVE_SUCCESS:
            return { ...state, fetching_active: 2, row: action.payload }
        case TURN_FINISH:
            return { ...state, fetching_finish: 1 }
        case TURN_FINISH_SUCCESS:
            return { ...state, fetching_finish: 2 }
        case TURN_REOPEN:
            return { ...state, fetching_reopen: 1 }
        case TURN_REOPEN_SUCCESS:
            return { ...state, fetching_reopen: 2 }
        case CREATE_TURN:
            return { ...state, fetching_create: 1 }
        case CREATE_TURN_SUCCESS:
            return { ...state, fetching_create: 2 }
        case CLOSE_SESSION_TURN:
            return { ...state, fetching_active: 0 }
        case TURN_REVIEW:
            return { ...state, fetching_review: 1 }
        case TURN_REVIEW_SUCCESS:
            return { ...state, fetching_review: 2, data_review: action.payload }
        default:
            return state;
    }
}

export let closeSessionTurn = () => dispatch => {
    dispatch({
        type: CLOSE_SESSION_TURN,
    });
}

export let getListTurn = () => (dispatch, getState) => {
    dispatch({
        type: LIST_TURN,
    });

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let url = `${API_URL}/api/turn?branch_id=${branch.id}`;

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: LIST_TURN_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getActiveTurn = () => (dispatch, getState) => {
    dispatch({
        type: TURN_ACTIVE,
    });

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let url = `${API_URL}/api/turn-active?branch_id=${branch.id}`;

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: TURN_ACTIVE_SUCCESS,
                payload: resp
            });

            localStorage.setItem("turn", JSON.stringify(resp))
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onCreateTurn = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TURN,
    });

    let url = `${API_URL}/api/turn`;

    let branch = JSON.parse(localStorage.getItem("branch"))

    form.branch_id = branch.id
    form.date = moment().format("YYYY-MM-DD")

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            localStorage.setItem("turn", JSON.stringify(resp));
            dispatch({
                type: CREATE_TURN_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onFinishTurn = (id) => (dispatch, getState) => {
    dispatch({
        type: TURN_FINISH,
    });

    let url = `${API_URL}/api/turn/${id}`;

    let branch = JSON.parse(localStorage.getItem("branch"))

    let param = { "branch_id": branch.id };


    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(param),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            localStorage.setItem("turn", null);
            dispatch({
                type: TURN_FINISH_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onReOpenTurn = (id) => (dispatch, getState) => {
    dispatch({
        type: TURN_REOPEN,
    });

    let url = `${API_URL}/api/turn-reopen/${id}`;

    let branch = JSON.parse(localStorage.getItem("branch"))

    let cashier_id = null



    if (getState().turn.row !== null) {
        cashier_id = getState().turn.row.id
    }

    let param = { "branch_id": branch.id, cashier_id };

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(param),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: TURN_REOPEN_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewTurn = (id) => (dispatch, getState) => {
    dispatch({
        type: TURN_REVIEW,
    });

    let url = `${API_URL}/api/turn-review/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "GET",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: TURN_REVIEW_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
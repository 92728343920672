import moment from 'moment';
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_history: 0,
    data: [],
    data_history: [],
    errors: {}
}

let LIST_CART = "LIST_CART";
let LIST_CART_SUCCESS = "LIST_CART_SUCCESS";
let CREATE_CART = "CREATE_CART";
let CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS";
let RESTART_CART = "RESTART_CART";
let LIST_HISTORY = "LIST_HISTORY";
let LIST_HISTORY_SUCCESS = "LIST_HISTORY_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CART:
            return { ...state, fetching: 1, }
        case LIST_CART_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case CREATE_CART:
            return { ...state, fetching_create: 1 }
        case CREATE_CART_SUCCESS:
            return { ...state, fetching_create: 2 }
        case RESTART_CART:
            return { ...state, fetching_create: 0, }
        case LIST_HISTORY:
            return { ...state, fetching_history: 1 }
        case LIST_HISTORY_SUCCESS:
            return { ...state, fetching_history: 2, data_history: action.payload }
        default:
            return state;
    }
}

export let restartCart = () => (dispatch, getState) => {
    dispatch({
        type: RESTART_CART,
    });
}

export let storeCar = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CART,
    });

    let url = `${API_URL}/api/cart`;

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"));
        form.branch_id = branch.id

        fetch(url, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                alert("error")
            } else {
                localStorage.removeItem("products")
                dispatch({
                    type: CREATE_CART_SUCCESS,
                    payload: resp
                });

            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getListHistory = () => (dispatch, getState) => {
    dispatch({
        type: LIST_HISTORY,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cart?branch_id=${branch.id}&cashier_id=${turn.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: LIST_HISTORY_SUCCESS,
                    payload: resp.results
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getListInventory = () => (dispatch, getState) => {
    dispatch({
        type: LIST_HISTORY,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))
    let date_init = moment().format("YYYY-MM-01")
    let date_end = moment().format("YYYY-MM-DD")


    let url = `${API_URL}/api/inventory-available?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: LIST_HISTORY_SUCCESS,
                    payload: resp.results
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
}
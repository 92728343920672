import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';

const ModalDeleteOrder = ({ show, onHide, order, onSubmit }) => {

    const [reason, setReason] = useState('')
    const [error, setError] = useState('')

    const onSubmitPre = () => {
        if (reason === '') {
            setError("Agrega una razón")
            return;
        }

        onSubmit(order, reason)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>¿Deseas Eliminar la Orden {`${order.id}`}?</Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Col>
                            <div className='form-group'>
                                <label>Agrega la Razón</label>
                                <textarea className='form-control' placeholder='Razón' name='reason' value={reason} onChange={(e) => setReason(e.target.value)} />
                            </div>
                            <Form.Text className="text-danger">
                                {error}
                            </Form.Text>
                        </Col>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={onSubmitPre}>Eliminar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>

    )
}

export default ModalDeleteOrder;
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ItemOrder from './item.order';
import { connect } from 'react-redux';


const ListActivePage = ({ list }) => {
    return (
        <Container fluid>
            <Row className='mt-3'>

                {(list.length > 0) ? list.map((ord, k) => <ItemOrder item={ord} key={k} />)
                    : <Col className='text-center'><h3>No Tienes Ordenes Activas</h3></Col>}
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { order } = props
    return {

    }
}

export default connect(mapsState, {})(ListActivePage);
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import 'react-calendar/dist/Calendar.css';
import { toogleDrawer } from '../Redux/alertDuck'
import { logOutAction } from '../Redux/userDuck'
import logo from '../Images/logo.png'
import { Link, useHistory } from 'react-router-dom';
import { MdClose } from 'react-icons/md';


const DrawerPage = ({ drawer, toogleDrawer, user, logOutAction, loggedIn, fetching }) => {

    const history = useHistory();
    const [customer, setCustomer] = useState({ name: '', email: '', phone: '' });

    useEffect(() => {

        if (fetching === 2) {
            setCustomer(user)
        }
    }, [fetching])


    const logOutActionPre = () => {
        toogleDrawer(false)
        logOutAction()
        // history.pushReplace("/login")
    }

    const goTo = (link) => {
        toogleDrawer(false)
        history.push(link)
    }

    return (
        <Container fluid className={`bg-drawer ${!drawer && 'd-none'}`}>
            {/* <Container fluid className={`bg-drawer ${!drawer && 'd-none'}`}> */}
            <Row className='h-100'>
                <Col lg={3} className='bg-drawer-content'>
                    <Row className='d-flex justify-content-center mt-3'>
                        <Col lg={3} xs={3}>
                            <img src={logo} className="img-fluid" onClick={() => window.location.href = "/"} alt='LOGO' />
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Col>{customer.name}</Col>
                            </Row>
                            <Row>
                                <Col>{customer.email}</Col>
                            </Row>
                            <Row>
                                <Col>{customer.phone}</Col>
                            </Row>
                        </Col>
                        <Col xs={1} className='pr-0'>
                            <MdClose className='cursor-pointer' size={30} onClick={() => toogleDrawer(false)} />
                        </Col>
                    </Row>
                    {/* <Row className='my-5 py-5'>
                        <Col className=''>
                            Sucursales pendiente
                        </Col>
                    </Row> */}
                    {/* <Row className='my-4 ml-4 mr-1 '>
                        <Col className='border-bottom'>
                            <a href='#' onClick={() => goTo("/perfil")}>Pefil</a>
                        </Col>
                    </Row> */}

                    <Row className='ml-4 my-4'>
                        <Col className='border-bottom'>
                            <Link to="/indicadores" onClick={() => toogleDrawer(false)}>Indicadores</Link>
                        </Col>
                    </Row>
                    <Row className='ml-4 my-4 mr-1'>
                        <Col className='border-bottom'>
                            <Link to="/clients" onClick={() => toogleDrawer(false)}>Clientes</Link>
                        </Col>
                    </Row>
                    <Row className='ml-4 my-4 mr-1'>
                        <Col className='border-bottom'>
                            <Link to="/cotizaciones" onClick={() => toogleDrawer(false)}>Cotizaciones</Link>
                        </Col>
                    </Row>
                    <Row className='ml-4 my-4 mr-1'>
                        <Col className='border-bottom'>
                            <Link to="/lubricentro" onClick={() => toogleDrawer(false)}>Lubricentro</Link>
                        </Col>
                    </Row>
                    <Row className='ml-4 mr-1'>
                        <Col className='border-bottom'>
                            {/* <a href='#' onClick={() => goTo("/turns")}>Turnos</a> */}
                            <Link to="/turns" onClick={() => toogleDrawer(false)}>Turnos</Link>
                        </Col>
                    </Row>

                    <Row className='mt-5'>
                        <Col className=''>
                            <a href='#' onClick={logOutActionPre}>Cerrar Sesion</a>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </Container>
    )
}

const mapsState = (props) => {
    let { alert, user } = props

    return {
        drawer: alert.drawer,
        user: user.user,
        fetching: user.fetching,
        loggedIn: user.loggedIn
    }
}

export default connect(mapsState, { toogleDrawer, logOutAction })(DrawerPage);
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';

const ModalPayment = ({ show, listMethods, onSuccess }) => {
    const [value, setValue] = useState('');

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Agregar Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Método de Pago</Form.Label>
                            <select className='form-control' value={value} onChange={(e) => setValue(e.target.value)} >
                                <option value={0}>Seleccione una Opcion</option>
                                {listMethods.map(ope => <option key={ope.id} value={ope.id}>{ope.description}</option>)}
                            </select>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-danger' onClick={() => onSuccess(value)}>Pagar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPayment;
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Navbar, Row } from 'react-bootstrap';
import { MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getActiveTurn, getListTurn, onFinishTurn, onReOpenTurn } from '../../Redux/turnDuck'

const TurnPage = ({ getListTurn, fetching_list, data, fetching_finish, fetching_reopen, onFinishTurn, onReOpenTurn }) => {

    const [list, setList] = useState([])
    const history = useHistory();

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_finish === 2) {
            getListTurn()
        }

        if (fetching_reopen === 2) {
            getActiveTurn()
            getListTurn()
        }
    }, [fetching_finish, fetching_reopen])

    useEffect(() => {
        getListTurn();
    }, [])

    const onGoBack = () => {
        history.goBack()
    }

    const onReOpenTurnForm = (turn) => {

        if (window.confirm(`¿Realmente deseas Abrir el Turno ${turn.date_start}?`)) {
            onReOpenTurn(turn.id);
        }
    }
    const onFinishTurnForm = (turn) => {

        if (window.confirm(`¿Realmente deseas Finalizar el Turno ${turn.date_start}?`)) {
            onFinishTurn(turn.id)
        }
    }


    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Turnos</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container fluid>
                {list.length === 0 ? <Row className='mt-5'>
                    <Col className='text-center'>No tienes Turnos</Col>
                </Row> : list.map((d, k) => <Row className='my-1 d-flex justify-content-center' key={k}>
                    <Col lg={4}>
                        <Card>
                            <Card.Body className='p-1'>
                                <Row onClick={() => history.push(`/turns/${d.id}`)}>
                                    <Col>ID</Col>
                                    <Col className='text-right'>{d.id}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-size-12'>ESTADO</Col>
                                    <Col className='text-right font-size-12'>{d.status_text}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-size-12'>USUARIO</Col>
                                    <Col className='text-right font-size-12'>{d.user.name}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-size-12'>FECHA REGISTRO</Col>
                                    <Col className='text-right font-size-12'>{d.date_start}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-size-12'>FECHA CIERRE</Col>
                                    <Col className='text-right font-size-12'>{d.date_end}</Col>
                                </Row>
                                <Row>
                                    <Col className='font-size-12'>MONTO APERTURA CAJA</Col>
                                    <Col className='text-right font-size-12'>{d.open_cash_formated}</Col>
                                </Row>
                                {d.percent > 0 && <Row>
                                    <Col className='font-size-12'>CAMBIO PORCENTAJE</Col>
                                    <Col className='text-right font-size-12 font-weight-bold'>{d.percent}%</Col>
                                </Row>}
                                <Row>
                                    <Col className=''>
                                        {d.status_id === 1 ?
                                            <button className='btn btn-sm btn-warning form-control' onClick={() => onFinishTurnForm(d)}>Finalizar Turno</button> :
                                            <button className='btn btn-sm btn-secondary form-control' onClick={() => onReOpenTurnForm(d)}>Reabrir Turno</button>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>)}

            </Container>
            <div className='container-btn-add'>
                <Link to={"/open-turn"}>
                    Ingresar a Turno
                </Link>
            </div>
        </>
    )
}

const mapsState = (props) => {
    let { turn } = props

    return {
        fetching_list: turn.fetching_list,
        fetching_reopen: turn.fetching_reopen,
        fetching_finish: turn.fetching_finish,
        data: turn.data,
    }
}

export default connect(mapsState, { getListTurn, onFinishTurn, onReOpenTurn })(TurnPage);
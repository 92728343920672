import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onLogin } from '../../Redux/userDuck'
import { getActiveTurn } from '../../Redux/turnDuck'
import Logo from '../../Images/logo.png'

const LoginPage = ({ onLogin, fetching, getActiveTurn, fetching_active }) => {
    const [form, setForm] = useState({ email: '', password: '' });
    const history = useHistory();

    useEffect(() => {
        if (fetching === 2) {
            getActiveTurn()
        }
    }, [fetching])
    useEffect(() => {
        if (fetching_active === 2) {
            localStorage.setItem("menu", "nuevas")
            history.push("/")
        }
    }, [fetching_active])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        onLogin(form)
    }

    return (
        <Container>
            <Row className='d-flex justify-content-center mt-5'>
                <Col lg={4} md={8}>
                    <Card>
                        <Card.Body>

                            <Row className='d-flex justify-content-center'>
                                <Col lg={8} md={4} xs={5}>
                                    <img src={Logo} className="img-fluid" />
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" name='email' onChange={updateInput} value={form.email} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Clave</Form.Label>
                                        <Form.Control type="password" placeholder="Ingresa la Clave" name='password' onChange={updateInput} value={form.password} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <button type='button' className='btn btn-sm btn-onix' onClick={onSubmit}>Iniciar</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>



        </Container>
    )
}

const mapsState = (props) => {
    let { user, turn } = props

    return {
        fetching: user.fetching,
        fetching_active: turn.fetching_active
    }
}

export default connect(mapsState, { onLogin, getActiveTurn })(LoginPage);
import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const RoutesPrivate = ({ loggedIn, component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
        loggedIn === true ? (
            <Component {...props} />
        ) : (
            <Redirect to={"/login"} />
        )
    )} />
}



const mapsState = (props) => {
    let { user } = props
    return {
        loggedIn: user.loggedIn
    }
}


export default connect(mapsState)(RoutesPrivate)
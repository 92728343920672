import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Navbar, Row } from 'react-bootstrap';
import { MdEast, MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { getHeaders } from '../../utils';

import { saveCotizationMemory } from '../../Redux/cotizationDuck'
import ItemCategory from '../Home/CreateOrder/item.category';
import { getListCategory } from '../../Redux/categoryDuck'


const API_URL = process.env.REACT_APP_API_URL


const StepOneCotization = ({ errors, getListCategory, fetching_list_category, saveCotizationMemory, data_category, fetching_save_form }) => {

    const history = useHistory()
    const [showInput, setShowInput] = useState(false)
    const [client, setClient] = useState('');
    const [plateTypped, setPlateTypped] = useState('')
    const [plate, setPlate] = useState('');
    const [listSearch, setListSearch] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({})
    const [showInputClient, setShowInputClient] = useState(false)
    const [clientTypped, setClientTypped] = useState('')
    const [listSearchClient, setListSearchClient] = useState([]);
    const [listCategory, setListCategory] = useState([]);

    useEffect(() => {
        getListCategory()
        // getOrderMemory()
    }, [])
    useEffect(() => {
        if (fetching_list_category === 2) {
            setListCategory(data_category)
        }

    }, [fetching_list_category])

    useEffect(() => {

        if (fetching_save_form === 2) {
            history.push("/steptwo-cotization");
        }
    }, [fetching_save_form])


    const onGoBack = () => {
        history.goBack()
    }

    const loadOptions = async (inputValue) => {
        setPlateTypped(inputValue)
        let url = `${API_URL}/api/plate/${inputValue}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search.results)
        return _search.results;
    }

    const updateFieldPlate = (e) => {
        if (e.target.value.length === 0) {
            setShowInput(false)
        } else {
            setPlateTypped(e.target.value)
        }
    }

    const addNewPlate = () => {
        setShowInput(false)
        setPlate({ label: plateTypped, id: 0 })
        setPlateTypped('')
        // updateFieldOrder({ field: "plate", value: plateTypped })

    }

    const loadOptionsClient = async (inputValue) => {
        setClientTypped(inputValue)
        let url = `${API_URL}/api/clients?search=${inputValue}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearchClient(_search.results)

        return _search.results;
    }

    const addNewClient = () => {
        setShowInputClient(true)
    }

    const onSelectedCategory = (item) => {
        setSelectedCategory(item)

    }
    const onNextPage = () => {

        if (client === '') {
            alert("Cliente Obligatorio")
            return;
        }

        if (selectedCategory === '') {
            alert("Categoria Obligatoria")
            return;
        }


        let _form = { plate, client, category: selectedCategory }

        saveCotizationMemory(_form, 2)
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Paso 1</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row>
                    <Col>
                        {showInput ?
                            <><Form.Group>
                                <label>Placa</label>
                                <input className='form-control' value={plateTypped} onChange={updateFieldPlate} />
                            </Form.Group>
                            </>
                            : <Form.Group className="mb-3">
                                <Form.Label>Placa</Form.Label>
                                <AsyncSelect
                                    isClearable
                                    value={plate}
                                    placeholder="Busca el Placa"
                                    loadOptions={loadOptions}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            let { client, category } = e
                                            setPlate(e)
                                            setClient({ label: `${client.name} (${client.phone})`, value: client.id })
                                            setSelectedCategory(category)
                                        } else {
                                            setPlate("")
                                            setClient("")
                                            setSelectedCategory("")
                                        }
                                    }}
                                />
                                <Form.Text className="text-danger">
                                    {errors.plate}
                                </Form.Text>
                            </Form.Group>}

                    </Col>
                    {(listSearch.length === 0 && plateTypped.length > 0) && <Col lg={3} md={3} xs={3} className="d-flex align-items-center">
                        <button className='btn btn-info btn-sm' onClick={addNewPlate}>Agregar</button>
                    </Col>}
                </Row>
                <Row>
                    <Col>
                        {showInputClient ?
                            <input className='form-control' value={clientTypped} onChange={updateFieldPlate} />
                            : <Form.Group className="mb-3">
                                <Form.Label>Buscar o Registrar Cliente</Form.Label>
                                <AsyncSelect
                                    isClearable
                                    value={client}
                                    placeholder="Busca cliente"
                                    loadOptions={loadOptionsClient}
                                    onChange={(e) => {
                                        if (e !== null) {
                                            setClient(e)
                                            // updateFieldOrder({ field: "client_id", value: e })
                                        }

                                    }}
                                />
                                <Form.Text className="text-danger">
                                    {errors.client_id}
                                </Form.Text>
                            </Form.Group>}
                    </Col>
                    {(listSearchClient.length === 0 && clientTypped.length > 0) && <Col lg={3} md={3} xs={4} className="d-flex align-items-center">
                        <button className='btn btn-info btn-sm' onClick={addNewClient}>Crear Cliente</button>
                    </Col>}
                </Row>

                <Row>
                    <Col><b>Selecciona el tipo de Vehículo</b></Col>
                </Row>
                <Row>
                    {listCategory.map((cat, k) =>
                        <ItemCategory key={k} item={cat} selectedCategory={selectedCategory}
                            onSelectCategory={onSelectedCategory} />)}
                </Row>
                <Row>
                    <Col className='text-danger'>{errors.category_id}</Col>
                </Row>
                <Navbar bg="dark" expand="lg" fixed="bottom">
                    <Container fluid>
                        <Row className='w-100'>
                            <Col className='text-center text-white' lg={2} md={5}>
                                <a onClick={onNextPage}>SIGUIENTE <MdEast size={25} /></a>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
            </Container>
        </>
    )
}


const mapsState = (props) => {
    let { cotization, category } = props
    return {
        errors: cotization.errors,
        fetching_list_category: category.fetching_list,
        data_category: category.data,
        fetching_save_form: cotization.fetching_save_form,

    }
}
export default connect(mapsState, { getListCategory, saveCotizationMemory })(StepOneCotization);
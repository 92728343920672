import React, { useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import ButtonAddRemove from '../../Components/button_add_remove';
const API_URL = process.env.REACT_APP_API_URL


const ItemProduct = ({ item }) => {

    const [quantity, setQuantity] = useState(0);

    let image = API_URL + "/" + item.image


    const onAddItem = () => {
        let _quantity = quantity;
        _quantity++;
        setQuantity(_quantity)
        item.quantity = _quantity
    }

    const onRemoveItem = () => {
        let _quantity = quantity;
        _quantity--;
        setQuantity(_quantity)
        item.quantity = _quantity
    }

    return (
        <Row className='my-1'>
            <Col>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col lg={2} xs={2}>
                                <img src={image} className="img-fluid" />
                            </Col>
                            <Col>
                                <Row>
                                    <Col><b>{item.name} ({item.price_formated})</b></Col>
                                </Row>
                                <Row>
                                    <Col>{item.description}</Col>
                                </Row>
                            </Col>
                            <Col lg={4} xs={5}>
                                <Row className='mx-0'>
                                    <Col lg={4}>
                                        <ButtonAddRemove quantity={quantity} onAdd={onAddItem} onRemove={onRemoveItem} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ItemProduct;
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';

const ModalEditParameter = ({ show, parameter, onClick, onHide }) => {

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(parameter.value)
    }, [parameter])

    const onClickBefore = () => {
        onClick(parameter, value)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{parameter.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>{parameter.label}</Form.Label>
                            <Form.Control type={parameter.typeInput} placeholder={parameter.label} value={value} onChange={(e) => setValue(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onClickBefore}>Editar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalEditParameter;
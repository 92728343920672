import React, { useEffect, useState } from 'react'
import { Col, Container, Form, ListGroup, Navbar, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatNumber } from '../../../utils';
import ItemServiceSelected from './item.service.selected';
import { updateFieldOrder, removeItemService, getOrderMemory, saveOrderMemory } from '../../../Redux/orderDuck'
import { getListServices, restartStatusService } from '../../../Redux/servicesDuck'
import { getListOperators } from '../../../Redux/userDuck'
import ModalEditService from '../EditOrder/modal.edit.service';
import ModalServices from '../modal.services';
import { MdClose, MdEast, MdWest } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';


const StepTwoPage = ({ onOpenProducts, errors, data_services, removeItemService, getListOperators, fetching_operator, data_operators, getListServices,
    getOrderMemory, fetching_get_form, form_order, saveOrderMemory, fetching_save_form, restartStatusService }) => {

    const [modal, setModal] = useState(false)
    const [modalEditService, setModalEditService] = useState(false)
    const [form, setForm] = useState({ operator_id: 0 })
    const [formEdit, setFormEdit] = useState({})
    const [listServices, setListServices] = useState([])
    const [listOperatorSelected, setListOperatorSelected] = useState([])
    const [listOperators, setListOperators] = useState([])
    const [listServicesSelected, setListServicesSelected] = useState([])
    const [listProductSelected, setListProductSelected] = useState([])
    const history = useHistory()

    useEffect(() => {
        getListOperators()
        getOrderMemory()

    }, [])

    useEffect(() => {
        if (fetching_get_form === 2) {
            if (form_order.category !== null) {
                getListServices(form_order.category);
            }
            if (form_order.operators !== null) {
                setListOperatorSelected(form_order.operators);
            }
            if (form_order.services !== null) {
                setListServicesSelected(form_order.services);
            }
        }
    }, [fetching_get_form])

    useEffect(() => {
        if (fetching_save_form === 3) {
            history.push("/step-three");

        }
    }, [fetching_save_form])

    useEffect(() => {
        if (fetching_operator === 2) {
            setListOperators(data_operators)
        }
    }, [fetching_operator])


    const onDeleteItem = (item) => {
        if (window.confirm("¿Realmente deseas Quitar el Servicio?")) {
            removeItemService(item)
        }
    }

    const onAddOperator = (e) => {
        let selected = listOperators.filter(d => parseInt(d.id) === parseInt(e.target.value))[0]

        setListOperatorSelected([
            ...listOperatorSelected,
            selected
        ])
    }

    const onEditItemService = (item) => {
        setModalEditService(true)
        setFormEdit(item)
    }

    const onClickEdit = (form) => {

        let _list = listServicesSelected.map(d => {
            if (d.id == form.id) {
                d.old_price = form.old_price;
                d.price = form.price;
                d.discount = form.discount;
                d.comment = form.comment;
            }
            return d;
        })

        setListServices(_list)
        setModalEditService(false)
    }

    const onDeleteService = (form) => {
        let _list = listServicesSelected.filter(d => d.id !== form.id)
        setListServices(_list)
        setModalEditService(false)
    }

    const onSelectedService = (item) => {
        item.comment = ''
        setListServicesSelected([
            ...listServicesSelected,
            item
        ]);
        setModal(false)
        restartStatusService()
    }

    const onDeleteOperator = (item) => {
        let _list = listOperatorSelected.filter(d => d.id !== item.id)

        setListOperatorSelected(_list)

    }

    const onGoBack = () => {
        history.goBack()
    }

    const onNextPage = () => {

        if (listOperatorSelected.length === 0) {
            alert("Selecciona un operador")
            return
        }
        if (listServicesSelected.length === 0) {
            alert("Selecciona por lo menos 1 Servicio")
            return
        }
        let _form = { operators: listOperatorSelected, services: listServicesSelected }
        saveOrderMemory(_form, 3)
    }

    const openModalService = () => {
        let category = JSON.parse(localStorage.category);
        getListServices(category);
        setModal(true)
    }


    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={9}><b>PASO 2</b></Col>
                        <Col></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6} md={5}>
                        <Form.Group className="mb-3">
                            <Form.Label>Operador</Form.Label>
                            <select className='form-control' name='operator_id' value={form.operator_id} onChange={onAddOperator}>
                                <option value={0}>Seleccione</option>
                                {listOperators.map((ope, k) => <option key={ope.id} value={ope.id}>{ope.name}</option>)}
                            </select>
                            <Form.Text className="text-danger">
                                {errors.operator_id}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6} md={6}>
                        <ListGroup>
                            {listOperatorSelected.map((d, k) => <ListGroup.Item key={k}>
                                <Row>
                                    <Col>{d.name}</Col>
                                    <Col xs={3} className="d-flex justify-content-end"><MdClose onClick={() => onDeleteOperator(d)} /></Col>
                                </Row>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col className='text-center'>
                        <button className='btn btn-sm btn-info' onClick={openModalService}>Agregar Servicio</button>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={8}>
                        <Table className='table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Servicio</th>
                                    <th>Subtotal</th>
                                    <th>Descuento</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listServicesSelected.map((serv, k) => <ItemServiceSelected key={k} item={serv} onEditItem={onEditItemService} />)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className='font-size-11'><b>TOTAL</b></td>
                                    <td className='font-size-11' align='right'>
                                        {formatNumber(listServicesSelected.reduce((a, b) => a = a + parseFloat(b.price) - parseFloat(b.discount || 0), 0))}
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                {/* <Row className='my-4'>
                    <Col className='text-center'>
                        <Link className='btn btn-sm btn-info' to={"/detailing"}>Agregar Detalles</Link>
                    </Col>
                </Row> */}
                <ModalServices show={modal} listServices={data_services} onHide={() => setModal(false)} onSelectedService={onSelectedService} />
                <ModalEditService show={modalEditService} formEdit={formEdit} onClickEdit={onClickEdit} onDeleteService={onDeleteService} onHide={() => setModalEditService(false)} />
            </Container>
            <Navbar bg="dark" expand="lg" fixed="bottom">
                <Container fluid>
                    <Row className='w-100'>
                        <Col className='text-center text-white' lg={12} md={12}>
                            <a onClick={onNextPage}>SIGUIENTE <MdEast size={25} /></a>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </>
    )
}

const mapsState = (props) => {
    let { order, services, user } = props
    return {
        form: order.form,
        errors: order.errors,
        data_services: services.data,
        fetching_operator: user.fetching_operator,
        data_operators: user.data_operators,
        fetching_get_form: order.fetching_get_form,
        form_order: order.form,
        fetching_save_form: order.fetching_save_form,

    }
}

export default connect(mapsState, {
    updateFieldOrder, removeItemService, getListOperators, getOrderMemory, getListServices,
    saveOrderMemory, restartStatusService
})(StepTwoPage);
import React from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap';
import ItemProduct from './item.product';

const ModalProducts = ({ show, onHide, listProducts, onAddProductSelected }) => {

    const onAddProducts = () => {
        onAddProductSelected(listProducts.filter(p => p.quantity > 0))
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Productos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {listProducts.map((ser, k) => <ItemProduct key={k} item={ser} />)}
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-info btn-sm' onClick={onAddProducts}>Agregar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalProducts;
import React, { useEffect, useRef, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { getListInventory } from '../../Redux/cartDuck'
import { connect } from 'react-redux';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";
import { formatNumber } from '../../utils';

const CartInventoryPage = ({ getListInventory, fetching_history, data_history }) => {

    const [list, setList] = useState([])
    const tableRef = useRef(null);


    useEffect(() => {
        if (fetching_history === 2) {
            setList(data_history)
        }
    }, [fetching_history])

    useEffect(() => {
        getListInventory()
    }, [])

    return (
        <SimpleTemplate title={"INVENTARIO"}>
            <Container fluid>
                <Row className='d-flex justify-content-center'>
                    <Col lg={8}>
                        <DownloadTableExcel
                            filename="inventario"
                            sheet="inventario"
                            currentTableRef={tableRef.current}>
                            <RiFileExcel2Fill color='green' size={30} className='cursor-pointer' />
                            {/* <button className='btn btn-sm btn-info'> Export excel </button> */}
                        </DownloadTableExcel>
                    </Col>
                </Row>
                <Row className='mt-3 d-flex justify-content-center'>
                    <Col lg={8}>
                        <Table className='table-sm table-bordered' ref={tableRef}>
                            <thead className='thead-dark'>
                                <tr>
                                    <th></th>
                                    <th>PRODUCTO</th>
                                    <th className='text-center'>INICIAL</th>
                                    <th className='text-center'>COMPRAS</th>
                                    <th className='text-center'>VENTAS</th>
                                    <th className='text-center'>BAJAS</th>
                                    <th className='text-center'>TOTAL DISPONIBLE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((d, k) => <tr key={k}>
                                    <td>{k + 1}</td>
                                    <td>{d.product}</td>
                                    <td align='right'>{d.initial_inv}</td>
                                    <td align='right'>{d.purchases}</td>
                                    <td align='right'>{d.sales}</td>
                                    <td align='right'>{d.lowdown}</td>
                                    <td align='right'>{formatNumber(d.total)}</td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={2}>TOTAL</th>
                                    <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.initial_inv || 0), 0))}</th>
                                    <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.purchases || 0), 0))}</th>
                                    <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.sales || 0), 0))}</th>
                                    <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.lowdown || 0), 0))}</th>
                                    <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total || 0), 0))}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>

                </Row>
            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { cart } = props
    return {
        fetching_history: cart.fetching_history,
        data_history: cart.data_history,
    }
}

export default connect(mapsState, { getListInventory })(CartInventoryPage);
import { BrowserRouter as Router } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Routes from './Routes';
import LoaderPage from './template/loader';
import CalendarPage from './template/calendarPage';
import DrawerPage from './template/DrawerPage';
import { useEffect } from 'react';
import { getLisBranch } from './Redux/branchDuck'
import { getActiveTurn } from './Redux/turnDuck'

import { toogleDrawer } from './Redux/alertDuck'


const App = ({ fetching_change, getLisBranch, getActiveTurn, toogleDrawer }) => {


  useEffect(() => {
    getLisBranch()
    getActiveTurn()
    toogleDrawer(false);
  }, []);

  return (
    <Router>
      <Routes />
      <LoaderPage />
      <CalendarPage />
      <DrawerPage />
    </Router>
  );
}

const mapsState = (props) => {
  let { order, turn } = props

  return {
    fetching_change: order.fetching_change
  }
}

export default connect(mapsState, { getLisBranch, getActiveTurn, toogleDrawer })(App);

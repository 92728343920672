import React from 'react'
import { Col, Row } from 'react-bootstrap';
import MenuTemplate from '../../template/menu.template';

const ParkingPage = () => {
    return (
        <MenuTemplate>
            <Row>
                <Col>Parqueaderos Proximamente</Col>
            </Row>
        </MenuTemplate>
    )
}

export default ParkingPage;
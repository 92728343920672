import React, { useEffect, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { getListClients } from '../../Redux/clientDuck'
import { getOrderMemory, saveClientMemory } from '../../Redux/orderDuck'

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SearchClientPage = ({ getListClients, fetching_list, data, saveClientMemory, fetching_add_client }) => {
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [client, setClient] = useState({ name: '' })
    const history = useHistory()

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            setIsLoading(false)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_add_client === 2) {
            history.push("/step-one");
        }
    }, [fetching_add_client])


    const updateField = (e) => {
        setIsLoading(true)
        setClient({
            ...client,
            "name": (e.target.value).toUpperCase()
        })
        if (e.target.value !== '') {
            getListClients(e.target.value)
        } else {
            setList([])
            setIsLoading(false)
        }
    }

    const onSelectedClient = (row) => {
        saveClientMemory(row);
    }

    const onCreateCLient = () => {
        history.push("/clients-create");
    }

    return (
        <SimpleTemplate title={"Buscar Cliente"}>
            <Container fluid className='mt-2'>
                <Row className='d-flex justify-content-center'>
                    <Col lg={4}>
                        <Row>
                            <Col>
                                <input className='form-control' placeholder='Escribe Nombre ó Celular' value={client.name} onChange={updateField} />
                            </Col>
                        </Row>

                        {isLoading && <Row className='my-4'>
                            <Col className='text-center'>
                                <Spinner animation="border" /></Col>
                        </Row>}
                        {list.length === 0 ? <Row className='mt-4'>
                            <Col className='text-center'>
                                {client.name !== '' && <Row>
                                    <Col>
                                        <Row>
                                            <Col>No se encontro el Cliente( <b>{client.name}</b>)</Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <button className='btn btn-sm btn-info' onClick={onCreateCLient}>Crear Cliente Nuevo</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>}
                            </Col>
                        </Row> : list.map((d, k) => <Row key={k} className='mt-1'>
                            <Col>
                                <Card className='cursor-pointer' onClick={() => onSelectedClient(d)}>
                                    <Card.Body>
                                        <Row>
                                            <Col>Nombre</Col>
                                            <Col className='font-weight-bold text-right'>{d.name}</Col>
                                        </Row>
                                        <Row>
                                            <Col className='text-muted'>Celular</Col>
                                            <Col className='text-muted text-right'>{d.phone}</Col>
                                        </Row>
                                        <Row className='d-flex justify-content-center'>
                                            <Col lg={8}>
                                                <Row>
                                                    <Col>
                                                        <button className='btn btn-sm btn-warning'>Vehículos Asociados {d.total}</button>
                                                    </Col>
                                                    <Col className='text-center'>
                                                        <button className='btn btn-sm btn-info'>Editar</button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>)}
                    </Col>
                </Row>
            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { client, order } = props

    return {
        fetching_list: client.fetching_list,
        data: client.data,
        fetching_add_client: order.fetching_add_client,
    }
}

export default connect(mapsState, { getListClients, saveClientMemory })(SearchClientPage);
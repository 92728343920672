import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../../Components/InputField';

const ModalPaymentProduct = ({ show, methodPayment, onHide, total, onSuccess }) => {

    const [form, setForm] = useState({ total: 0, tip: 0, payment: {} })
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setForm({
            ...form,
            ["amount"]: total,
        })

    }, [total])

    useEffect(() => {
        if (Object.keys(methodPayment).length > 0) {
            setForm({
                ...form,
                ["payment"]: methodPayment
            })
        }
    }, [methodPayment])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onAddPayment = () => {
        let _form = form
        if (form.amount > 0) {
            onSuccess(_form)
        } else {
            alert("El monto debe ser Mayor a Cero")
        }
    }


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Pagar con {methodPayment.description}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Col>
                            <div className="form-group">
                                <label>Agrega Monto</label>
                                <InputField
                                    type={"number"}
                                    placeholder="Monto"
                                    value={form.amount || ''}
                                    name="amount"
                                    onChange={updateInput}
                                    error={errors.total}
                                />
                            </div>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Col>
                            <div className="form-group">
                                <label>¿Tiene Propina?</label>
                                <InputField
                                    type={"number"}
                                    placeholder="Valor Propina"
                                    value={form.tip || ''}
                                    name="tip"
                                    onChange={updateInput}
                                    error={errors.tip}
                                />
                            </div>
                        </Col>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-danger btn-sm' onClick={onHide}>Cerrar</button></Col>
                    <Col><button className='btn btn-success btn-sm' onClick={onAddPayment}>Agregar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPaymentProduct;
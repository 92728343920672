import axios from 'axios'
import { getHeaders, getHeadersImage } from '../utils';
import { logOutAction } from './userDuck';
import moment from 'moment'
import { restartPayment } from './paymentDuck';

const API_URL = process.env.REACT_APP_API_URL

const initStateForm = {
    plate: '', recurrent: '', client_id: '', client: '', category_id: '', category: '', operator_id: '', services: [], products: [], observations: '', photos: [], discount: 0,
    recurrent: null
}

let initialState = {
    fetching: 0,
    fetching_create: 0,
    fetching_list: 0,
    fetching_by_id: 0,
    fetching_step: 0,
    fetching_finish: 0,
    fetching_payment: 0,
    fetching_advancement: 0,
    fetching_additional: 0,
    fetching_cash: 0,
    fetching_cash_create: 0,
    fetching_change: 0,
    fetching_detail: 0,
    fetching_save_form: 0,
    fetching_get_form: 0,
    fetching_plate: 0,
    fetching_cancel: 0,
    fetching_delete: 0,
    fetching_delete_plate: 0,
    fetching_delete_client: 0,
    fetching_add_client: 0,
    fetching_delete_payment: 0,
    fetching_reverse: 0,
    data: [],
    current: 0,
    row: {},
    cash: {},
    errors: {},
    form: initStateForm,
    currentDate: moment().format("YYYY-MM-DD")
}

let LIST_ORDER = "LIST_ORDER";
let LIST_ORDER_SUCCESS = "LIST_ORDER_SUCCESS";
let ORDER_BY_ID = "ORDER_BY_ID";
let ORDER_BY_ID_SUCCESS = "ORDER_BY_ID_SUCCESS";
let STEP_VALIDATE = "STEP_VALIDATE";
let STEP_VALIDATE_SUCCESS = "STEP_VALIDATE_SUCCESS";
let STEP_VALIDATE_ERROR = "STEP_VALIDATE_ERROR";
let UPDATE_FIELD = "UPDATE_FIELD";
let UPDATE_FIELD_SUCCESS = "UPDATE_FIELD_SUCCESS";
let BACk_STEP = "BACk_STEP";
let CREATE_ORDER = "CREATE_ORDER";
let CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
let FINISH_ORDER = "FINISH_ORDER";
let FINISH_ORDER_SUCCESS = "FINISH_ORDER_SUCCESS";
let ADD_PAYMENT = "ADD_PAYMENT";
let ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
let ADDITIONAL_PRODUCTS = "ADDITIONAL_PRODUCTS"
let ADDITIONAL_PRODUCTS_SUCCESS = "ADDITIONAL_PRODUCTS_SUCCESS"
let OPEN_CASH = "OPEN_CASH"
let OPEN_CASH_SUCCESS = "OPEN_CASH_SUCCESS"
let CREATE_OPEN_CASH = "CREATE_OPEN_CASH"
let CREATE_OPEN_CASH_SUCCESS = "CREATE_OPEN_CASH_SUCCESS"
let REMOVE_ITEM_SERVICE = "REMOVE_ITEM_SERVICE"
let CHANGE_DATE = "CHANGE_DATE"
let CHANGE_DATE_SUCCESS = "CHANGE_DATE_SUCCESS"
let RESTART_ORDER = "RESTART_ORDER"
let EDIT_SERVICE_DETAIL = "EDIT_SERVICE_DETAIL"
let EDIT_SERVICE_DETAIL_SUCCESS = "EDIT_SERVICE_DETAIL_SUCCESS"
let SAVE_FORM_MEMORY = "SAVE_FORM_MEMORY"
let SAVE_FORM_MEMORY_SUCCESS = "SAVE_FORM_MEMORY_SUCCESS"
let READ_FORM_MEMORY = "READ_FORM_MEMORY"
let READ_FORM_MEMORY_SUCCESS = "READ_FORM_MEMORY_SUCCESS"
let RESTART_MEMORY = "RESTART_MEMORY"
let UPDATE_PLATE = "UPDATE_PLATE"
let UPDATE_PLATE_SUCCESS = "UPDATE_PLATE_SUCCESS"
let CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS"
let CANCEL_ORDER = "CANCEL_ORDER"
let DELETE_ADDITION_PRODUCT = "DELETE_ADDITION_PRODUCT"
let DELETE_ADDITION_PRODUCT_SUCCESS = "DELETE_ADDITION_PRODUCT_SUCCESS"
let DELETE_PLATE = "DELETE_PLATE"
let DELETE_PLATE_SUCCESS = "DELETE_PLATE_SUCCESS"
let DELETE_CLIENT = "DELETE_CLIENT"
let DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
let SAVE_CLIENT_MEMORY = "SAVE_CLIENT_MEMORY"
let SAVE_CLIENT_MEMORY_SUCCESS = "SAVE_CLIENT_MEMORY_SUCCESS"
let REVERSE_ORDER = "REVERSE_ORDER"
let REVERSE_ORDER_SUCCESS = "REVERSE_ORDER_SUCCESS"
let ADVANCEMENT = "ADVANCEMENT"
let ADVANCEMENT_SUCCESS = "ADVANCEMENT_SUCCESS"
let DELETE_PAYMENT = "DELETE_PAYMENT"
let DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case LIST_ORDER:
            return { ...state, fetching_list: 1, fetching_by_id: 0, fetching_create: 0, fetching_finish: 0, fetching_payment: 0 }
        case LIST_ORDER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, }
        case ORDER_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case ORDER_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case STEP_VALIDATE:
            return { ...state, fetching_step: 1 }
        case STEP_VALIDATE_SUCCESS:
            return { ...state, fetching_step: 2, current: action.payload, title: action.title }
        case STEP_VALIDATE_ERROR:
            return { ...state, fetching_step: 3, errors: action.payload }
        case BACk_STEP:
            return { ...state, current: action.payload, title: action.title }
        case UPDATE_FIELD:
            return { ...state, fetching_field: 1 }
        case UPDATE_FIELD_SUCCESS:
            return { ...state, fetching_field: 2, form: action.payload }
        case CREATE_ORDER:
            return { ...state, fetching_create: 1 }
        case CREATE_ORDER_SUCCESS:
            return { ...state, fetching_create: 2, form: action.payload, current: 0, errors: {} };
        case FINISH_ORDER:
            return { ...state, fetching_finish: 1 }
        case FINISH_ORDER_SUCCESS:
            return { ...state, fetching_finish: 2 }
        case ADD_PAYMENT:
            return { ...state, fetching_payment: 1 }
        case ADD_PAYMENT_SUCCESS:
            return { ...state, fetching_payment: 2 }
        case ADDITIONAL_PRODUCTS:
            return { ...state, fetching_additional: 1 }
        case ADDITIONAL_PRODUCTS_SUCCESS:
            return { ...state, fetching_additional: 2 }
        case OPEN_CASH:
            return { ...state, fetching_cash: 1 }
        case OPEN_CASH_SUCCESS:
            return { ...state, fetching_cash: 2, cash: action.payload }
        case CREATE_OPEN_CASH:
            return { ...state, fetching_cash_create: 1 }
        case CREATE_OPEN_CASH_SUCCESS:
            return { ...state, fetching_cash_create: 2 }
        case REMOVE_ITEM_SERVICE:
            return { ...state, form: action.payload }
        case CHANGE_DATE:
            return { ...state, fetching_change: 1 }
        case CHANGE_DATE_SUCCESS:
            return { ...state, fetching_change: 2, currentDate: action.payload }
        case RESTART_ORDER:
            return { ...state, currentDate: moment().format("YYYY-MM-DD"), fetching_list: 0, fetching_by_id: 0, current: 0, fetching_save_form: 0 }
        case EDIT_SERVICE_DETAIL:
            return { ...state, fetching_detail: 1 }
        case EDIT_SERVICE_DETAIL_SUCCESS:
            return { ...state, fetching_detail: 2 }
        case SAVE_FORM_MEMORY:
            return { ...state, fetching_save_form: 1, fetching_save_form: 0, fetching_add_client: 0, }
        case SAVE_FORM_MEMORY_SUCCESS:
            return { ...state, fetching_save_form: action.payload }
        case READ_FORM_MEMORY:
            return { ...state, fetching_get_form: 1 }
        case READ_FORM_MEMORY_SUCCESS:
            return {
                ...state, fetching_get_form: 2, form: action.payload, fetching_save_form: 0, fetching_delete_plate: 0, fetching_delete_client: 0,
                fetching_add_client: 0, data: []
            }
        case RESTART_MEMORY:
            return { ...state, fetching_save_form: 0 }
        case UPDATE_PLATE:
            return { ...state, fetching_plate: 1 }
        case UPDATE_PLATE_SUCCESS:
            return { ...state, fetching_plate: 2 }
        case CANCEL_ORDER:
            return { ...state, fetching_cancel: 1 }
        case CANCEL_ORDER_SUCCESS:
            return { ...state, fetching_cancel: 2 }
        case DELETE_ADDITION_PRODUCT:
            return { ...state, fetching_delete: 1, fetching_by_id: 0 }
        case DELETE_ADDITION_PRODUCT_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case DELETE_PLATE:
            return { ...state, fetching_delete_plate: 1 }
        case DELETE_PLATE_SUCCESS:
            return { ...state, fetching_delete_plate: 2, form: action.payload }
        case DELETE_CLIENT:
            return { ...state, fetching_delete_client: 1 }
        case DELETE_CLIENT_SUCCESS:
            return { ...state, fetching_delete_client: 2, fetching_save_form: 0, fetching_add_client: 0, form: action.payload }
        case SAVE_CLIENT_MEMORY:
            return { ...state, fetching_add_client: 1, fetching_save_form: 0, fetching_add_client: 0, }
        case SAVE_CLIENT_MEMORY_SUCCESS:
            return { ...state, fetching_add_client: 2, form: action.payload }
        case REVERSE_ORDER:
            return { ...state, fetching_reverse: 1 }
        case REVERSE_ORDER_SUCCESS:
            return { ...state, fetching_reverse: 2 }
        case ADVANCEMENT:
            return { ...state, fetching_advancement: 1 }
        case ADVANCEMENT_SUCCESS:
            return { ...state, fetching_advancement: 2 }
        case DELETE_PAYMENT:
            return { ...state, fetching_delete_payment: 1 }
        case DELETE_PAYMENT_SUCCESS:
            return { ...state, fetching_delete_payment: 2 }
        default:
            return state;
    }
}


export let saveClientMemory = (client) => (dispatch, getState) => {
    let form = getState().order.form

    dispatch({
        type: SAVE_CLIENT_MEMORY
    })

    form.client = client;

    localStorage.setItem("client", JSON.stringify(client))

    dispatch({
        type: SAVE_CLIENT_MEMORY_SUCCESS,
        payload: form
    })
}

export let onDeletePlate = () => (dispatch, getState) => {

    dispatch({
        type: DELETE_PLATE,
    });
    let { form } = getState().order

    localStorage.removeItem("plate")
    form.plate = null;

    dispatch({
        type: DELETE_PLATE_SUCCESS,
        payload: { ...form },
    });

}

export let onDeleteClient = () => (dispatch, getState) => {

    dispatch({
        type: DELETE_CLIENT,
    });
    let { form } = getState().order

    localStorage.removeItem("client")
    form.client = null;

    dispatch({
        type: DELETE_CLIENT_SUCCESS,
        payload: { ...form },
    });

}

export let onChangeDate = (date) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_DATE,
    });


    let storage

    if (localStorage.filter === undefined) {
        storage = { date: moment().format("YYYY-MM-DD") };
    } else {
        storage = JSON.parse(localStorage.filter)
    }


    storage.date = date;
    localStorage.filter = JSON.stringify(storage)

    dispatch({
        type: CHANGE_DATE_SUCCESS,
        payload: date
    });
}


export let removeItemService = (item) => (dispatch, getState) => {
    let { form } = getState().order

    let { services } = form

    let _services = services.filter(s => s.id !== item.id)
    form.services = _services

    dispatch({
        type: REMOVE_ITEM_SERVICE,
        payload: { ...form },
    });
}


export let updateFieldOrder = (e) => (dispatch, getState) => {
    let { form } = getState().order

    dispatch({
        type: UPDATE_FIELD,
        payload: form
    });

    if (e.field === 'plate') {
        form.plate = e.value
    }

    if (e.field === 'recurrent') {
        let { plate, client, category } = e.value
        form.plate = plate

        let _recurrent = e.value
        _recurrent.label = `${plate} [${category.title}]-(${client.name})`;
        _recurrent.value = e.value
        form.recurrent = e.value
    }

    if (e.field === 'client_id') {
        form.client_id = e.value.id
        let _client = e.value
        _client.label = `${_client.name} (${_client.phone})`
        _client.value = _client.id
        form.client = e.value
    }

    if (e.field === 'category_id') {
        form.category_id = e.value.id
        form.category = e.value
    }
    if (e.field === 'operator_id') {
        form.operator_id = e.value
    }
    if (e.field === 'list_services') {
        let { services } = form
        services.push(e.value)
        form.services = services
    }

    if (e.field === 'list_products') {
        let { products } = form
        products = e.value
        form.products = products
    }
    if (e.field === 'observations') {
        let { observations } = form
        observations = e.value
        form.observations = observations
    }

    if (e.field === 'photos') {
        let { photos } = form
        photos.push(e.value)
        form.photos = photos
    }

    dispatch({
        type: UPDATE_FIELD_SUCCESS,
        payload: { ...form }
    });


}

export let onCreateOrder = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_ORDER,
    });

    let url = `${API_URL}/api_v2/orders`;

    let client = JSON.parse(localStorage.client)
    let category = JSON.parse(localStorage.category)

    let plate = JSON.parse(localStorage.getItem("plate"));

    let services = JSON.parse(localStorage.services)
    let operators = JSON.parse(localStorage.operators)
    let branch = JSON.parse(localStorage.branch)
    let turn = JSON.parse(localStorage.turn)


    let _form = new FormData()
    _form.append("client_id", client.id);
    _form.append("services", JSON.stringify(services));
    _form.append("observations", form.observations);
    _form.append("plate", plate);
    _form.append("category_id", category.id);
    _form.append("operators", JSON.stringify(operators));
    _form.append("status_id", form.status_id);
    _form.append("notification", form.notification);
    _form.append("registration_date", form.registration_date);
    _form.append("branch_id", branch.id);
    _form.append("cashier_id", turn.id);


    if (form.photos.length > 0) {
        let { photos } = form

        photos.map(p => {
            console.log('p', p);

            _form.append("image", p.file);
        })
    }

    axios.post(url, _form, {
        headers: getHeadersImage()
    })
        .then((resp) => {
            localStorage.removeItem("category")
            localStorage.removeItem("client")
            localStorage.removeItem("plate")
            localStorage.removeItem("operators")
            localStorage.removeItem("services")

            dispatch({
                type: CREATE_ORDER_SUCCESS,
                payload: form
            });
        });

}

export let getOrders = (status) => (dispatch, getState) => {
    dispatch({
        type: LIST_ORDER,
    });

    let { currentDate } = getState().order;

    if (getState().user.loggedIn) {

        let turn_id = null

        let turn = JSON.parse(localStorage.getItem("turn"))

        if (turn !== null) {
            turn_id = turn.id;
        }

        let url = `${API_URL}/api/orders-list-lite/${status}?cashier_id=${turn_id}`;

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            if (resp.ok === true) {
                return resp.json()
            }

            if (resp.status === 401) {
                logOutAction()(dispatch, getState);
            }

        }).then(resp => {

            dispatch({
                type: LIST_ORDER_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getOrdersCC = (status, method_payment) => (dispatch, getState) => {
    dispatch({
        type: LIST_ORDER,
    });

    let { currentDate } = getState().order;

    if (getState().user.loggedIn) {

        let turn_id = null

        let turn = JSON.parse(localStorage.getItem("turn"))
        let branch = JSON.parse(localStorage.getItem("branch"))

        if (turn !== null) {
            turn_id = turn.id;
        }

        let url = `${API_URL}/api/orders-list-lite-cxc/${status}?branch_id=${branch.id}&cashier_id=${turn.id}&method_payment_id=${method_payment}`;

        fetch(url, {
            headers: getHeaders()
        }).then(resp => {
            if (resp.ok === true) {
                return resp.json()
            }

            if (resp.status === 401) {
                logOutAction()(dispatch, getState);
            }

        }).then(resp => {

            dispatch({
                type: LIST_ORDER_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let getOrderById = (id) => (dispatch, getState) => {
    dispatch({
        type: ORDER_BY_ID,
    });

    let url = `${API_URL}/api/orders/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ORDER_BY_ID_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onFinishOrder = (id, notification) => (dispatch, getState) => {
    dispatch({
        type: FINISH_ORDER,
    });

    let url = `${API_URL}/api_v2/orders/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify({ notification }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: FINISH_ORDER_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onRestartOrder = (id) => (dispatch, getState) => {
    dispatch({
        type: FINISH_ORDER,
    });

    let url = `${API_URL}/api/orders-restart/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: FINISH_ORDER_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onReverseOrder = (id) => (dispatch, getState) => {
    dispatch({
        type: REVERSE_ORDER,
    });

    let url = `${API_URL}/api/orders-reverse/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: REVERSE_ORDER_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onAddPayment = (order_id, payments) => (dispatch, getState) => {
    dispatch({
        type: ADD_PAYMENT,
    });

    let url = `${API_URL}/api/orders-payment`;

    let turnActive = getState().turn.row

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                "order_id": order_id,
                "cashier_id": turnActive.id,
                "payments": JSON.stringify(payments)
            }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ADD_PAYMENT_SUCCESS,
            });
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onAdvancement = (order_id, method_payment_id, amount, amount_received, tips) => (dispatch, getState) => {
    dispatch({
        type: ADVANCEMENT,
    });

    let url = `${API_URL}/api/orders-payment-advancement`;

    let turnActive = getState().turn.row

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify({
                "method_payment_id": method_payment_id,
                "order_id": order_id,
                "amount": amount,
                "amount_received": amount_received,
                "cashier_id": turnActive.id,
                "tips": tips
            }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: ADVANCEMENT_SUCCESS,
            });
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onAddAdditionProducts = (order_id, products) => (dispatch, getState) => {
    dispatch({
        type: ADDITIONAL_PRODUCTS,
    });

    let url = `${API_URL}/api/orders-additional-product/${order_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify({ order_id, products }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ADDITIONAL_PRODUCTS_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteAdditionService = (detail_id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_ADDITION_PRODUCT,
    });


    let url = `${API_URL}/api/orders-detail-product/${detail_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: DELETE_ADDITION_PRODUCT_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
export let onDeletePayment = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYMENT,
    });


    let url = `${API_URL}/api/orders-payment/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "DELETE",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: DELETE_PAYMENT_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onAddAdditionService = (form) => (dispatch, getState) => {
    dispatch({
        type: ADDITIONAL_PRODUCTS,
    });


    let url = `${API_URL}/api/orders-additional/${form.order_id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: ADDITIONAL_PRODUCTS_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}





export let getOpenCashToday = (id) => (dispatch, getState) => {
    dispatch({
        type: OPEN_CASH,
    });

    let { currentDate } = getState().order;

    let url = `${API_URL}/api/open-cash-today?date=${currentDate}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: OPEN_CASH_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let createOrEditCash = (id, value) => (dispatch, getState) => {
    dispatch({
        type: CREATE_OPEN_CASH,
    });
    let { currentDate } = getState().order;


    let url = `${API_URL}/api/open-cash`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ "value": value, "date": currentDate }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: CREATE_OPEN_CASH_SUCCESS
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onEditServiceDetail = (form) => (dispatch, getState) => {
    dispatch({
        type: EDIT_SERVICE_DETAIL,
    });

    let url = `${API_URL}/api/orders-detail/${form.id}`;

    if (getState().user.loggedIn) {
        fetch(url, {
            method: 'PUT',
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: EDIT_SERVICE_DETAIL_SUCCESS
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onDeleteServiceDetail = (form) => (dispatch, getState) => {
    dispatch({
        type: EDIT_SERVICE_DETAIL,
    });

    let url = `${API_URL}/api/orders-detail/${form.id}`;

    if (getState().user.loggedIn) {
        fetch(url, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: EDIT_SERVICE_DETAIL_SUCCESS
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let saveOrderMemory = (form, step) => (dispatch, getState) => {
    dispatch({
        type: SAVE_FORM_MEMORY
    })

    if (form.category !== undefined) {
        localStorage.setItem("category", JSON.stringify(form.category))
    }
    if (form.plate !== undefined) {
        localStorage.setItem("plate", JSON.stringify(form.plate))
    }
    if (form.client !== undefined) {
        localStorage.setItem("client", JSON.stringify(form.client))
    }

    if (form.operators !== undefined) {
        localStorage.setItem("operators", JSON.stringify(form.operators))
    }

    if (form.services !== undefined) {
        localStorage.setItem("services", JSON.stringify(form.services))
    }

    dispatch({
        type: SAVE_FORM_MEMORY_SUCCESS,
        payload: step
    })
}



export let getOrderMemory = () => (dispatch, getState) => {
    dispatch({
        type: READ_FORM_MEMORY
    })

    let category = JSON.parse(localStorage.getItem("category"));
    let client = JSON.parse(localStorage.getItem("client"));
    let plate = localStorage.getItem("plate");
    let operators = JSON.parse(localStorage.getItem("operators"));
    let services = JSON.parse(localStorage.getItem("services"));


    dispatch({
        type: READ_FORM_MEMORY_SUCCESS,
        payload: { category, client, plate, operators, services }
    })
}


export let onUpdatePlate = (id, plate) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_PLATE,
    });

    let url = `${API_URL}/api/order-plate/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify({ plate }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: UPDATE_PLATE_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onCancelOrder = (order, comment) => (dispatch, getState) => {
    dispatch({
        type: CANCEL_ORDER,
    });

    let url = `${API_URL}/api/orders-cancel/${order.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "PUT",
            body: JSON.stringify({ "comment_cancel": comment }),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: CANCEL_ORDER_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}
import React, { useEffect, useState } from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { MdWest } from 'react-icons/md';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import InputField from '../../Components/InputField';
import { getClientById, onCreateClient } from '../../Redux/clientDuck'
import FormClient from './form.client';

const CreateClientPage = ({ onCreateClient, fetching_create, errors }) => {
    const history = useHistory()
    const [form, setForm] = useState({})

    useEffect(() => {
        if (fetching_create === 2) {
            history.push("/step-one")
        }
    }, [fetching_create])


    const onGoBack = () => {
        history.goBack()
    }

    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container fluid>
                    <Row className='w-100'>
                        <Col
                            className='d-flex justify-content-end' xs={2}>
                            <MdWest className='cursor-pointer' onClick={onGoBack} />
                        </Col>
                        <Col className='text-center' xs={8}><b>Crear Cliente</b></Col>
                    </Row>
                </Container>
            </Navbar>
            <Container>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <FormClient formEdit={{}} onSuccess={onCreateClient} errors={errors} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const mapsState = (props) => {
    let { client } = props
    return {
        fetching_create: client.fetching,
        errors: client.errors
    }
}

export default connect(mapsState, { getClientById, onCreateClient })(CreateClientPage);
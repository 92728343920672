import React, { useState } from 'react'
import { formatNumber } from '../../utils'

const ItemMoney = ({ item, onUpdateRow }) => {

    const [quantity, setQuantity] = useState(0)

    const onUpdateQuantity = (e) => {
        setQuantity(e.target.value)
        onUpdateRow(item, e.target.value)
    }

    return (
        <tr>
            <td align='right'>{formatNumber(item.denomination)}</td>
            <td><input className='form-control text-right' value={quantity} onChange={onUpdateQuantity} /></td>
            <td align='right'>{formatNumber(item.denomination * item.quantity)}</td>
        </tr>

    )
}

export default ItemMoney;
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalReverse = ({ show, order, onHide, onConfirm }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>¿Deseas Reabrir la Orden?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='text-center'>
                        <h3>#{order.id}</h3>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col className='text-right'>
                        <button className='btn btn-sm btn-warning' onClick={() => onConfirm(order.id)}>Aceptar</button>
                    </Col>
                </Row>
            </Modal.Footer>

        </Modal >
    )
}

export default ModalReverse;
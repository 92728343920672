import React, { useEffect, useState } from 'react'
import SimpleTemplate from '../../template/simple.template';
import { getListHistory } from '../../Redux/cartDuck'
import { connect } from 'react-redux';
import { Card, Col, Container, Row } from 'react-bootstrap';

const CartHistoryPage = ({ getListHistory, fetching_history, data_history }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_history === 2) {
            setList(data_history)
        }
    }, [fetching_history])

    useEffect(() => {
        getListHistory()
    }, [])

    return (
        <SimpleTemplate title={"Historial"}>
            <Container fluid>
                <Row className='mt-3'>
                    {list.map((d, k) => <Col key={k} xs={12} className='mt-1'>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col>ID</Col>
                                    <Col className='text-end'>{d.id}</Col>
                                </Row>
                                <Row>
                                    <Col>Tipo de Compra</Col>
                                    <Col className='text-end'>{d.type_order}</Col>
                                </Row>
                                <Row>
                                    <Col>Cliente</Col>
                                    <Col className='text-end'>{d.client}</Col>
                                </Row>
                                <Row>
                                    <Col>Fecha</Col>
                                    <Col className='text-end'>{d.created_at}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>)}
                </Row>
            </Container>
        </SimpleTemplate>

    )
}

const mapsState = (props) => {
    let { cart } = props
    return {
        fetching_history: cart.fetching_history,
        data_history: cart.data_history,
    }
}

export default connect(mapsState, { getListHistory })(CartHistoryPage);
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Loader from '../Images/loader.gif'

const LoaderPage = ({ loader }) => {
    return (
        <Container fluid className={`bg-loader ${!loader && 'd-none'}`}>
            <Row>
                <Col>
                    <img src={Loader} />
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { alert } = props

    return {
        loader: alert.loader
    }
}

export default connect(mapsState, {})(LoaderPage);
import React from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap';
import ItemService from './CreateOrder/item.service';

const ModalServices = ({ show, onHide, listServices, onSelectedService }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Servicios para tu Vehículo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {listServices.map((ser, k) => <ItemService key={k} item={ser} onSelectedService={onSelectedService} />)}
            </Modal.Body>
        </Modal>
    )
}

export default ModalServices;
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_worker: 0,
    fetching_create: 0,
    data: [],
    data_worker: [],
    errors: {}
}

let LIST_DETAIL_WORKER = "LIST_DETAIL_WORKER";
let LIST_DETAIL_WORKER_SUCCESS = "LIST_DETAIL_WORKER_SUCCESS";
let CREATE_PAYROLL = "CREATE_PAYROLL";
let CREATE_PAYROLL_SUCCESS = "CREATE_PAYROLL_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_DETAIL_WORKER:
            return { ...state, fetching_worker: 1, }
        case LIST_DETAIL_WORKER_SUCCESS:
            return { ...state, fetching_worker: 2, data_worker: action.payload }
        case CREATE_PAYROLL:
            return { ...state, fetching_create: 1 }
        case CREATE_PAYROLL_SUCCESS:
            return { ...state, fetching_create: 2 }
        default:
            return state;
    }
}

export let getDetailWorker = (worker_id) => (dispatch, getState) => {
    dispatch({
        type: LIST_DETAIL_WORKER,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/cost/services-by-worker/${worker_id}?branch_id=${branch.id}&cashier_id=${turn.id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "get",
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: LIST_DETAIL_WORKER_SUCCESS,
                    payload: resp
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
}

export let onCreatePayroll = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PAYROLL,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let turn = JSON.parse(localStorage.getItem("turn"))

    let url = `${API_URL}/api/orders-liquidate-app`;
    form.turn_id = turn.id
    form.branch_id = branch.id

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            if (resp.ok === false) {
                // dispatch({
                //     type: CREATE_CLIENT_ERROR,
                //     payload: resp.errors
                // });
            } else {
                dispatch({
                    type: CREATE_PAYROLL_SUCCESS,
                });
            }
        })
    } else {
        console.log("no Ingreso");
    }
}